import React from "react";
import "./styles.css";
import GET_DASHBOARD_CASE_FOLDERS from "../../../graphql/queries/getDashboardCaseFolder";
import { useMutation, useQuery } from "@apollo/client";
import { GetCaseFolders, GetCaseFoldersVariables, TouchCaseFolder, TouchCaseFolderVariables } from "../../../schema";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonText,
  useIonRouter,
} from "@ionic/react";
import Status from "../../../components/Status";
import ErrorAlert from "../../../components/ErrorAlert";
import { PATH_LINK } from "../../../App";
import styled from "styled-components";
import { folderOpenOutline } from "ionicons/icons";
import SkeletonLoadingBlock from "./SkelektonLoadingBlock";
import { TOUCH_CASE_FOLDER } from "../../../graphql/mutation/TouchCaseFolder";

const CaseFolderTable: React.FC = () => {
  const router = useIonRouter();

  const { data, loading, error, refetch } = useQuery<
    GetCaseFolders,
    GetCaseFoldersVariables
  >(GET_DASHBOARD_CASE_FOLDERS, {
    context: {
      debounceKey: "caseFolderTable",
      debounceTimeout: 500,
    },
  });

  const [mutateTouchCaseFolder] = useMutation<TouchCaseFolder, TouchCaseFolderVariables>(TOUCH_CASE_FOLDER)

  const handleOpenCaseFolderModal = (caseFolderId: string) => {
    router.push(`${PATH_LINK.DASHBOARD}/c/${caseFolderId}`, "forward", "push");
    mutateTouchCaseFolder({
      variables: {
        id: caseFolderId
      }
    })
    refetch()
  };

  const caseFolders =
    data && data?.findCaseFoldersByCurrentCustomer.caseFolders!;

  return (
    <IonContent className="caseFolderTableWrapper" scrollY={true}>
      <CaseFolderTableStyleWrapper>
        <div className="caseFolderTableContainer">
          <div className="caseFolderHeaderWrapper">
            <IonLabel className="label">Akter</IonLabel>
          </div>
          <IonList className="caseFolderList" lines={"none"}>
            {loading && <SkeletonLoadingBlock />}
            {caseFolders && (
              caseFolders.length <= 0 ? (
                <div className="noItemContainer">
                <IonText>Det finns inga pågående akter</IonText>
                <div className="buttonWrapper">
                  <IonMenuButton className="menuBtn" type={"button"} color={"none"}> Till meny</IonMenuButton>
                </div>
              </div>
              ) : (
                caseFolders?.map((caseFolder, index) => {
                  const documentLength =
                    caseFolder?.productPackages[0]?.documents.length;
                  return (
                    <IonItem
                      className="caseFolderItem"
                      detail
                      lines={"none"}
                      shape={"round"}
                      key={index}
                      onClick={() => handleOpenCaseFolderModal(caseFolder?.id!)}
                    >
                      <div className="itemContent" slot={"start"}>
                        <IonLabel className="itemLabel">
                          {caseFolder?.name}
                        </IonLabel>
  
                        <div className="itemContentInfo">
                          <div className="statusWrapper">
                            <Status
                              color={caseFolder?.summary.mostCriticalColor!}
                              text={caseFolder?.summary.mostCriticalStatus}
                            />
                          </div>
                          <div className="productNumberWrapper">
                            <IonIcon
                              icon={folderOpenOutline}
                              className={"icon"}
                              color={"secondary"}
                            />
                            <IonText className="number">
                              {documentLength}
                              {documentLength! === 1 ? " produkt" : " produkter"}
                            </IonText>
                          </div>
                        </div>
                      </div>
                    </IonItem>
                  );
                })
              )

            )}
          </IonList>
        </div>
      </CaseFolderTableStyleWrapper>
      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </IonContent>
  );
};

export default CaseFolderTable;

const CaseFolderTableStyleWrapper = styled.div`
  .caseFolderTableContainer {
    width: 100%;
    height: 100%;
    .caseFolderHeaderWrapper {
      margin: 15px 15px 10px 15px;
      .label {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .caseFolderList {
      background: none;

      .caseFolderItem {
        border: 1px solid var(--ion-color-light-shade);
        --padding-end: 0px;
        --inner-padding-end: 0px;
        margin: 8px 15px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 2px 8px 0px rgba(245, 245, 245, 1);
        -moz-box-shadow: 0px 2px 8px 0px rgba(245, 245, 245, 1);
        box-shadow: 0px 2px 8px 0px rgba(245, 245, 245, 1);
        --detail-icon-color: var(--ion-color-secondary);
        --detail-icon-font-size: 25px;

        .itemContent {
          padding: 12px 6px;
          .itemLabel {
            font-weight: bold;
          }
          .itemContentInfo {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .productNumberWrapper {
              margin-left: 10px;
              display: flex;
              align-items: center;
              .icon {
                font-size: 18px;
              }
              .number {
                font-size: 14px;
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
    .noItemContainer {
      width: 100%;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .buttonWrapper {
        margin-top: 10px;
        width: 100px;
        height: auto;
        border-radius: 3px;
        border: 1px solid var(--ion-color-primary);
        background-color: var(--ion-color-primary);
        padding: 8px;
        .menuBtn {
          font-size: 18px;
          color: white;
          --border-radius: 0;
        }
      }
    }
  }
`;
