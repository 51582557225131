import React from "react";
import { IonContent, IonIcon, IonRange, IonSpinner } from "@ionic/react";
import styled from "styled-components";
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas } from "../../../../../schema";
import { closeOutline, searchOutline } from "ionicons/icons";
import produce from "immer";
import Button from "../../../../../components/Button";
import { getLocalStorageItem, setLocalStorageItem } from "../../../../../utilities";

const Preview: React.FC<{
  img: string | undefined;
  isUpdateContentLoading: boolean;
  areas:
    | (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[]
    | null
    | undefined;
  getAreaId: (arg: string) => void;
  selectedArea: string | null;
  handleCloseZoomRange: () => void;
  isZoomRangeVisible: boolean;
}> = (props) => {
  const [state, setState] = React.useState<{
    imageScale?: number;
    containerHeight?: number;
  }>({
    imageScale: undefined,
    containerHeight: undefined,
  });
  const [zoom, setZoom] = React.useState<number | "auto">();
  const imageContainer = React.useRef<HTMLDivElement | null>(null);
  const MIN_IMAGE_HEIGHT = 215;

  const handleResize = () => {
    getLocalStorageItem("zoom").then((val) => val !== null && setZoom(val))

    const image = imageContainer.current!;
    if (!image) return;

    const minImageScale = MIN_IMAGE_HEIGHT / image.clientHeight;
    let imageScale =
      (window.innerHeight - image.offsetTop - 140) / image.clientHeight;

    imageScale = imageScale > minImageScale ? imageScale : minImageScale;

    if (imageScale > 1) imageScale = 1;

    const containerHeight =
      zoom === "auto"
        ? image.clientHeight * imageScale + 70
        : image.clientHeight * zoom! + 70;

    setState(
      produce(state, (draft) => {
        draft.imageScale = imageScale;
        draft.containerHeight =
          containerHeight < MIN_IMAGE_HEIGHT
            ? MIN_IMAGE_HEIGHT
            : containerHeight;
      })
    );
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    const img = new Image();
    img.onload = () => {
      handleResize();
    };
    img.src = props.img!;
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRangeInputChange = (value: number | "auto") => {
      if(value === "auto"){
         setZoom("auto")
         setLocalStorageItem("zoom", "auto")
        }
      setZoom(value)
      setLocalStorageItem("zoom", value)
  }

  return (
    <PreviewStyleWrapper>
      <div className="previewContainer">
        <div
          className="imageWrapper"
          ref={imageContainer}
          style={{
            transform: `scale(${zoom === "auto" ? state.imageScale : zoom})`,
          }}
        >
          <img
            className={"image"}
            src={props.img}
            alt="decease"
            loading="lazy"
          />
          {props.areas &&
            props.areas.map((area, index) => {
              const selected = area?.id === props.selectedArea;
              return (
                <div
                  key={index}
                  className="rectangle"
                  onClick={() => props.getAreaId(area?.id!)}
                  style={{
                    width: area!.rectangle?.w,
                    height: area!.rectangle?.h,
                    top: area!.rectangle?.y!,
                    left: area!.rectangle?.x!,
                    border: selected ?  "1px dashed var(--ion-color-secondary)"  : "1px dashed #b0b0b0",
                  }}
                >
                  {props.isUpdateContentLoading && selected && (
                    <IonSpinner
                      className="spinner"
                      name="dots"
                      color={"dark"}
                    ></IonSpinner>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {props.isZoomRangeVisible && (
        <div className="zoomRangeWrapper">
          <div className="buttonTool">
            <Button
              icon={closeOutline}
              iconDir={"icon-only"}
              onClick={props.handleCloseZoomRange}
              size={"small"}
              variants={"danger-noLine"}
            ></Button>
            <Button
              onClick={() => handleRangeInputChange("auto")}
              size={"small"}
              variants={"secondary_noLine"}

            >Auto</Button>
          </div>

          <IonRange
            pin={true}
            value={Number(zoom)}
            pinFormatter={(value: number) => `${Math.floor(value * 100)}%`}
            onIonChange={(e) => handleRangeInputChange(Number(e.detail.value!))}
            step={0.1}
            min={0.5}
            max={1.5}
            className={"range"}
            snaps={true}
          >
            <IonIcon color={"secondary"} slot="start" icon={searchOutline} size={"small"} />
            <IonIcon color={"secondary"} slot="end" icon={searchOutline} size={"default"} />
          </IonRange>
        </div>
      )}
    </PreviewStyleWrapper>
  );
};

const PreviewStyleWrapper = styled.div`
  .previewContainer {
    margin-bottom: 400px;
    padding: 8px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    .imageWrapper {
      position: absolute;
      .image {
        width: 100% !important;
        height: 100% !important;
      }
      position: relative;
      .rectangle {
        position: absolute;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .spinner {
          width: 35px;
          height: 100%;
        }
      }
    }
  }
  .zoomRangeWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--ion-color-light-tint);
    width: 75%;
    height: 100px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    -webkit-box-shadow: -3px 3px 9px 0px rgba(219, 219, 219, 1);
    -moz-box-shadow: -3px 3px 9px 0px rgba(219, 219, 219, 1);
    box-shadow: -3px 3px 9px 0px rgba(219, 219, 219, 1);
    opacity: 1;
    .buttonTool {
      display: flex;
    }
    .range {
      padding: 0 10px;
    }
  }
`;

export default Preview;
