import React from "react";
import { IonIcon, IonInput, IonLabel, IonNote, IonSpinner } from "@ionic/react";
import styled from "styled-components";
import { calendar, checkmarkDone } from "ionicons/icons";
import cx from "classnames";

const RcInput: React.FC<{
  label?: string;
  value: any;
  onChange?: (arg: any) => void;
  type?: "text" | "date" | "email" | "password";
  name?: string;
  errorMsg?: any;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  debounce?: number;
  color?: any;
  isSuccess?: boolean;
  onClick?: () => void;
  outSideClass?: string;
  isHorizontally?: boolean;
}> = (props) => {
  return (
    <RcInputStyleWrapper className={`${(props.outSideClass, props.className)}`}>
      <div
        className={cx("inputContainer", {
          horizontally: props.isHorizontally,
        })}
      >
        {props.label && (
          <div className="labelWrapper">
            <IonLabel className="rcLabel">{props.label}</IonLabel>
          </div>
        )}

        <div className="rcInput">
          <IonInput
            type={props.type && props.type}
            className={cx("input", {
              error: props.errorMsg,
              success: props.isSuccess,
            })}
            value={props.value}
            onIonChange={props.onChange}
            name={props.name}
            disabled={props.disabled}
            placeholder={props.placeholder}
            debounce={props.debounce}
            color={props.color}
            onClick={props.onClick}
          >
            {props.isSuccess && (
              <IonIcon
                className="successIcon"
                icon={checkmarkDone}
                color={"success"}
              />
            )}

            {props.type === "date" && !props.isLoading && (
              <IonIcon
                className="calenderIcon"
                icon={calendar}
                color={"secondary"}
              />
            )}

            {props.isLoading && <IonSpinner name="circles" color="secondary" />}
          </IonInput>
          {props.errorMsg && (
            <IonNote className="errorMsg">{props.errorMsg}</IonNote>
          )}
        </div>
      </div>
    </RcInputStyleWrapper>
  );
};

export default RcInput;

const RcInputStyleWrapper = styled.div`
  .inputContainer {
    display: flex;
    flex-direction: column;
    .labelWrapper {
      padding-top: 10px;
      margin-bottom: 5px;
    }
    .rcInput {
      width: 100%;
      .input {
        --background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%)
          0% 0% no-repeat padding-box;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        position: relative;
        --padding-start: 10px;
        .calenderIcon {
          padding: 5px;
          font-size: 20px;
          position: absolute;
          right: 0;
        }
        .successIcon {
          padding: 5px;
          font-size: 20px;
          position: absolute;
          right: 0;
        }

        &.has-focus {
          border-color: var(--ion-color-secondary);
        }
        &.error {
          border: 1px solid var(--ion-color-danger);
        }
        &.success {
          border: 1px solid var(--ion-color-success);
        }
      }
      .errorMsg {
        color: var(--ion-color-danger);
        font-size: 12px;
        font-weight: bold;
      }
    }

    &.horizontally {
      display: grid;
      grid-template-columns: 0.6fr 1.2fr;
      grid-template-rows: 1fr;
      column-gap: 10px;
      .labelWrapper {
        grid-column: 1/2;
        .rcLabel {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
      .rcInput {
        grid-column: 2/3;
        .input {
          --padding-start: 10px;
        }
      }
    }
  }
`;
