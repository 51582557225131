import gql from "graphql-tag";

let width: number = window.screen.width;

let PAGES_FRAGMENT_MOBILE = gql`
fragment PagesFragment on MediaOrderMaterialPage {
	png(widthPx:${width - 40} , resolutionDeep: 1.9) {
		url
	}
}
`

const PAGES_FRAGMENT_DEFAULT = gql`
fragment PagesFragment on MediaOrderMaterialPage {
	png(resolution: 192, resolutionDeep: 1.4) {
		url
	}
}
`
const PAGES_FRAGMENT =  width <= 480 ? PAGES_FRAGMENT_MOBILE : PAGES_FRAGMENT_DEFAULT

const PREVIEW_IMAGE_FRAGMENT = gql`
  fragment previewImageFragment on MediaOrderPreview {
    material {
      basePage {
        size(unit: MILLIMETER) {
          width
          height
          __typename
        }
        __typename
      }
      pages {
			...PagesFragment
		}
      __typename
    }
    __typename
  }
  ${PAGES_FRAGMENT}
`;

export default PREVIEW_IMAGE_FRAGMENT;
