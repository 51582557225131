import gql from "graphql-tag"

const UPDATE_CUSTOMER_DOCUMENT_CONTENT_INITIATOR_FROM_ORDER = gql`
  mutation UpdateCustomerDocumentContentInitiatorFromOrder(
    $customerScopeForDocumentContent: CustomerScope!
    $orderId: ID!
    $replaceLowerCustomerScopeInitiors: Boolean!
  ) {
    updateCustomerDocumentContentInitiatorFromOrder(
      customerScopeForDocumentContent: $customerScopeForDocumentContent
      orderId: $orderId
      replaceLowerCustomerScopeInitiors: $replaceLowerCustomerScopeInitiors
    )
  }
`
export default UPDATE_CUSTOMER_DOCUMENT_CONTENT_INITIATOR_FROM_ORDER