import gql from "graphql-tag";

export const UPDATE_ORDER_LOCK = gql`
  mutation UpdateOrderLock($input: MutationLockOrderInput!) {
    lockOrder(input: $input) {
      isError
      errorReason
      order {
        id
        mediaOrder {
          isLocked
          status {
            color
            status
          }
          __typename
        }
      }
    }
  }
`;
