import gql from "graphql-tag"

const REACTIVE_ORDERS = gql`
  mutation ReActivateUnPublishOrders(
    $input: MutationReactivateUnPublishOrdersInput!
  ) {
    reactivateUnPublishOrders(input: $input) {
      isError
      errorReason
      orders {
        id
        mediaOrder {
          isLocked
          edit {
            userInterface {
              editStatusText
              editStatusIcon
              editStatusColor
              publishButtonText
              isLockButton
              lockButtonText
              isUnlockButton
              unlockButtonText
              isReactivateButton
              reactivateButtonText
            }
          }
          status {
            color
            status
          }
          publishConversation {
            isBookingSent
          }
        }
      }
    }
  }
`
export default REACTIVE_ORDERS