import React from "react";
import { IonContent, IonIcon, IonLabel, IonModal, IonText } from "@ionic/react";
import { callOutline, mailOutline } from "ionicons/icons";
import styled from "styled-components";

const SupportModal: React.FC<{
  modalRef: any;
}> = (props) => {
  return (
    <IonModal
      ref={props.modalRef as any}
      trigger={"open-support-modal"}
      onDidDismiss={() => props.modalRef.current?.dismiss()}
      initialBreakpoint={0.60}
      canDismiss={true}
      breakpoints={[0, 0.5]}
    >
      <IonContent className="ion-padding">
        <SupportModalStyleWrapper>
          <div className="header">
            <div className="headerImage">
              <IonIcon className="icon" icon={callOutline} /> <br />
            </div>

            <IonLabel className="label">Support</IonLabel>
          </div>

          <div className="content">
            <div className="openTime">
              <IonLabel className="label">Öppettider vardagar</IonLabel>
              <IonText>08:00 - 17:00</IonText>
            </div>
            <div className="contactTime">
              <IonIcon className="icon" icon={callOutline} />
              <a href="tel:026-401-21-80" className="text">026-401-21-80</a>
            </div>
            <div className="contactTime">
              <IonIcon className="icon" icon={mailOutline} />
              <a href="mailto:support@timecut.se" className="text">support@timecut.se</a>
            </div>
          </div>
        </SupportModalStyleWrapper>
      </IonContent>
    </IonModal>
  );
};

const SupportModalStyleWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  margin-top: 10px;
  .header {
    .headerImage {
        background-color: var(--ion-color-primary);
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-bottom: 15px;
        .icon {
            color: white;
            font-size: 35px;
        }
    }
    .label {
      font-size: 25px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .openTime {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      row-gap: 10px;
      font-size: 19px;
      .label {
        font-weight: bold;
      }
    }
    .contactTime {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      .icon {
        margin-right: 8px;
        font-size: 25px;
      }
      .text {
        font-weight: 300;
        font-size: 19px;
        color: #000;
      }
    }
  }
`;

export default SupportModal;
