import gql from "graphql-tag"

const PROPERTIES_FUNERAL_STANDARD_FRAGMENT = gql`
  fragment PropertiesFuneralStandardFragment on CaseFolderPropertiesFuneralStandard {
    externalId
    personBorn
    personDied
    personFuneral
    personBornLastname
    personFirstname
    personLastname
    personCityLive
    personFuneral
    personFuneralInfoText
    memoryCeremonyLastBookingDay
  }
`

export default PROPERTIES_FUNERAL_STANDARD_FRAGMENT
