import React from "react";
import "./styles.css"
import { IonModal } from "@ionic/react";

const WindowModal: React.FC<{
    isOpen: boolean
    onDidDismiss: () => void
}> = (props) => {
  return (
    <IonModal
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      className={"datePickerModal"}
      id="ModalWindowModal"
      trigger="open-custom-dialog"
      showBackdrop={true}
    >
      {props.children}
    </IonModal>
  );
};

export default WindowModal;
