import React from "react";
import Button from "../../../../../components/Button";
import ErrorAlert from "../../../../../components/ErrorAlert";
import COPY_ORDER_FUNERAL_INFO_MEMORY_ROOM from "../../../../../graphql/mutation/copyOrderFunerallnfoMemoryRoom";
import {
  CopyOrderFuneralInfoToMemoryRoom,
  CopyOrderFuneralInfoToMemoryRoomVariables,
} from "../../../../../schema";
import { useMutation } from "@apollo/client";
import { IonAlert } from "@ionic/react";

const MoveToMemoryRoomButton: React.FC<{
  orderId: string;
  copyFuneralInfoToMemoryRoomText: string;
  dismissPopover:() => void
}> = (props) => {
  const [alert, setAlert] = React.useState<boolean>(false);

  const [mutationCopyOrderFuneralInfoToMemoryRoom, { loading, error }] =
    useMutation<
      CopyOrderFuneralInfoToMemoryRoom,
      CopyOrderFuneralInfoToMemoryRoomVariables
    >(COPY_ORDER_FUNERAL_INFO_MEMORY_ROOM);

  const handleCopyOrderFuneralInfoToMemoryRoom = () => {
    props.dismissPopover()
    mutationCopyOrderFuneralInfoToMemoryRoom({
      variables: {
        id: props.orderId,
      },
    });
  };

  const confirmText = "Du kommer att överföra begravningInfo till minnsrum";
  return (
    <div>
      <Button
        variants={"secondary_noLine"}
        className="button"
        isLoading={loading}
        onClick={() => setAlert(true)}
      >
        {"Överför info"}
      </Button>
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        message={confirmText}
        buttons={[
          {
            text: "bekräfta",
            role: "confirm",
            handler: () => {
              handleCopyOrderFuneralInfoToMemoryRoom();
            },
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>
      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default MoveToMemoryRoomButton;
