import React from "react";
import {
  EditModelAdvertAreaContentEditorBehaviours,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns,
} from "../../../../../../../schema";
import styled from "styled-components";
import VersesModal from "./VersesModal";
import { useContextSelector } from "use-context-selector";
import { AreasContext } from "..";
import TextEditorWrapper from "./TextEditorWrapper";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderNavigation from "./SliderNavigation";
import {type Swiper as SwiperRef } from 'swiper'

type TEditorRows =
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows;

type TColumn =
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns;

interface IColumn extends TColumn {
  columnId?: string;
}

export interface IRow extends TEditorRows {
  rowId?: string;
  columns: IColumn[];
}

const TextContent: React.FC<{
  rows:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows[]
    | undefined;
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours;
  isLocked: boolean;
  saveContentRow: (arg: TEditorRows[]) => void;
  isEnabledMultiColumns: boolean;
  isUpdateContentLoading: boolean;
  swpRef: React.MutableRefObject<SwiperRef | null>
}> = (props) => {
  const [rowIndex, setRowIndex] = React.useState<number>(0);
  const { onPlainTextChange, doCloseAreaModal } = useContextSelector(
    AreasContext,
    (v) => v!
  );

  const [isVersesModalOpen, setIsVersesModalOpen] =
    React.useState<boolean>(false);

  const [isAdvancedMode, setIsAdvancedMode] = React.useState<boolean>(false);

  const handleOpenVerseModal = () => setIsVersesModalOpen(true);

  const handleCloseVerseModal = () => setIsVersesModalOpen(false);

  const handleAdvancedModeOpen = () => setIsAdvancedMode(true);

  const handleAdvancedModeClose = () => setIsAdvancedMode(false);

  return (
    <TextContentStyleWrapper>
      {props.rows &&
        props.rows?.map((row, rowIdx) => {
          if (rowIdx === rowIndex) {
            return (
              <Swiper
                key={rowIdx}
                className="columnSwiper"
                initialSlide={0}
                direction={"horizontal"}
                speed={500}
                autoplay={false}
                allowTouchMove={false}
              >
                {isAdvancedMode && props.rows && props.rows.length - 1 > 0 && (
                  <SliderNavigation
                    totalRowLength={props.rows && props.rows!.length - 1}
                    rowIndex={rowIndex}
                    setRowIndex={setRowIndex}
                    columns={row.columns}
                    row={row}
                    activeRow={rowIdx === rowIndex}
                  />
                )}

                {row.columns.map((col, colIndx) => {
                  return (
                    <SwiperSlide key={colIndx} className={"column"}>
                      <TextEditorWrapper
                        inputValue={col?.item.text?.plainText}
                        onPlainTextChange={onPlainTextChange}
                        isLocked={props.isLocked}
                        column={colIndx}
                        row={rowIdx}
                        isUpdateContentLoading={props.isUpdateContentLoading}
                        handleOpenVerseModal={handleOpenVerseModal}
                        isEnabledMultiColumns={
                          props.rows && props.rows.length - 1 > 0 ? true : false
                        }
                        editorBehaviour={props.editorBehaviour}
                        handleAdvancedModeOpen={handleAdvancedModeOpen}
                        handleAdvancedModeClose={handleAdvancedModeClose}
                        lines={col.item.text!.lines}
                        textAlignment={col.item.text!.textAlignment}
                        isAdvancedMode={isAdvancedMode}
                        isDemoValue={col.item.isDemoValue!}
                        swpRef={props.swpRef}
                      />
                      <VersesModal
                        isOpen={isVersesModalOpen}
                        setIsOpen={setIsVersesModalOpen}
                        doClose={handleCloseVerseModal}
                        doCloseAreaModal={doCloseAreaModal}
                        onPlainTextChange={onPlainTextChange}
                        column={colIndx}
                        row={rowIdx}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            );
          }
        })}
    </TextContentStyleWrapper>
  );
};

const TextContentStyleWrapper = styled.div`
  .columnSwiper {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export default TextContent;
