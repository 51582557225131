import React from "react";
import { IonAlert } from "@ionic/react";
import styled from "styled-components";

const ErrorAlert: React.FC<{
  title: string;
  subTitle: string;
  msg: any;
  mode?: "ios" | "md"
}> = (props) => {
  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState<boolean>(true)


  return (
    <AlertStyleWrapper>
      <IonAlert
        isOpen={isErrorAlertOpen}
        onDidDismiss={() => setIsErrorAlertOpen(false)}
        cssClass="error-alert"
        header={props.title}
        subHeader={props.subTitle}
        message={props.msg}
        mode={props.mode}
        buttons={["Stäng"]}
      />
    </AlertStyleWrapper>
  );
};

const AlertStyleWrapper = styled.div`
`;

export default ErrorAlert;

// {error && (
//   <ErrorAlert
//     title={error?.name}
//     subTitle={error.message}
//     msg={error.extraInfo}
//   />
// )}