import gql from "graphql-tag";

export const GET_AVAILABLE_PUBLISH_TIMES = gql`
  query GetAvailablePublishTimes($orderId: ID) {
    mediaPublishCalendarDays(orderId: $orderId) {
      timeDay
      isEnabled
      timeBooking
      __typename
    }
  }
`;
