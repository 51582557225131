import { IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses } from "../../../../../../../../../../schema";

const RelativeDropdown: React.FC<{
  paragraphClasses: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  handleSelectChange: (arg: any, id: string) => void
  lineId: string
  lineClass: string
}> = ({ paragraphClasses , handleSelectChange, lineId, lineClass}) => {
  return (
    <RelativeDropdownStyleWrapper>
      <IonSelect interface="popover"  placeholder="Välj stil" className="select" value={lineClass} onIonChange={(e) => handleSelectChange(e, lineId)}>
        {paragraphClasses &&
          paragraphClasses.map((pc, index) => {
            return (
              <IonSelectOption key={index} value={pc.id} >
                {pc.name}
              </IonSelectOption>
            );
          })}
      </IonSelect>
    </RelativeDropdownStyleWrapper>
  );
};

const RelativeDropdownStyleWrapper = styled.div`
  .select {
    --padding-start: 0;
    --padding-end: 0;
  }
`;

export default RelativeDropdown;
