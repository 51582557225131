import React from "react";
import {
  IonAvatar,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import {
  callOutline,
  folderOutline,
  homeOutline,
  logOutOutline,
  mailOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";
import { PATH_LINK } from "../../App";
import { removeLocalStorageItem } from "../../utilities";
import styled from "styled-components";
import { AppContext } from "../../contexts/AppContextProvider";
import { useContextSelector } from "use-context-selector";
import SupportModal from "../SupportModal";

interface IItem {
  icon: any;
  label: string;
  active?: string;
  path?: string;
  action?: () => void;
  id?: string;
}

const SideMenu: React.FC<{
  isMenuDisabled: boolean
}> = (props) => {
  const menuRef = React.useRef<HTMLIonMenuElement | null>(null);
  const route = useIonRouter();
  const pathname = useHistory().location.pathname.toLowerCase();
  const modalRef = React.useRef<HTMLIonModalElement | null>(null);
  const user = useContextSelector(
    AppContext,
    (v) => v?.currentCustomer
  )?.user;

  const logout = () => {
    route.push("/login", "back", "replace");
    removeLocalStorageItem("token");
    window.location.reload();
  };

  const handleChangeRoute = (path: string) => {
    route.push(`${path}`, "root", "push");
    menuRef.current?.close(true);
  };

  const items: Array<IItem> = [
    {
      icon: homeOutline,
      label: "Hem",
      active: pathname === PATH_LINK.DASHBOARD ? "secondary" : "dark",
      path: PATH_LINK.DASHBOARD,
    },
    // {
    //   icon: folderOutline,
    //   label: "Nyheter",
    //   active: pathname === PATH_LINK.ARCHIVE ? "secondary" : "dark",
    //   path: PATH_LINK.NEWS,
    // },
    {
      icon: folderOutline,
      label: "Aktarkiv",
      active: pathname === PATH_LINK.ARCHIVE ? "secondary" : "dark",
      path: PATH_LINK.ARCHIVE,
    },
    {
      icon: callOutline,
      label: "Support",
      active: "dark",
      id: "open-support-modal",
      action: () => {
        menuRef.current?.close();
      },
    },
    {
      icon: logOutOutline,
      label: "logga ut",
      action: logout,
    },
  ];

  return (
    <IonMenu
      ref={menuRef}
      side={"start"}
      type={"reveal"}
      contentId="main"
      swipeGesture
      disabled={props.isMenuDisabled}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Meny</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false} fullscreen>
        <SideMenuStyleWrapper>
          <IonList>
            {items.map((item, index) => (
              <IonItem
                key={index}
                id={item.id}
                onClick={() =>
                  item.path
                    ? handleChangeRoute(item.path)
                    : item.action && item.action()
                }
              >
                <IonIcon
                  color={item.active}
                  icon={item.icon}
                  slot={"start"}
                  size={"small"}
                />
                <IonLabel color={item.active}>{item.label}</IonLabel>
              </IonItem>
            ))}
          </IonList>
          <div className="chipContainer">
            <IonChip color="light" className="chip">
              <IonAvatar>
                <img
                  alt="Silhouette of a person's head"
                  src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg"
                />
              </IonAvatar>
              <IonLabel color="dark" className="chipTitle">
                {user?.username} ({user?.customers![0]!.office.name})
              </IonLabel>
            </IonChip>
          </div>

            <SupportModal modalRef={modalRef} />
        </SideMenuStyleWrapper>
      </IonContent>
    </IonMenu>
  );
};

const SideMenuStyleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 50px;
  .chipContainer {
    width: 100%;
    display: flex;
    .chipTitle {
      font-size: 12px;
    }
  }
`;

export default SideMenu;
