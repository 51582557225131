import React from "react";
import { IonTextarea, TextareaChangeEventDetail } from "@ionic/react";
import styled from "styled-components";
import {
  EditModelAdvertAreaContentEditorBehaviours,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines,
  TextAlignments,
} from "../../../../../../../../schema";
import LineEditorWrapper from "./LinesEditorWrapper";
import Tools from "./Tools";
import {type Swiper as SwiperRef } from 'swiper'


const TextEditorWrapper: React.FC<{
  inputValue?: string | null | undefined;
  onPlainTextChange: (value: string, row: number, column: number) => void;
  isLocked: boolean;
  column: number;
  row: number;
  isUpdateContentLoading: boolean;
  handleOpenVerseModal: () => void;
  isEnabledMultiColumns: boolean;
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours;
  handleAdvancedModeOpen: () => void;
  handleAdvancedModeClose: () => void;
  lines:
    | (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines | null)[]
    | null;
  textAlignment: TextAlignments | null;
  isAdvancedMode: boolean;
  isDemoValue: boolean;
  swpRef: React.MutableRefObject<SwiperRef | null>
}> = (props) => {
  const [isFontStyleMode, setFontStyleMode] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>();
  const [isFocus, setFocus] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!isFocus)
      setValue(props.inputValue!);
  },[props.inputValue, isFocus])

  const onSubmitTextChange = () => {
    if (props.onPlainTextChange!) {
      props.onPlainTextChange(value!, props.row, props.column);
    }
  };

  const handleTextChange = (e: CustomEvent<TextareaChangeEventDetail>) => {
    setValue(e.detail.value!);
    props.onPlainTextChange(e.detail.value!, props.row, props.column);
  };

  const handleSwitchFontStyleModeOpen = () => {
    // onSubmitTextChange();
    setFontStyleMode(true);
  };

  const handleSwitchFontStyleModeClose = () => setFontStyleMode(false);


  return (
    <TextEditorWrapperStyleWrapper>
      <Tools
        editorBehaviour={props.editorBehaviour}
        handleOpenVerseModal={props.handleOpenVerseModal}
        isLocked={props.isLocked}
        isEnabledMultiColumns={props.isEnabledMultiColumns}
        isAdvancedMode={props.isAdvancedMode}
        isFontStyleMode={isFontStyleMode}
        handleSwitchFontStyleModeOpen={handleSwitchFontStyleModeOpen}
        handleSwitchFontStyleModeClose={handleSwitchFontStyleModeClose}
        handleAdvancedModeOpen={props.handleAdvancedModeOpen}
        handleAdvancedModeClose={props.handleAdvancedModeClose}
        textAlignment={props.textAlignment}
        row={props.row}
        column={props.column}
      />

      {!isFontStyleMode && (
        <TextEditor
          value={value!}
          onTextChange={(e) => handleTextChange(e)}
          disabled={props.isLocked}
          onFocus={(e) => setFocus(true)}
          onBlur={(e) => setFocus(false)}
          isDemoValue={props.isDemoValue}
        />
      )}

      {isFontStyleMode && <LineEditorWrapper lines={props.lines!} swpRef={props.swpRef} />}
    </TextEditorWrapperStyleWrapper>
  );
};

const TextEditorWrapperStyleWrapper = styled.div`
  .textAreaFooter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .TextAreaHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const textAreaStyle = {
  border: "1px solid var(--ion-color-secondary)",
  borderRadius: "3px",
  padding: "5px",
  textAlign: "left",
  marginBottom: "100px"
};

const TextEditor: React.FC<{
  value: string;
  onTextChange: (arg: CustomEvent<TextareaChangeEventDetail>) => void;
  onBlur?: (arg: CustomEvent<FocusEvent>) => void;
  onFocus?: (arg: CustomEvent<FocusEvent>) => void;
  disabled: boolean;
  isDemoValue: boolean;
}> = ({ value, onTextChange, disabled, onBlur, onFocus,isDemoValue, ...rest }) => {
  const textArea = React.useRef<HTMLIonTextareaElement | null>(null)


  const handleOnFocus = (e:CustomEvent<FocusEvent>) => {
    if(onFocus) onFocus(e)
    if(isDemoValue && textArea?.current !== null ) {
      console.log("🚀 ~ file: index.tsx ~ line 129 ~ handleOnFocus ~ textArea.current.firstChild")
    }
  }



  return(
    <>
      <IonTextarea
        ref={textArea}
        rows={7}
        wrap={"soft"}
        inputMode={"text"}
        className={"textArea"}
        value={value}
        style={textAreaStyle}
        onIonChange={onTextChange}
        disabled={disabled}
        onIonBlur={onBlur}
        onIonFocus={handleOnFocus}
        {...rest}
      />
    </>
  )
} ;


export default TextEditorWrapper;
