import gql from "graphql-tag";

export const GET_ARCHIVE_CASE_Folder = gql`
  query GetArchiveCaseFolders(
    $scope: CaseFolderCustomerScope
    $filterByOrderStatus: OrderStatus
    $textFilter: String
    $startindex: Int!
    $pageLength: Int!
  ) {
    findCaseFoldersByCurrentCustomer(
      scope: $scope
      textFilter: $textFilter
      filterByOrderStatus: $filterByOrderStatus
      startindex: $startindex
      limit: $pageLength
    ) {
      count
      startIndex
      caseFolders {
        id
        name
        internalId
        propertiesFuneralStandard {
          externalId
          __typename
        }
        customer {
          id
          name
          __typename
        }
        productPackages {
          productPackage {
            id
            name
            __typename
          }
          documents {
            id
            internalId
            documentFormat {
              id
              documentType {
                id
                name
                friendlyName
                __typename
              }
              __typename
            }
            orders {
              id
              customerFriendlyId
              alerting {
                smallText
                text
                type
                __typename
              }
              mediaOrder {
                media {
                  id
                  friendlyName
                  __typename
                }
                documentFormat {
                  id
                  mediaAdaptedName: friendlyName(isShownWithMedia: true)
                  __typename
                }
                publishTimes {
                  time
                  __typename
                }
                status {
                  color
                  status
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        summary {
          countOrders
          mostCriticalStatus
          mostCriticalColor
          isMostCriticalTimeBookingWarning
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
