import gql from "graphql-tag";

export const GET_CURRENT_USER_SESSION = gql`
  query GetCurrentUserSession {
    currentUserSession {
      id
      isAuthenticated
      client {
        route {
          taps4UrlHome
          __typename
        }
        __typename
      }
      user {
        id
        username
        cultureCode
        isDemoUser
        isPowerUserOffice
        isPowerUserCompany
        isAssistentUser
        isSupportUser
        isSuperUser
        customers {
          id
          office {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      customer {
        id
        name
        settings {
          emailCustomer
          contactPhone
          __typename
        }
        colleagues {
        ...CustomerUserFragment
        __typename
      }
        office {
          id
          name
          company {
            id
            __typename
          }
          __typename
        }
        inheritSettings {
          ...SettingsFragment
          __typename
        }
        __typename
      }
      __typename
    }
  }

  fragment SettingsFragment on CustomerSettings {
    emailOffice
    contactPhone
    agencyHomepageName
    contactPhone
    emailCustomer
    emailOrderReceipt
    emailOrderMemoriz
    emailOffice
    emailOrderMemoriz
    postAddressCity
    postAddressPostcode
    postAddressStreet
    orderPriceEndCustomerRule
    isDefaultProofSuggestionThanksAdvert
    isDefaultParallelPublishInFamiljesidan
    isDefaultParallelPublishInMinnesrummet
    isDefaultParallelPublishInAgencyHomepage
    isDefaultMyPageEnabled
    isMemoryRoomUrlEnabled
    favoriteMedias
    __typename
  }

  fragment CustomerUserFragment on Customer {
    id
    name
    firstName
    lastName
    inheritSettings {
      contactPhone
      __typename
    }
    office {
      id
      name
      __typename
    }
    primaryUser {
      id
      username
      enabled
      __typename
    }
    __typename
  }
`;
