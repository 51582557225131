import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import DebounceLink from "apollo-link-debounce";
import React from "react";
import { getLocalStorageItem } from "./utilities";

export const ApolloClientProvider: React.FunctionComponent<any> = (props) => {
  const [token, setToken] = React.useState<string | null>(null);

  React.useEffect(() => {
    getLocalStorageItem("token")
      .then((value) => {
        if (value !== undefined && value !== null) {
          setToken(value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          Authorization: token && `${token}`,
        },
      });
      return forward ? forward(operation) : null;
    });

    const backendEndPoint = process.env.REACT_APP_BACKEND_URL // process.env.REACT_APP_BACKEND_URL
    // console.log(`BackendUrl : ${backendEndPoint}`)
    const httpLink = createHttpLink({
      uri: backendEndPoint ??  "https://dev-taps5-backend-webapi.timecut.se/graphql",
    });

    const client = new ApolloClient({
      link: ApolloLink.from([new DebounceLink(350), authLink, httpLink]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
      queryDeduplication: false,
      defaultOptions: {
        query: {
          fetchPolicy: "network-only",
        },
      },
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;



};
