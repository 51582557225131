import React from "react";
import "./styles.css";
import styled from "styled-components";
import {
  DatetimeChangeEventDetail,
  IonDatetime,
  IonIcon,
  IonInput,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import { calendar } from "ionicons/icons";


const DatePickerInput: React.FC<{
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  value?: any;
  onChange: (arg: any) => void;
  monthValues?: number | number[] | string 
  dayValues?: number | number[] | string
  showDefaultButtons?: boolean
  name?: string
  presentation?: "date" | "date-time" | "month" | "month-year" | "time" | "time-date" | "year"
  placeholder?: string
  isDateEnable?: (dates: any) => boolean
}> = (props) => {
  const [openDatePicker, setOpenDatePicker] = React.useState<boolean>(false);
  const dateRef = React.useRef<null | HTMLIonDatetimeElement>(null)

  const handleOpenDatePicker = () => {
    if (!props.disabled) setOpenDatePicker(true);
  };
  const handleCloseDatePicker = () => setOpenDatePicker(false);

  const handleDatePickerChange = (
    e: CustomEvent<DatetimeChangeEventDetail>
  ) => {
      e.stopPropagation()
      props.onChange(e);
  };
  
  return (
    <DatePickerInputStyleWrapper>
      <div
        className={`inputWrapper ${props.className}`}
        onClick={handleOpenDatePicker}
      >
        <IonInput
          className="input"
          disabled={props.disabled}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
        >
          {props.isLoading && <IonSpinner name="circles" color="secondary" />}

          {!props.isLoading && (
            <IonIcon
              icon={calendar}
              className="calenderIcon"
              color={"secondary"}
            />
          )}
        </IonInput>
      </div>

      <IonModal
        isOpen={openDatePicker}
        onDidDismiss={handleCloseDatePicker}
        className={"datePickerModal"}
        id="DatePickerModalId"
        trigger="open-custom-dialog"
        showBackdrop={true}
      >
        <div className="wrapper">
          <div className="datePickerWrapper">
            <IonDatetime
              ref={dateRef}
              slot="buttons"
              className={"datePicker"}
              presentation={props.presentation ? props.presentation : "date"}
              locale={"sv-SE"}
              onIonChange={(e) => {
                handleDatePickerChange(e)
                // handleCloseDatePicker()
              }}
              color={"primary"}
              value={props.value}
              disabled={props.disabled}
              size={"cover"}
              monthValues={props.monthValues}
              dayValues={props.dayValues}
              showDefaultButtons={props.showDefaultButtons}
              doneText={"Välj"}
              cancelText={"Stäng"}
              // defaultChecked={true}
              isDateEnabled={props.isDateEnable}
            >
              <span slot="time-label">Tid</span>
            </IonDatetime>
          </div>
        </div>
      </IonModal>
    </DatePickerInputStyleWrapper>
  );
};

const DatePickerInputStyleWrapper = styled.div`
  .inputWrapper {
    .input {
      --background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%)
        0% 0% no-repeat padding-box;
      border-radius: 3px;
      border: 1px solid #d8d8d8;
      position: relative;
      color: var(--ion-color-custom-dark);
      .calenderIcon {
        padding: 5px;
        font-size: 20px;
        position: absolute;
        right: 0;
      }
    }
  }

  .datePickerWrapper {
    border: 1px solid red;
  }
`;

export default DatePickerInput;
