import gql from "graphql-tag";
import AREA_FRAGMENT from "./areaFragment";

const EDIT_MODEL_ADVERT_FRAGMENT = gql`
  fragment editModelAdvertFragment on EditModelAdvert {
    isEnabledUpdateOrdersContent
    basePage {
      areas {
        ...AreaFragment
        __typename
      }
      __typename
    }
    __typename
  }
  ${AREA_FRAGMENT}
`;

export default EDIT_MODEL_ADVERT_FRAGMENT;
