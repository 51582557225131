import gql from "graphql-tag";

const EDIT_MODEL_LIVE_STREAM_FRAGMENT = gql`
  fragment editModelLiveStreamFragment on EditModelLiveStream {
    isStored
    isActivated
    timeLive
    waitImageUrl
    ceremonyAgendaUrl
    advanced {
      playerUrl
      ingestionUrl
      streamName
      RecorderLarix500: recorderUrl(
        recorder: LARIX
        platform: IOS
        bitRate: 500
      )
      RecorderLarix1000: recorderUrl(
        recorder: LARIX
        platform: IOS
        bitRate: 1000
      )
      RecorderLarix1500: recorderUrl(
        recorder: LARIX
        platform: IOS
        bitRate: 1500
      )
      RecorderLarix2000: recorderUrl(
        recorder: LARIX
        platform: IOS
        bitRate: 2000
      )
      GoCoderIOS: recorderUrl(recorder: GOCODER, platform: IOS, bitRate: 2000)
      GoCoderAndriod: recorderUrl(
        recorder: GOCODER
        platform: ANDRIOD
        bitRate: 2000
      )
      qrRecorderLarix500: qrUrl(recorder: LARIX, platform: IOS, bitRate: 500)
      qrRecorderLarix1000: qrUrl(recorder: LARIX, platform: IOS, bitRate: 1000)
      qrRecorderLarix1500: qrUrl(recorder: LARIX, platform: IOS, bitRate: 1500)
      qrRecorderLarix2000: qrUrl(recorder: LARIX, platform: IOS, bitRate: 2000)
      qrGoCoderIOS: qrUrl(recorder: GOCODER, platform: IOS, bitRate: 2000)
      qrGoCoderAndriod: qrUrl(
        recorder: GOCODER
        platform: ANDRIOD
        bitRate: 2000
      )
      GoCoderAndroid: recorderUrl(
        recorder: GOCODER
        platform: ANDRIOD
        bitRate: 2000
      )
      __typename
    }
    __typename
  }
`;

export default EDIT_MODEL_LIVE_STREAM_FRAGMENT;
