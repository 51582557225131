import { gql } from "graphql-tag";

export const GET_GALLERY_IMAGES = gql`
  query GetGalleryImages(
    $input: GalleryImageAvailableImagesInput!
    $filterTagGroupId: ID!
  ) {
    galleryImagesByTags(input: $input) {
      isSupportTags
      totalImageCount
      startIndex
      totalImageCount
      actuallyPageLength
      pageLength
      images {
        id
        publicId
        thumbnail100x80Url
        tagGroups(filter: [{ tagGroupId: $filterTagGroupId, tags: [] }]) {
          tags
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;