import React from "react";
import { IonContent, IonItem, IonList, IonPopover } from "@ionic/react";
import { closeOutline, menuOutline, searchOutline } from "ionicons/icons";
import Button from "../../../../components/Button";
import {
  EditModelTypes,
  GetOrderById_orders_mediaOrder_edit_userInterface,
} from "../../../../schema";
import LockButton from "./LockButton";
import UnpublishedButton from "./UnpublishButton";
import styled from "styled-components";
import MoveToMemoryRoomButton from "./MoveToMemoryRoomButton";
import ContentInitiator from "./ContentInitiator";
import ReactiveButton from "./ReactiveButton";
import ExhibitOrderButton from "./ExhibitOrderButton";
import ProofModal from "../../../../components/ProofModel";
import Remove from "./Remove";

const ProductMenu: React.FC<{
  orderId: string;
  isLocked: boolean;
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined;
  publishTime: Date | undefined;
  caseFolderId: string;
  editModelType: EditModelTypes;
  handleOpenZoomRange: () => void;
}> = (props) => {
  const popover = React.useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
  const [openProof, setOpenProof] = React.useState<boolean>(false);
  const { MEMORY_ROOM, ADVERT, LIVE_STREAM } = EditModelTypes;
  const isMenuButtonMemoryRoomVisible =
    (props.editModelType === MEMORY_ROOM &&
      props.userInterface?.isUnlockButton) ||
    props.userInterface?.isReactivateButton ||
    props.userInterface?.isUnPublishButton;

  const openPopover = (e?: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const dismissPopover = () => {
    popover.current?.dismiss();
  };

  const handleOpenZoomTools = () => {
    props.handleOpenZoomRange();
    dismissPopover();
  };

  return (
    <>
      {isMenuButtonMemoryRoomVisible ? (
        <Button
          size={"small"}
          variants={"secondary_noLine"}
          icon={popoverOpen ? closeOutline : menuOutline}
          iconDir={"icon-only"}
          onClick={openPopover}
        ></Button>
      ) : null}

      {props.editModelType === ADVERT ? (
        <Button
          size={"small"}
          variants={"secondary_noLine"}
          icon={popoverOpen ? closeOutline : menuOutline}
          iconDir={"icon-only"}
          onClick={openPopover}
        ></Button>
      ) : null}

      {props.editModelType === LIVE_STREAM && !props.userInterface?.isLockButton ? (
        <Button
          size={"small"}
          variants={"secondary_noLine"}
          icon={popoverOpen ? closeOutline : menuOutline}
          iconDir={"icon-only"}
          onClick={openPopover}
        ></Button>
      ) : null}

      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        size={"auto"}
      >
        <IonContent class="ion-padding content">
          <ProductMenuStyleWrapper>
            <IonList lines="none" inset={false} className={"listItem"}>
              {props.editModelType === ADVERT && (
                <IonItem className="productItem">
                  <Button
                    variants={"secondary_noLine"}
                    className="button"
                    onClick={() => {
                      setOpenProof(true);
                      dismissPopover();
                    }}
                  >
                    Korrektur
                  </Button>
                </IonItem>
              )}

              {props.userInterface?.isCopyFuneralInfoToMemoryRoom && (
                <IonItem className="productItem">
                  <MoveToMemoryRoomButton
                    orderId={props.orderId!}
                    copyFuneralInfoToMemoryRoomText={
                      props.userInterface.copyFuneralInfoToMemoryRoomText!
                    }
                    dismissPopover={dismissPopover}
                  />
                </IonItem>
              )}

              {((props.userInterface?.isLockButton &&
                props.editModelType !== MEMORY_ROOM) ||
                props.userInterface?.isUnlockButton) && (
                <IonItem className="productItem">
                  <LockButton
                    orderId={props.orderId}
                    isLocked={props.isLocked}
                    lockButtonText={props.userInterface?.lockButtonText}
                    unlockButtonText={props.userInterface.unlockButtonText}
                    isDisabled={!props.publishTime}
                    dismissPopover={dismissPopover}
                    editModelType={props.editModelType}
                  />
                </IonItem>
              )}

              {props.userInterface?.isUnPublishButton && (
                <IonItem className="productItem">
                  <UnpublishedButton
                    orderId={props.orderId!}
                    unPublishButtonText={
                      props.userInterface.unPublishButtonText!
                    }
                    dismissPopover={dismissPopover}
                  />
                </IonItem>
              )}
              {props.editModelType === ADVERT && (
                <IonItem className="productItem">
                  <Remove
                    orderId={props.orderId}
                    isLocked={props.isLocked}
                    dismissPopover={dismissPopover}
                  />
                </IonItem>
              )}

              {props.userInterface?.isReactivateButton && (
                <IonItem className="productItem">
                  <ReactiveButton
                    orderId={props.orderId}
                    reactiveButtonText={
                      props.userInterface.reactivateButtonText
                    }
                    dismissPopover={dismissPopover}
                  />
                </IonItem>
              )}

              {props.editModelType === ADVERT &&
                props.userInterface?.isSetExhibitOrder && (
                  <ExhibitOrderButton
                    orderId={props.orderId}
                    exhibitText={props.userInterface.setExhibitOrderText}
                    caseFolderId={props.caseFolderId}
                    dismissPopover={dismissPopover}
                  />
                )}

              {props.editModelType === ADVERT &&
                props.userInterface
                  ?.isUpdateCustomerDocumentContentInitiator && (
                  <IonItem className="productItem">
                    <ContentInitiator
                      orderId={props.orderId}
                      InitiatorText={
                        props.userInterface
                          .updateCustomerDocumentContentInitiatorText
                      }
                      dismissPopover={dismissPopover}
                    />
                  </IonItem>
                )}
              {props.editModelType === ADVERT && (
                <IonItem className="productItem">
                  <Button
                    variants={"secondary_noLine"}
                    className="button"
                    onClick={handleOpenZoomTools}
                    icon={searchOutline}
                    iconDir={"start"}
                  >
                    Förstora annons
                  </Button>
                </IonItem>
              )}
            </IonList>
          </ProductMenuStyleWrapper>
        </IonContent>
        <ProofModal
          isOpen={openProof}
          setIsIOpen={setOpenProof}
          doClose={() => setOpenProof(false)}
        />
      </IonPopover>
    </>
  );
};

const ProductMenuStyleWrapper = styled.div`
  .listItem {
    padding: 0;
  }
  .productItem {
    --padding-start: 0px;
    --inner-padding-start: 0px;
    --padding-left: 0px;
    --padding-right: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
  }
  .button {
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }
`;

export default ProductMenu;
