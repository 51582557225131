import gql from "graphql-tag"

const MESSAGING_ORDER_INFO = gql`
  mutation MessagingOrderInfo($input: OrderCollectionMessagingInfoInput!) {
    messagingOrderInfo(input: $input) {
      isError
      errorReason
      errorStack
    }
  }
`

export default MESSAGING_ORDER_INFO
