import { IonContent, IonLabel, IonModal, IonToolbar } from "@ionic/react";
import React from "react";
import CaseFolderForm from "../../../../components/CaseFolderForm";
import Modal from "../../../../components/Modal";
import {
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer,
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard,
} from "../../../../schema";

const CaseFolderFormModal: React.FC<{
  isOpen: boolean;
  doClose: () => void;
  doOpen: () => void;
  funeralProperties:
    | GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard
    | undefined;
  documentId: string | null | undefined;
  customer:
    | GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer
    | null
    | undefined;
}> = (props) => {
  const modalRef = React.useRef<HTMLIonModalElement | null>(null)

  return (
    <IonModal
      ref={modalRef}
      // isOpen={props.isOpen}
      // onDidDismiss={props.doClose}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      swipeToClose={true}
      trigger={"openCaseFolderForm"}
    >
      <IonToolbar>
        <IonLabel></IonLabel>
      </IonToolbar>
      <IonContent fullscreen>
        <CaseFolderForm
          funeralProperties={props.funeralProperties}
          documentId={props.documentId}
          customer={props.customer}
          doExit={props.doClose}
          modalRef={modalRef}
          doOpen={props.doOpen}
        />
      </IonContent>
    </IonModal>
  );
};

export default CaseFolderFormModal;
