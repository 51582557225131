import React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonText,
  useIonRouter,
} from "@ionic/react";
import styled from "styled-components";
import Status from "../../../components/Status";
import Button from "../../../components/Button";
import moment from "moment";
import "moment/locale/sv";
import { calendarOutline, readerOutline } from "ionicons/icons";
import { IProducts } from "..";
import { useMutation } from "@apollo/client";
import { TouchCaseFolder, TouchCaseFolderVariables } from "../../../schema";
import { TOUCH_CASE_FOLDER } from "../../../graphql/mutation/TouchCaseFolder";

const ArchiveDetailsModal: React.FC<{
  isOpen: boolean;
  doClose: () => void;
  selectedProducts: {
    products: IProducts;
    caseFolderId: string;
  };
}> = ({ isOpen, doClose, selectedProducts }) => {
  const router = useIonRouter();
  const modal = React.useRef<HTMLIonModalElement>(null);

  const [mutateTouchCaseFolder] = useMutation<
    TouchCaseFolder,
    TouchCaseFolderVariables
  >(TOUCH_CASE_FOLDER);

  const handleMoveEditPage = (
    caseFolderId: string | null,
    orderId: string | null
  ) => {
    router.push(`/editOrder/c/${caseFolderId}/o/${orderId}`, "forward", "push");
    mutateTouchCaseFolder({
      variables: {
        id: caseFolderId!,
      },
    });
    modal.current?.dismiss();
  };

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      onWillDismiss={doClose}
      animated={true}
      initialBreakpoint={0.95}
      breakpoints={[0, 0.95]}
      canDismiss={true}
    >
      <IonContent fullscreen>
        <ArchiveDetailsModalsStyleWrapper>
          <div className="caseFolderDetailsFolderContainer">
            {selectedProducts &&
              selectedProducts?.products!.map((product, index) => {
                return (
                  <IonAccordionGroup key={index} className={"accordionGroup"}>
                    {product?.documents.map((product, index) => {
                      return (
                        <div key={index} className={"productContainer"}>
                          <div className="productTitleWrapper">
                            <IonLabel className={"documentTitle"}>
                              {
                                product?.documentFormat.documentType
                                  .friendlyName
                              }
                            </IonLabel>

                            <div className="productInternalId">
                              <IonText> {product?.internalId}</IonText>
                            </div>
                          </div>

                          {product?.orders.map((order, index) => {
                            return (
                              <IonAccordion key={index} className={"accordion"}>
                                <IonItem
                                  slot="header"
                                  lines="none"
                                  className="accordionItem"
                                >
                                  <IonLabel className="label">
                                    {order.mediaOrder?.media.friendlyName}
                                  </IonLabel>

                                  <div className="orderCustomerFriendlyId">
                                    {order.customerFriendlyId}
                                  </div>
                                </IonItem>
                                <div
                                  className="accordionContent ion-padding "
                                  slot="content"
                                >
                                  <div className="orderInfo">
                                    <div className="statusWrapper">
                                      <Status
                                        text={order.mediaOrder?.status.status}
                                        color={order.mediaOrder?.status.color!}
                                        isTextMode={true}
                                      />
                                    </div>

                                    {order.mediaOrder?.documentFormat
                                      .mediaAdaptedName && (
                                      <div className="item-row">
                                        <IonIcon
                                          className="icon"
                                          icon={readerOutline}
                                          color={"secondary"}
                                        />
                                        <IonText className="text">
                                          {
                                            order.mediaOrder?.documentFormat
                                              .mediaAdaptedName
                                          }
                                        </IonText>
                                      </div>
                                    )}

                                    <div className="datumWrapper item-row">
                                      <IonIcon
                                        className="icon"
                                        icon={calendarOutline}
                                        color={"secondary"}
                                      />
                                      <IonText className="text">
                                        {moment(
                                          order.mediaOrder?.publishTimes[0]
                                            ?.time
                                        )
                                          .locale("sv")
                                          .format("yy-MM-DD ddd")}
                                      </IonText>
                                    </div>
                                  </div>
                                  {product.documentFormat.documentType.name.toLowerCase() !==
                                    "programkort" && (
                                    <div className="buttonContainer">
                                      <Button
                                        size="small"
                                        variants={"secondary_outline"}
                                        onClick={() =>
                                          handleMoveEditPage(
                                            selectedProducts.caseFolderId!,
                                            order.id
                                          )
                                        }
                                      >
                                        Redigera
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </IonAccordion>
                            );
                          })}
                        </div>
                      );
                    })}
                  </IonAccordionGroup>
                );
              })}
          </div>
        </ArchiveDetailsModalsStyleWrapper>
      </IonContent>
    </IonModal>
  );
};

const ArchiveDetailsModalsStyleWrapper = styled.div`
  .caseFolderDetailsFolderContainer {
    margin-top: 25px;
    padding: 10px;
    .accordionGroup {
      .productContainer {
        .productTitleWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 20px;
          margin-bottom: 8px;
          .productInternalId,
          .documentTitle {
            font-weight: bold;
            font-size: 15px;
          }
        }
        .accordion {
          margin: 10px 0;
          border: 1px solid transparent;
          border-radius: 5px;
          -webkit-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
          -moz-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
          box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
          .ion-accordion-toggle-icon {
            color: var(--ion-color-secondary-tint);
          }
          &.accordion-expanded {
            border: 1px solid var(--ion-color-tertiary);
            border-radius: 3px;
          }
          .accordionItem {
            --border-color: transparent;
            .orderCustomerFriendlyId,
            .label {
              font-size: 15px;
            }
          }
          .accordionContent {
            background-color: var(--ion-color-light-tint);
            padding-bottom: 5px;
            .orderInfo {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              column-gap: 8px;
              row-gap: 10px;
              margin-bottom: 20px;
              .item-row {
                display: flex;
                align-items: center;
                column-gap: 5px;
                .icon {
                  font-size: 19px;
                }
                .text {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ArchiveDetailsModal;
