import gql from "graphql-tag"
import UPDATE_CASE_FOLDER_RESPONSE_FRAGMENT from "../fragments/updateCaseFolderResponseFragment"


const UPDATE_CASE_FOLDER = gql`
  mutation UpdateCaseFolder($input: MutationUpdateCaseFolderInput!) {
    updateCaseFolder(input: $input) {
      isError
      errorReason
      caseFolder {
        ...UpdateCaseFolderResponseFragment
      }
    }
  }
  ${UPDATE_CASE_FOLDER_RESPONSE_FRAGMENT}
`

export default UPDATE_CASE_FOLDER