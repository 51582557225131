import React from "react";
import { IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";
import { IProofSetting } from "..";

const ProofSettings: React.FC<{
  proofSettings: IProofSetting;
  onChange: (key:"isSuggestionMediaOrders" | "isVoucherKey" ,arg: boolean) => void
}> = (props) => {
  return (
    <div>
      <IonLabel className="label">Visa/Dölj på Korrektur</IonLabel>
      <IonList lines="none">
        <IonItem className="item">
          <IonLabel className="checkboxLabel">Tackannonsförslag</IonLabel>
          <IonCheckbox
            className="checkbox"
            slot="start"
            color="success"
            checked={props.proofSettings.isSuggestionMediaOrders}
            onIonChange={(e) => props.onChange( "isSuggestionMediaOrders",e.detail.checked)}
          />
        </IonItem>
        <IonItem className="item">
          <IonLabel className="checkboxLabel">Nyckel till Minnesrum</IonLabel>
          <IonCheckbox
            className="checkbox"
            slot="start"
            color="success"
            checked={props.proofSettings.isVoucherKey}
            onIonChange={(e) => props.onChange("isVoucherKey",e.detail.checked)}
          />
        </IonItem>
      </IonList>
    </div>
  );
};

export default ProofSettings;
