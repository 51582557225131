import React from "react";
import styled from "styled-components";
import { I_FormikValue } from "..";
import RcDatePicker from "../../../../../components/RcDatePicker";
import RcInput from "../../../../../components/RcInput";
import { FormikProps } from "formik";
import moment from "moment";
import { InputChangeEventDetail } from "@ionic/react";

const CaseInputs: React.FC<{
  dateFormat: string;
  formik: FormikProps<I_FormikValue>;
  updateContent: (arg: any) => void;
  debounce: number;
}> = (props) => {
  const {
    personFirstname,
    personLastname,
    personTimeBorn,
    personTimeDied,
    personCityDied,
    personBornLastname,
  } = props.formik.values;
  const { formik, dateFormat, updateContent, debounce } = props;

  const handleTextInputChange = (
    e: CustomEvent<HTMLIonInputElement | InputChangeEventDetail>,
    name: string
  ) => {
    const value = e.detail.value;
    formik.setFieldValue(name, value);
    updateContent({
      case: {
        [name]: value,
      },
    });
  };

  const handleDateInputChange = (
    e: CustomEvent<HTMLIonInputElement | InputChangeEventDetail>,
    name: string
  ) => {
    const value = e.detail.value;
    const dateUtc = moment.utc(value);
    const convertToLocal = dateUtc.local().set({ h: 12, m: 0 });
    formik.setFieldValue(name, value);
    updateContent({
      case: {
        [name]: {
          value: convertToLocal,
        },
      },
    });
  };

  return (
    <CaseInputsStyleWrapper>
      <div className="deceased-InputBlock">
        <RcInput
          type={"text"}
          className="input"
          label="Förnamn"
          name={"personFirstname"}
          placeholder="Förnamn"
          value={personFirstname}
          debounce={debounce}
          onChange={(e) => {
            handleTextInputChange(e, e.target.name);
          }}
          errorMsg={formik.errors.personFirstname}
        />
        <RcInput
          type={"text"}
          className="input"
          label="Efternamn"
          name={"personLastname"}
          placeholder="Efternamn"
          value={personLastname}
          debounce={debounce}
          onChange={(e) => {
            handleTextInputChange(e, e.target.name);
          }}
          errorMsg={formik.errors.personLastname}
        />
        <RcDatePicker
          label="Födelsedatum *"
          className="input"
          placeholder="Födelsedatum"
          name={"personTimeBorn"}
          value={moment(personTimeBorn).format(dateFormat)}
          onChange={(e) => {
            handleDateInputChange(e, "personTimeBorn");
          }}
          errorMsg={formik.errors.personTimeBorn}
        />

        <RcDatePicker
          label="Dödsdatum *"
          className="input"
          name={"personTimeDied"}
          placeholder="Dödsdatum"
          value={moment(personTimeDied).format(dateFormat)}
          onChange={(e) => {
            handleDateInputChange(e, "personTimeDied");
          }}
          errorMsg={formik.errors.personTimeDied}
        />

        <RcInput
          type={"text"}
          className="input"
          label="Hemstad"
          name={"personCityDied"}
          placeholder="Hemstad"
          value={personCityDied}
          debounce={debounce}
          onChange={(e) => {
            handleTextInputChange(e, e.target.name);
          }}
        />
        <RcInput
          type={"text"}
          className="input"
          label="Födelsenamn"
          name={"personBornLastname"}
          placeholder="Födelsenamn"
          value={personBornLastname}
          debounce={debounce}
          onChange={(e) => {
            handleTextInputChange(e, e.target.name);
          }}
        />
      </div>
    </CaseInputsStyleWrapper>
  );
};

const CaseInputsStyleWrapper = styled.div`
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid var(--ion-color-medium-tint);
  .deceased-InputBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    .input {
      width: 165px;
      .rcLabel {
        font-size: 14px;
      }
      &.datePickerWrapper {
        .labelWrapper {
          margin-top: 10px;
          padding-bottom: 5px;
        }
      }
    }
  }
`;

export default CaseInputs;
