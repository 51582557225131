import gql from "graphql-tag";

export const MEDIATE_MATERIAL = gql`
  mutation MediateMaterial($input: MutationMessagingOrdersMaterialsInput!) {
    messagingOrdersMaterials(input: $input) {
      isError
      errorReason
      material {
        proof {
          url
        }
        galleryImages {
          url
        }
        orders {
          previewPng {
            url
          }
        }
      }
    }
  }
`;
