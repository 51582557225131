import React from "react";
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonText,
} from "@ionic/react";
import { getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts } from "../../../../../../../../../schema";
import styled from "styled-components";
import cx from "classnames";

const VersesContainer: React.FC<{
  versesList: getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts[];
  selectedVerse: (arg: string) => void;
}> = (props) => {
  // const [isAlisInfo, setIsAlisInfo] = React.useState<boolean>(false);

  return (
    <VersesContainerStyleWrapper>
      <IonCard className="versesContainer" mode="ios">
        {props.versesList.map((verse, index) => {
          return (
            <IonItem className="verseItem" key={index} mode={"ios"}>
              <IonCardContent className="verseItemContent" mode={"ios"}>
                <IonText
                  onClick={() => props.selectedVerse(verse.textPlain! + verse.author)}
                  dangerouslySetInnerHTML={{ __html: verse.textXml }}
                ></IonText>

                <div className="verseItemFooter">
                  <span className="author">{verse.author}</span>
                  <div className="rightColumn">
                    <div
                      className="alisContainer"
                      // onClick={() => setIsAlisInfo(true)}
                    >
                      <span
                        className={cx("alis", {
                          verified: verse.isAlisVerified,
                        })}
                      >
                        ALIS
                      </span>
                    </div>
                    <div className="versNumber">
                      <span>Nr. {verse.internalId}</span>
                    </div>
                  </div>
                </div>
              </IonCardContent>
            </IonItem>
          );
        })}
        {/* <IonToast
          isOpen={isAlisInfo}
          onDidDismiss={() => setIsAlisInfo(false)}
          message="Administration av litterära rättigheter i Sverige"
          color={"dark"}
          duration={3000}
        /> */}
      </IonCard>
    </VersesContainerStyleWrapper>
  );
};

const VersesContainerStyleWrapper = styled.div`
  .versesContainer {
    .verseItem {
      .verseItemContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        padding-top: 20px;
        .verseItemFooter {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .author {
            font-size: 14px;
            font-weight: bold;
          }
          .rightColumn {
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .alisContainer {
              .alis {
                background-color: #d7d7d7;
                color: #fff;
                padding: 5px;
                font-size: 11px;
                border-radius: 3px;
                &.verified {
                  background-color: #6bd1aa;
                }
              }
            }
            .versNumber {
              width: auto;
              span {
                font-size: 13px;
                margin-left: 8px;
                color: var(--ion-color-medium);
                overflow-wrap: ;
              }
            }
          }
        }
      }
    }
  }
`;

export default VersesContainer;
