import React from "react";
import { IonCard, IonCardContent, IonIcon, IonLabel } from "@ionic/react";
import styled from "styled-components";
import { arrowDown, arrowUp, closeCircleOutline } from "ionicons/icons";
import cx from "classnames";
import Button from "../Button";
import { Preferences } from "@capacitor/preferences";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const TipsBox: React.FC<{
  description: string;
  description2?: string;
  icon?: any;
  className?: string;
  position: string;
}> = (props) => {
  const [isBoxHidden, setIsBoxHidden] = React.useState<boolean>(false);
  const [positionValue, setPositionValue] = React.useState<any>();

  React.useEffect(() => {
    checkName()
  }, []);

  const checkName = async () => {
    const { value } = await Preferences.get({ key: props.position });
    setPositionValue(value ? true : false);
  };

  const handleHideTipsBox = () => {
    const setName = async () => {
      return await Preferences.set({
        key: props.position,
        value: props.position,
      });
    };
    setName();
    setIsBoxHidden(true);
  };

  return (
    <TipsBoxStyleWrapper>
      <IonCard
        className={cx(`tipsCard `, props.className, {
          hide: isBoxHidden || positionValue,
        })}
      >
        <IonCardContent className="tipsCardContent">
          <div className="leftCol">
            {props.icon ? (
              <div className="singleIconWrapper">
                <FontAwesomeIcon icon={props.icon} />
              </div>
            ) : (
              <div className="doubleIconWrapper">
                <FontAwesomeIcon icon={faArrowUp} />
                <FontAwesomeIcon icon={faArrowDown} />
                {/* <FontAwesomeIcon icon={arrowUp} />
                <FontAwesomeIcon icon={arrowDown} /> */}
              </div>
            )}
          </div>
          <div className="rightCol">
            <IonLabel className="title">Tips:</IonLabel>
            {props.description && <p>{props.description}</p>}
            {props.description2 && <p>{props.description2}</p>}
          </div>
        </IonCardContent>
        <Button
          onClick={handleHideTipsBox}
          className="closeBtn"
          variants={"gray-noLine"}
          icon={closeCircleOutline}
          iconDir={"icon-only"}
        ></Button>
      </IonCard>
    </TipsBoxStyleWrapper>
  );
};

const TipsBoxStyleWrapper = styled.div`
  .tipsCard {
    width: auto;
    position: relative;
    &.hide {
      display: none;
    }
    .tipsCardContent {
      display: flex;
      align-items: center;
      padding: 0;
      .leftCol {
        width: 100px;
        height: 100px;
        background-color: var(--ion-color-primary-light);
        border-bottom-right-radius: 110px;
        border-top-right-radius: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        .doubleIconWrapper {
          width: fit-content;
          display: flex;
          flex-direction: column;
          font-size: 20px;
          color: black;
          transform: rotate(90deg);
        }
        .singleIconWrapper {
          width: fit-content;
          font-size: 25px;
          color: black;
        }
      }
      .rightCol {
        margin-top: 25px;
        margin-left: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        .title {
          color: var(--ion-color-primary);
        }
        p {
          color: #000;
        }
      }
    }
    .closeBtn {
      width: min-content;
      position: absolute;
      top: 0;
      right: -8px;
    }
  }
`;

export default TipsBox;
