import { useApolloClient, useMutation } from "@apollo/client";
import { IonContent, IonProgressBar } from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { useContextSelector } from "use-context-selector";
import { OrderContext } from "..";
import UserInterFace from "../../../../components/UserInterface";
import { UPDATE_ORDER_CONTENT } from "../../../../graphql/mutation/updateOrderContent";
import {
  EditModelLiveStreamInput,
  UpdateOrderContent,
  UpdateOrderContentVariables,
} from "../../../../schema";
import FooterToolbarContainer from "../../FooterToolbarContainer";
import ActiveLiveStream from "./ActiveLiveStream";
import BeforeActiveLiveSteam from "./BeforeActiveLiveStream";

const LiveStream: React.FC = () => {
  const client = useApolloClient();
  const orderContextValues = useContextSelector(OrderContext, (v) => v);
  const order = orderContextValues?.order;
  const isLocked = order?.mediaOrder?.isLocked;
  const isActivated = isLocked ?? false;
  const liveStream = order?.mediaOrder?.edit.editModelLiveStream;
  const waitImageUrl = liveStream?.waitImageUrl;
  const userInterface = order?.mediaOrder?.edit.userInterface;


  const [mutateUpdateContent, { loading }] = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
    context: {
      debounceKey: "updateAdvertContentLiveStream",
      debounceTimeout: 500,
    },
  });

  const updateContent = (arg: EditModelLiveStreamInput) => {
    mutateUpdateContent({
      variables: {
        input: {
          orderId: order?.id!,
          editModel: {
            editModelLiveStream: {
              ...arg,
            },
          },
        },
      },
    });
  };

  return (
    <>
      {loading ? (
        <IonProgressBar type="indeterminate"></IonProgressBar>
      ) : (
        <div className="fill" style={{ height: "3px" }}></div>
      )}
      <IonContent className="bg-transparent">
        <LiveStreamStyleWrapper>
          {userInterface?.editStatusText && (
            <div className="userInterfaceWrapper">
              <UserInterFace userInterface={userInterface} />
            </div>
          )}
          <div className="liveStreamContainer">
            {!isActivated && (
              <BeforeActiveLiveSteam
                orderId={order?.id}
                waitImageUrl={waitImageUrl}
                updateContent={updateContent}
                ceremonyAgendaUrl={liveStream?.ceremonyAgendaUrl}
              />
            )}
            {isActivated && (
              <ActiveLiveStream
                liveStream={liveStream}
                waitImageUrl={waitImageUrl}
                orderId={order?.id}
              />
            )}
          </div>
        </LiveStreamStyleWrapper>
        {order && (
          <FooterToolbarContainer
            userInterface={order.mediaOrder?.edit.userInterface}
            publishTime={order.mediaOrder?.publishTimes[0]?.time!}
            alert={order.alerting}
            orderFriendlyId={order.customerFriendlyId}
            preview={order.mediaOrder?.preview}
            orderId={order.id}
            isLocked={order.mediaOrder?.isLocked!}
            documentId={order.document.id}
            status={order.mediaOrder?.status}
            editModelTypes={order.mediaOrder?.edit.editModelType}
          />
        )}
      </IonContent>
    </>
  );
};

const LiveStreamStyleWrapper = styled.div`
  background-color: white;
  .liveStreamContainer {
    padding: 10px;
  }
  .userInterfaceWrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
`;
export default LiveStream;
