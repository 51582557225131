import { IonIcon, IonNote } from '@ionic/react';
import { closeCircleOutline, cloudDownloadOutline, cloudUploadOutline } from 'ionicons/icons';
import React from 'react'
import { useDropzone } from 'react-dropzone';
import Button from '../../../../../components/Button';
import { IImageFile } from '../BeforeActiveLiveStream';

const UploadPdfButton: React.FC<{
    onDrop?: (file: IImageFile) => void;
    isUploaded?: boolean;
    clearPdfFile?: () => void
    downloadFile?: () => void
    isDownloadMode?: boolean
  }> = (props) => {
    const [error, setError] = React.useState<string | null>(null);
    const onDrop = React.useCallback((acceptedFiles) => {
      setError("");
      const file = acceptedFiles[0];
      const mimeTypes = ["application/pdf"];
      const incorrectMimetype = mimeTypes.indexOf(file.type) === -1;
  
      if (incorrectMimetype) {
        setError("kunde inte ladda upp. filtyp är felaktig");
        return;
      }
  
      const houndedKb = 1 * 1024;
      const maxSize = 20 * 1024 * 1024;
      const incorrectFileSize = file.size < houndedKb || file.size > maxSize;
  
      if (incorrectFileSize) {
        setError("bildstorlek är ogiltigt");
        return;
      }
  
      if (props.onDrop) {
        const imageSelectDialogFile: IImageFile = {
          fileFormInput: file,
          name: file.name,
          type: file.type,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          size: file.size,
          path: file.path,
          preview: URL.createObjectURL(file),
        };
        props.onDrop(imageSelectDialogFile);
      }
    }, []);
  
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      noDrag: true,
      multiple: false,
      maxFiles: 1,
      disabled: false,
    });
  
    return (
      <>
        {!props.isUploaded && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              variants={"secondary_outline"}
              icon={cloudUploadOutline}
              iconDir={"start"}
            >
              ladda upp
            </Button>
            <IonNote color="danger">{error}</IonNote>
          </div>
        )}
  
        {props.isUploaded && (
          <div style={{display: "flex", alignItems: "center"}}>
            <Button
              variants={"secondary_outline"}
              icon={cloudDownloadOutline}
              iconDir={"start"}
              onClick={() => props.downloadFile && props.downloadFile()}
            >
              ladda ner
            </Button>
            <IonIcon
              size={"large"}
              color={"secondary"}
              icon={closeCircleOutline}
              onClick={() => props.clearPdfFile && props.clearPdfFile()}
            />
          </div>
        )}
      </>
    );
  };

  export default UploadPdfButton