import React from "react";
import { IonButtons, IonHeader, IonMenuButton, IonToolbar, useIonRouter } from "@ionic/react";
import styled from "styled-components";
import { PATH_LINK } from "../../App";
import { arrowBackOutline } from "ionicons/icons";
import Button from "../Button";
import Logo from "../Logo";

const PageHeader: React.FC = () => {
  const route = useIonRouter();
  const isLogo =
    route.routeInfo.pathname === PATH_LINK.DASHBOARD ||
    route.routeInfo.pathname === PATH_LINK.ARCHIVE;

  const handleGoBack = () => {
    if (route.canGoBack()) {
      route.goBack();
    } else route.push(`${PATH_LINK.DASHBOARD}`, "root");
  };

  return (
    <ToolbarStyleWrapper>
      <IonHeader className="headerWrapper">
        <IonToolbar className="toolbarWrapper">
          <div className="toolbarContent">
            {isLogo ? (
              <Logo />
            ) : (
              <Button
                onClick={handleGoBack}
                variants={"secondary_noLine"}
                icon={arrowBackOutline}
                iconDir={"start"}
                className={"homeBtn"}
                size={"small"}
              >
                Tillbaka
              </Button>
            )}

           <div className="rightCol">
             {/*   <IonIcon
                icon={notifications}
                slot={"start"}
                className={"notificationIcon"}
              />*/}
              <IonButtons slot="end">
                <IonMenuButton color={"primary"}/>
              </IonButtons>
            </div> 
          </div>
        </IonToolbar>
      </IonHeader>
    </ToolbarStyleWrapper>
  );
};

const ToolbarStyleWrapper = styled.div`
  .headerWrapper {
    .toolbarWrapper {
      --background: white;
      -webkit-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
      -moz-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
      box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
      --border-color: transparent;
      .toolbarContent {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .homeBtn {
          width: auto !important;
          font-size: 15px;
        }
        .rightCol {
          width: 100px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          .notificationIcon {
            font-size: 20px;
            background-color: #acacac2e;
            padding: 7px;
            border-radius: 100%;
          }
        }
      }
    }
  }
`;
export default PageHeader;
