import React from "react";
import { GET_AVAILABLE_TAGS } from "../../../../../../../../../graphql/queries/getAvailableTags";
import { useApolloClient, useQuery } from "@apollo/client";
import ErrorAlert from "../../../../../../../../../components/ErrorAlert";
import { ITags } from "..";
import Select, { components } from "react-select";
import { IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";

const SearchContainer: React.FC<{
  handleTagUpdate: any;
  selectedTags: ITags[] | undefined;
}> = (props) => {
  const client = useApolloClient();
  const [newTag, setNewTag] = React.useState<string>("");

  const { data, loading, error } = useQuery(GET_AVAILABLE_TAGS, {
    client,
    fetchPolicy: "network-only",
    variables: {
      input: {
        newTag,
        tags:
          props.selectedTags === undefined
            ? [" "]
            : props.selectedTags.map((tag: any) => tag.value),

        tagGroupIds: [
          "00000000-0000-0000-0000-000000000010",
          "00000000-0000-0000-0000-000000000100",
        ],
      },
    },
  });

  const handleSetNewTag = (value: string) => setNewTag(value);

  const options: ITags[] = (() => {
    if (loading) {
      return [];
    } else {
      if (data.galleryImagesTagsByTags.tags) {
        return data.galleryImagesTagsByTags.tags!.map((t: string) => ({
          value: t,
          label: t,
        }));
      }
    }
  })();

  const selectStyle = {
    control: (base: any) => ({
      ...base,
      border: "1px solid #eee !important",
      boxShadow: 100,
      borderRadius: 8,
      height: 50,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: "black",
      padding: 8,
    }),
  };

  if (error)
    return (
      <ErrorAlert
        title={error.name}
        subTitle={"field to calling available tags"}
        msg={error.message}
      />
    );

  return (
    <Select
    menuPortalTarget={document.body}
    menuPosition={'fixed'} 
      options={options}
      value={props.selectedTags}
      onInputChange={handleSetNewTag}
      onChange={props.handleTagUpdate}
      inputValue={newTag}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Control,
      }}
      styles={selectStyle}
      isMulti
      placeholder={"Sök symbol"}
      isLoading={loading}
      loadingMessage={() => "läser in ..."}
      noOptionsMessage={() => null}
    />
  );
};
const Control: React.FC<any> = ({ children, ...rest }) => (
  <components.Control {...rest}>
    <IonIcon
      style={{ fontSize: "22px", marginLeft: "5px" }}
      color={"secondary"}
      icon={search}
    />{" "}
    {children}
  </components.Control>
);

export default SearchContainer;
