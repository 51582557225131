import React from "react";
import {
  InputChangeEventDetail,
  IonLabel,
  IonTextarea,
  TextareaChangeEventDetail,
} from "@ionic/react";
import { I_FormikValue } from "..";
import styled from "styled-components";
import RcInput from "../../../../../components/RcInput";
import moment from "moment";
import RcDatePicker from "../../../../../components/RcDatePicker";
import { FormikProps } from "formik";

const FuneralInputs: React.FC<{
  dateFormat: string;
  formik: FormikProps<I_FormikValue>;
  updateContent: (arg: any) => void;
  debounce: number;
  orderId: string | null | undefined;
}> = (props) => {
  const { formik, updateContent, dateFormat, debounce, orderId } = props;

  const handleTextInputChange = (
    e: CustomEvent<
      HTMLIonInputElement | InputChangeEventDetail | TextareaChangeEventDetail
    >,
    name: string
  ) => {
    const value = e.detail.value;
    formik.setFieldValue(name, value);
    updateContent({
      funeral: {
        ceremonies: [
          {
            id: orderId!,
            [name]: value,
          },
        ],
      },
    });
  };

  const handleDateInputChange = (
    e: CustomEvent<HTMLIonInputElement | InputChangeEventDetail>,
    name: string
  ) => {
    const value = e.detail.value;
    const dateUtc = moment.utc(value);
    const convertToLocal = dateUtc.local().set({ h: 12, m: 0 });
    if (name === "time") {
      formik.setFieldValue(name, value);
      updateContent({
        funeral: {
          ceremonies: [
            {
              id: orderId!,
              [name]: {
                value: moment(value!).toDate(),
              },
            },
          ],
        },
      });
    } else {
      formik.setFieldValue(name, value);
      updateContent({
        funeral: {
          ceremonies: [
            {
              id: orderId!,
              [name]: {
                value: convertToLocal,
              },
            },
          ],
        },
      });
    }
  };

  const lastBookingTimeValue = formik.values.lastBookingTime ? moment(formik.values.lastBookingTime).format(dateFormat) : undefined
  const timeValue = formik.values.time ?  moment(formik.values.time).format(dateFormat + "THH:mm") : undefined
  return (
    <FuneralInputsStyleWrapper>
      <div className="funeral-inputWrapper">
        <IonLabel className="funeralLabel">Begravning</IonLabel>
        <div className="funeral-inputs">
          <div className="dateInputWrapper">
            <RcDatePicker
              label="Tid för begravning"
              presentation={"date-time"}
              className="input"
              name={"time"}
              value={timeValue}
              onChange={(e) => {
                handleDateInputChange(e, "time");
              }}
              showDefaultButtons={true}
            />

            <RcDatePicker
              label="Minnesstund"
              className="input"
              name={"lastBookingTime"}
              value={lastBookingTimeValue}
              onChange={(e) => {
                handleDateInputChange(e, "lastBookingTime");
              }}
            />
          </div>

          <RcInput
            type={"text"}
            placeholder={"Kungsgatan 132, stockholm"}
            className="input"
            label={"Adress"}
            name={"locationText"}
            debounce={debounce}
            value={formik.values.locationText}
            onChange={(e) => handleTextInputChange(e, e.target.name)}
          />

          <div className="textareaInputWrapper">
            <IonLabel className="label">Begravningsinformation</IonLabel>
            <IonTextarea
              name="infoText"
              className="textareaInput"
              rows={5}
              debounce={debounce}
              value={formik.values.infoText}
              onIonChange={(e) => handleTextInputChange(e, "infoText")}
            />
          </div>
        </div>
      </div>
    </FuneralInputsStyleWrapper>
  );
};

const FuneralInputsStyleWrapper = styled.div`
  margin-bottom: 20px;
  padding: 10px 0;
  .funeral-inputWrapper {
    .funeralLabel {
      font-weight: bold;
    }
    .funeral-inputs {
      margin-top: 15px;
      .dateInputWrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        .input {
          width: 180px;
        }
      }
      .input {
        margin-top: 10px;
        width: auto;
      }
    }
  }
  @media (max-width: 298px) {
    .input {
        margin-top: 10px;
        width: 100% !important;
      }
  }
`;

export default FuneralInputs;
