import React from "react";
import { IonToast } from "@ionic/react";

const Toastify: React.FC<{
  msg: string;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "light"
    | "medium"
    | "dark";
  position?: "bottom" | "middle" | "top";
  isOpen: boolean;
  doClose: () => void;
  duration?: number;
  isExitBtn?: boolean;
}> = (props) => {
  return (
    <IonToast
      isOpen={props.isOpen}
      onDidDismiss={props.doClose}
      color={props.color ?? "secondary"}
      position={props.position ?? "bottom"}
      message={props.msg}
      duration={props.duration}
      buttons={
        !props.duration && props.isExitBtn
          ? [
              {
                text: "Stäng",
                role: "cancel",
              },
            ]
          : []
      }
    />
  );
};

export default Toastify;
