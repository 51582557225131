import { useApolloClient, useQuery } from "@apollo/client";
import { type } from "os";
import React from "react";
import { createContext } from "use-context-selector";
import { GET_CURRENT_USER_SESSION } from "../graphql/queries/getCurrentUserSession";
import {
  GetCurrentUserSession,
  GetCurrentUserSession_currentUserSession,
} from "../schema";

interface I_InitialState {
  isMenuDisabled: boolean
}

type T_type = "SIDE_MENU_HANDLE" 

interface contextValues {
  currentCustomer: GetCurrentUserSession_currentUserSession;
  dispatch: React.Dispatch<{type: T_type, payload:I_InitialState}>
}

interface IAction {
  type: T_type;
  payload?: I_InitialState;
}

export const AppContext = createContext<contextValues | null>(null);

const AppContextProvider: React.FC<{
  values: {
    isSideMenuDisabled: boolean
    setSideMenuDisabled: (arg:boolean) => void
  }
}> = (props) => {
  const client = useApolloClient();
  const initialState:I_InitialState = {
    isMenuDisabled: props.values.isSideMenuDisabled
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { data } = useQuery<GetCurrentUserSession>(GET_CURRENT_USER_SESSION, {
    client,
  });

  function reducer(state:I_InitialState, action:IAction) {
    switch(action.type) {
      case "SIDE_MENU_HANDLE":
        props.values.setSideMenuDisabled(!state.isMenuDisabled!)
        return {isMenuDisabled: action.payload?.isMenuDisabled!}
      default: 
        return state
    }
  }

  return (
    <AppContext.Provider
      value={{
        currentCustomer: data?.currentUserSession!,
        dispatch: dispatch
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
