import gql from "graphql-tag";

export const UNPUBLISH_ORDERS = gql`
  mutation UnPublishOrders($input: MutationUnPublishOrdersInput!) {
    unPublishOrders(input: $input) {
      isError
      errorReason
      orders {
        id
        mediaOrder {
          isLocked
          status {
            color
            status
          }
          publishConversation {
            isBookingSent
          }
        }
      }
    }
  }
`;
