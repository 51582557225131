import gql from "graphql-tag";
import USER_INTERFACE_FRAGMENT from "./userInterfaceFragment";
import EDIT_MODEL_ADVERT_FRAGMENT from "./editModelAdvertFragment";
import EDIT_MODEL_LIVE_STREAM_FRAGMENT from "./editModelLiveStreamFragment";
import EDIT_MODEL_MEMORY_ROOM_FRAGMENT from "./editModelMemoryRoomFragment";
import PREVIEW_IMAGE_FRAGMENT from "./previewImageFragment";

const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    customerFriendlyId
    document {
      id
      __typename
    }
    client {
      mainSystem
      isTaps4
      isTaps5
      route {
        taps4Url
        __typename
      }
      __typename
    }
    alerting {
      smallText
      text
      type
      __typename
    }
    mediaOrder {
      timeBookingStop
      isLocked
      publishTimes {
        time
        __typename
      }
      documentFormat {
        id
        documentFormatCollection {
          id
          name
          __typename
        }
        mediaAdaptedName: friendlyName(isShownWithMedia: true)
        documentType {
          id
          name
          __typename
        }
        __typename
      }
      publishConversation {
        messageFromMedia
        codeLate
        isCodeLate
        isMessageToMedia
        isMediaSystemOrderNumber
        isMediaCustomerSubriberNumber
        publishReadyStatus
        __typename
      }
      publishChannels {
        inFamiljesidan
        inMinnesrummet
        inAgency
        __typename
      }
      preview {
        ...previewImageFragment
        price(withVAT: true, priceView: WITH_SERVICE_ORDER) {
          currency
          endCustomer {
            pricePay
            __typename
          }
          __typename
        }
        __typename
      }
      edit {
        userInterface {
          ...userInterfaceFragment
          __typename
        }
        resources {
          styleCssSpecialCharacters
          __typename
        }
        editModelMemoryRoom {
          ...editModelMemoryRoomFragment
        }
        editModelLiveStream {
          ...editModelLiveStreamFragment
        }
        editModelAdvert {
          ...editModelAdvertFragment
        }
        editModelType
        history {
          countStepBack
          __typename
        }
        __typename
      }

      media {
        id
        friendlyName
        __typename
      }
      status {
        color
        status
        __typename
      }
      __typename
    }
    __typename
  }
  ${USER_INTERFACE_FRAGMENT}
  ${EDIT_MODEL_ADVERT_FRAGMENT}
  ${EDIT_MODEL_LIVE_STREAM_FRAGMENT}
  ${EDIT_MODEL_MEMORY_ROOM_FRAGMENT}
  ${PREVIEW_IMAGE_FRAGMENT}
`;

export default ORDER_FRAGMENT;
