import React from "react";
import { IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";
import { AttachmentsObj } from "..";

const Attachments: React.FC<{
  attachments: AttachmentsObj;
  onChange: (
    key: "isOrdersProof" | "isOrderGalleryImages" | "isOrderPreviewPng",
    checked: boolean
  ) => void;
}> = (props) => {
  return (
    <div>
      <IonLabel className="label">Bilagor</IonLabel>
      <IonList>
        <IonItem className="item" lines="none">
          <IonLabel className="checkboxLabel">Korrektur</IonLabel>
          <IonCheckbox
            className="checkbox"
            slot="start"
            color="success"
            checked={props.attachments.isOrdersProof}
            onIonChange={(e) =>
              props.onChange("isOrdersProof", e.detail.checked)
            }
          />
        </IonItem>

        <IonItem className="item" lines="none">
          <IonLabel className="checkboxLabel">Symbol</IonLabel>
          <IonCheckbox
            className="checkbox"
            slot="start"
            color="success"
            checked={props.attachments.isOrderPreviewPng}
            onIonChange={(e) =>
              props.onChange("isOrderPreviewPng", e.detail.checked)
            }
          />
        </IonItem>

        <IonItem className="item" lines="none">
          <IonLabel className="checkboxLabel">Annonsbild</IonLabel>
          <IonCheckbox
            className="checkbox"
            slot="start"
            color="success"
            checked={props.attachments.isOrderGalleryImages}
            onIonChange={(e) =>
              props.onChange("isOrderGalleryImages", e.detail.checked)
            }
          />
        </IonItem>
      </IonList>
    </div>
  );
};

export default Attachments;
