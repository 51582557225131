import React from "react";
import { IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";
const Products: React.FC<{
  products: any[];
  onChange: (id: string, checked: boolean) => void
}> = (props) => {
  return (
    <div>
      <IonLabel className="label">Annonser och produkter</IonLabel>
      <IonList>
        {props.products &&
          Object.keys(props.products).map((id: any) => (
            <IonItem className="item" lines="none" key={id}>
              <IonLabel className="checkboxLabel">{props.products[id].label}</IonLabel>
              <IonCheckbox
              className="checkbox"
                slot="start"
                color="success"
                checked={props.products[id].checked}
                onIonChange={(e) => props.onChange(id, e.detail.checked)}
              />
            </IonItem>
          ))}
      </IonList>
    </div>
  );
};

export default Products;
