import React from "react";
import {
  chevronBackOutline,
  chevronDownOutline,
  chevronForwardOutline,
  chevronUpOutline,
} from "ionicons/icons";
import { useSwiper } from "swiper/react";
import Button from "../../../../../../../../components/Button";
import styled from "styled-components";
import {
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns,
} from "../../../../../../../../schema";
import cx from "classnames";
import { IonText } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const SliderNavigation: React.FC<{
  totalRowLength: number;
  rowIndex: number;
  setRowIndex: (arg: number) => void;
  activeRow: boolean;
  columns: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns[];
  row: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows;
}> = ({ totalRowLength, rowIndex, setRowIndex, columns, activeRow, row }) => {
  const carrousel = useSwiper();
  const [colIndex, setColIndex] = React.useState<number>(0);

  const nextRow = () => {
    if (totalRowLength && totalRowLength > rowIndex) setRowIndex(rowIndex + 1);
  };
  const prevRow = () => {
    if (totalRowLength && totalRowLength <= rowIndex + 1)
      setRowIndex(rowIndex - 1);
  };

  const nextCol = () => {
    carrousel.slideNext();
    setColIndex(carrousel.activeIndex);
  };

  const prevCol = () => {
    carrousel.slidePrev();
    setColIndex(carrousel.activeIndex);
  };

  function renderColumns() {
    let columnsJsx = [];
    let i: number = 0;
    for (i; i < columns.length; i++) {
      columnsJsx.push(
        <FontAwesomeIcon
          key={i}
          className={cx("icon", {
            activeCol: i === colIndex,
          })}
          icon={faSquare}
        />
      );
    }
    return columnsJsx;
  }

  let itemPosition: string = "";
  if (columns.length === 1 || columns.length === 3) itemPosition = "center";
  if (columns.length === 2) itemPosition = "space-evenly";

  return (
    <SliderNavigationStyleWrapper>
      <div className="sliderNavigation">
        <div className="rowArrows">
          <Button
            icon={chevronBackOutline}
            iconDir={"icon-only"}
            size={"small"}
            variants={"secondary_noLine"}
            className={`prevCol ${columns.length === 1 ? "hide" : ""}`}
            onClick={prevCol}
          />
          <div className="columsArrows">
            <Button
              icon={chevronUpOutline}
              iconDir={"icon-only"}
              size={"small"}
              variants={"secondary_noLine"}
              className="prevRow"
              onClick={prevRow}
              isDisabled={rowIndex === 0}
            />
            <div className="indicator">
              <IonText className="rowCounter">rad {rowIndex + 1}</IonText>
              <div
                className="indicator-row"
                style={{ justifyContent: itemPosition }}
              >
                {renderColumns()}
              </div>
            </div>
            <Button
              icon={chevronDownOutline}
              iconDir={"icon-only"}
              size={"small"}
              variants={"secondary_noLine"}
              className="nextRow"
              onClick={nextRow}
              isDisabled={rowIndex === totalRowLength}
            />
          </div>
          <Button
            icon={chevronForwardOutline}
            iconDir={"icon-only"}
            size={"small"}
            variants={"secondary_noLine"}
            className={`nextCol ${columns.length === 1 ? "hide" : ""}`}
            onClick={nextCol}
          />
        </div>
      </div>
    </SliderNavigationStyleWrapper>
  );
};

const SliderNavigationStyleWrapper = styled.div`
  .sliderNavigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .rowArrows {
      display: flex;
      align-items: center;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 5px;
      .columsArrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        .indicator {
          border: 1px solid var(--ion-color-secondary);
          width: auto;
          height: 35px;
          border-radius: 3px;
          width: 50px;
          height: 45px;
          .rowCounter {
            color: gray;
            font-size: 13px;
          }
          .indicator-row {
            display: flex;
            column-gap: 3px;
            .icon {
              &.activeCol {
                color: var(--ion-color-secondary);
              }
            }
          }
        }
      }
    }
    .hide {
      visibility: hidden;
      /* display: none; */
    }
  }
`;

export default SliderNavigation;
