import { chatbox } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import DatePickerInput from "../DatepickerInput";
import cx from "classnames";
import { IonNote } from "@ionic/react";

const RcDatePicker: React.FC<{
  label?: string;
  className?: string;
  name?: string;
  value: string | undefined | null;
  onChange: (e: any) => void;
  placeholder?: string;
  presentation?:
    | "date"
    | "date-time"
    | "month"
    | "month-year"
    | "time"
    | "time-date"
    | "year";
  showDefaultButtons?: boolean;
  errorMsg?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isHorizontally?: boolean;
  isDateEnable?: (date:any) => boolean
}> = (props) => {
  return (
    <RcDatePickerStyleWrapper>
      <div
        className={cx(`datePickerWrapper ${props.className}`, {
          horizontally: props.isHorizontally,
        })}
      >
        <div className="labelWrapper">
          <label className="label">{props.label}</label>
        </div>

        <div className="inputWrapper">
          <DatePickerInput
            className={"input"}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            presentation={props.presentation}
            showDefaultButtons={props.showDefaultButtons}
            disabled={props.isDisabled}
            isLoading={props.isLoading}
            isDateEnable={props.isDateEnable}
          />
          {props.errorMsg ? (
            <IonNote className="error">{props.errorMsg}</IonNote>
          ) : (
            <IonNote></IonNote>
          )}
        </div>
      </div>
    </RcDatePickerStyleWrapper>
  );
};

const RcDatePickerStyleWrapper = styled.div`
  .datePickerWrapper {
    .label {
      font-size: 14px;
    }
    .error {
      color: var(--ion-color-danger);
      font-size: 12px;
    }

    .inputWrapper {
      .input {
        --padding-start: 10px;
      }
    }

    &.horizontally {
      display: grid;
      grid-template-columns: 0.6fr 1.2fr;
      grid-template-rows: 1fr;
      column-gap: 10px;
      .labelWrapper {
        grid-column: 1/2;
        padding-top: 10px;
      }
      .inputWrapper {
        grid-column: 2/3;
        .input {
          --padding-start: 10px;
        }
      }
    }
  }
`;

export default RcDatePicker;
