import { useApolloClient, useMutation } from "@apollo/client";
import { IonAlert } from "@ionic/react";
import React from "react";
import Button from "../../../../../components/Button";
import ErrorAlert from "../../../../../components/ErrorAlert";
import SET_EXHIBIT_ORDER from "../../../../../graphql/mutation/setExhibitOrder";
import { SetExhibitOrder, SetExhibitOrderVariables } from "../../../../../schema";

const ExhibitOrderButton: React.FC<{
  orderId: string | null | undefined;
  exhibitText: string | null | undefined;
  caseFolderId: string 
  dismissPopover: () => void
}> = (props) => {
    const [alert, setAlert] = React.useState<boolean>(false);
    const client = useApolloClient()

    const [mutationSetExhibitOrder, {loading, error}] = useMutation<
    SetExhibitOrder,
    SetExhibitOrderVariables
  >(SET_EXHIBIT_ORDER, { client })


  const handleExhibitOrder = () => {
    props.dismissPopover()
    mutationSetExhibitOrder({
        variables: {
          caseFolderId: props.caseFolderId!,
          orderId: props.orderId!,
        },
      })
  }

    const confirmTitle = "visingsannons";
    const confirmText = "vill du göra annons till visingsannons?";
  return (
    <div>
      <Button
        // icon={bookmarkOutline}
        // iconDir={"start"}
        variants={"secondary_noLine"}
        onClick={() => setAlert(true)}
        isLoading={loading}
        className="button"
      >
        {props.exhibitText}
      </Button>
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        header={confirmTitle}
        message={confirmText}
        buttons={[
          {
            text: "ja",
            role: "confirm",
            handler: () => {
                handleExhibitOrder();
            },
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default ExhibitOrderButton;
