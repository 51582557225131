import { IonSkeletonText } from "@ionic/react";
import React from "react";
import { createFakeArray } from "../../../../utilities";

const SkeletonLoadingBlock: React.FC = () => (
  <>
    {createFakeArray(10).map((a, b) => (
      <IonSkeletonText
        key={a + b}
        animated
        style={{
          width: "auto",
          height: "65px",
          margin: "15px 15px",
          borderRadius: "6px",
        }}
      />
    ))}
  </>
);

export default SkeletonLoadingBlock;
