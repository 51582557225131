import { Preferences } from "@capacitor/preferences";

export const omitProps = (object: object, propNames: string[]) =>
  JSON.parse(JSON.stringify(object), (key: string, value: any) =>
    propNames.indexOf(key) !== -1 ? undefined : value
  );

export const omitTypename = (object: object) =>
  omitProps(object, ["__typename"]);

export const createFakeArray = (length: number) => {
  let array = [];
  for (let i = 0; i < length; i++) {
    array.push(i + 1);
  }
  return array;
};

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;

export const standardMobileScreenSize = 414;

export function blobToBase64(blob: any) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const setLocalStorageItem = async (name: string, token: any) => {
  await Preferences.set({
    key: name,
    value: JSON.stringify(token),
  });
};

export const getLocalStorageItem = async (key: string) => {
  const { value } = await Preferences.get({ key: key });
  return JSON.parse(value!);
};

export const removeLocalStorageItem = async (key: string) => {
  await Preferences.remove({ key: key });
};

export const statusText = {
  CREATE: "Skapa",
  SEND_WAIT_APPROVAL: "Sändning",
  EDIT: "Skapar",
  EDIT_CHANGE: "Ändring",
  WAIT_APPROVAL: "Väntar på godkännande",
  FAILED: "Misslyckad",
  PRODUCTION: "Produktion",
  PUBLISHED: "Publicerad",
  DELETED: "Raderad",
  CANCELED: "Avbokad",
  UNSPECIFIED: "Ospecifierad",
  NONE: "Alla statusar",
};

export const customerScopeText = {
  Default: "standard",
  Customer: "Mina Akter",
  Office: "Byråns Akter",
  Company: "Företagets Akter",
};

interface ISize {
  h: number,
  w: number
}

interface IMobileSizes {
  small: ISize,
  medium: ISize,
  large: ISize
}


export const averageMobileSizes:IMobileSizes = {
  small: {
    h: 667,
    w: 375
  },
  medium: {
    h: 780,
    w: 390,
  },
  large: {
    h: 926,
    w: 428
  }

}