import gql from "graphql-tag"
import PROPERTIES_FUNERAL_STANDARD_FRAGMENT from "./propertiesFuneralStandardFragment"


const USER_INTERFACE_FRAGMENT = gql`
fragment userInterfaceFragment on EditUI {
	editStatusColor
	editStatusText
	publishButtonText
	editStatusIcon
	isUnPublishButton
	unPublishButtonText
	isPublishButton
	isLockButton
	lockButtonText
	isUnlockButton
	unlockButtonText
	isReactivateButton
	reactivateButtonText
	isSetExhibitOrder
	setExhibitOrderText
	isUpdateCustomerDocumentContentInitiator
	updateCustomerDocumentContentInitiatorText
	orderEntityNameSingular: orderEntityName
	orderEntityNamePlural: orderEntityName(plural: true)
	orderEntityNameSingularDetermined: orderEntityName(
		plural: true
		determined: true
	)
	orderEntityNamePluralDetermined: orderEntityName(
		plural: true
		determined: true
	)
	isCopyFuneralInfoToMemoryRoom
	copyFuneralInfoToMemoryRoomText
	__typename
}
`

export default USER_INTERFACE_FRAGMENT