import React from "react";
import styled from "styled-components";
import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import moment from "moment";

const OrderInfo: React.FC<{
  publishTime: string;
  orderHeight: number | undefined;
  orderPrise: string | undefined;
  currency: string | undefined;
}> = (props) => {
  const { publishTime, orderHeight, orderPrise, currency } = props;
  return (
    <OrderInfoStyleWrapper>
      <div className="orderInfoWrapper">
        <IonList className="orderInfoList" lines={"none"}>
          <IonItem className="orderInfoItem">
            <div className="orderInfoItemInner">
              <IonLabel className="label">Införande</IonLabel>
              <IonText>{moment(publishTime).format("yyyy-MM-DD")}</IonText>
            </div>
          </IonItem>
          <IonItem className="orderInfoItem">
            <div className="orderInfoItemInner">
              <IonLabel className="label">Höjd</IonLabel>
              <IonText>{orderHeight ? orderHeight?.toFixed(2) + " MM": "N/A"}</IonText>
            </div>
          </IonItem>
          <IonItem className="orderInfoItem">
            <div className="orderInfoItemInner">
              <IonLabel className="label">Pris</IonLabel>
              <IonText>
                {orderPrise ? orderPrise + " " + currency : "N/A"}
              </IonText>
            </div>
          </IonItem>
        </IonList>
      </div>
    </OrderInfoStyleWrapper>
  );
};

const OrderInfoStyleWrapper = styled.div`
  .orderInfoWrapper {
    width: 100%;
    margin-bottom: 20px;
    .orderInfoList {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      .orderInfoItem {
        .orderInfoItemInner {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 15px;
          .label {
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`;

export default OrderInfo;
