
import React from "react";
import { trashOutline } from "ionicons/icons";
import Button from "../../../../../components/Button";
import { IonAlert, useIonRouter } from "@ionic/react";
import { DeleteOrder, DeleteOrderVariables } from "../../../../../schema";
import { useMutation } from "@apollo/client";
import { DELETE_ORDER } from "../../../../../graphql/mutation/deleteOrder";
import ErrorAlert from "../../../../../components/ErrorAlert";

const Remove:React.FC<{
    orderId: string
    isLocked: boolean
    dismissPopover: () => void
}> = (props) => {
    const [alert, setAlert] = React.useState<boolean>(false);
    const router = useIonRouter()

    const [mutateDeleteOrder, { loading, error }] = useMutation<
    DeleteOrder,
    DeleteOrderVariables
  >(DELETE_ORDER);

  const handleOrderRemove = () => {
    props.dismissPopover()
    if (!props.isLocked)
      mutateDeleteOrder({
        variables: {
          input: {
            orderIds: [props.orderId],
          },
        },
      }).then((result) => {
        if(result.data) {
        
            if(router.canGoBack()) {
                router.goBack()
            }else {
                router.push("/dashbord", "back", "pop")
            }
        }
      })
      
  };

    const confirmText = "Du är på väg att ta bort ett media. Tryck på 'Ta bort media' om du är säker.";
  return (
    <div>
      <Button
        icon={trashOutline}
        iconDir={"start"}
        variants={"secondary_noLine"}
        onClick={() => setAlert(true)}
        className="button"
        isLoading={loading}
      >
        {"Radera"}
      </Button>

      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        message={confirmText}
        buttons={[
          {
            text: "Ta bort",
            role: "confirm",
            handler: () => {
                handleOrderRemove()
            },
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default Remove;
