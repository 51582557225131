import React from "react";
import {
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import * as Yup from "yup";
import Modal from "../Modal";
import { useContextSelector } from "use-context-selector";
import { OrderContext } from "../../pages/OrdersPage/OrderContainer";
import Products from "./Products";
import Attachments from "./Attachments";
import produce from "immer";
import ProofSettings from "./ProofSettings";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { MEDIATE_MATERIAL } from "../../graphql/mutation/mediateMaterial";
import { MediateMaterial, MediateMaterialVariables } from "../../schema";
import Button from "../Button";
import Mediate from "./Mediate";
import { useFormik } from "formik";
import ErrorAlert from "../ErrorAlert";

import Toastify from "../Toastify";
import { AppContext } from "../../contexts/AppContextProvider";

const ProofModal: React.FC<{
  isOpen: boolean;
  setIsIOpen: (arg: boolean) => void;
  doClose: () => void;
}> = (props) => {
  const currentCustomer = useContextSelector(
    AppContext,
    (v) => v?.currentCustomer
  );

  const { productOrders } = useContextSelector(OrderContext, (s) => {
    return {
      productOrders:
        s!.orders?.filter((x) => x.document?.id === s?.order?.document?.id) ??
        [],
    };
  });

  const [toastify, setToastify] = React.useState<boolean>(false);

  const [state, setState] = React.useState<IState>({
    products: {},
    attachments: {
      isOrdersProof: true,
      isOrderGalleryImages: false,
      isOrderPreviewPng: false,
    },
    proofSettings: {
      isSuggestionMediaOrders: false,
      isVoucherKey: true,
    },
    isSendEmailReplyCopy: false,
  });

  const [mutateMediateMaterial, { loading, error, data }] = useMutation<
    MediateMaterial,
    MediateMaterialVariables
  >(MEDIATE_MATERIAL);

  React.useEffect(() => {
    setState(
      produce(state, (draft) => {
        draft.products = productOrders.reduce(
          (productsAcc, order) => ({
            ...productsAcc,
            [order.id!]: {
              label: order.mediaOrder!.media.friendlyName,
              checked: true,
            },
          }),
          {}
        );
      })
    );
  }, []);

  const initialValues: IValues = {
    sendEmail: "",
    mediateEmail: currentCustomer?.customer?.inheritSettings.emailOffice!,
    msg: "",
  };

  const validationSchema = Yup.object().shape({
    sendEmail: Yup.string()
      .email("Fel e-post format")
      .required("måste ange e-post"),
    msg: Yup.string().min(3, "Meddelande är för kort").required("obligatoriskt fält"),
  });

  const handleAttachmentsChange = (
    key: "isOrdersProof" | "isOrderGalleryImages" | "isOrderPreviewPng",
    checked: boolean
  ) => {
    setState(
      produce(state, (draft) => {
        draft.attachments[key] = checked;
      })
    );
  };

  const handleProductsChange = (id: string, checked: boolean) => {
    setState(
      produce(state, (draft: any) => {
        draft.products[id] = {
          label: draft.products[id].label,
          checked: checked,
        };
      })
    );
  };

  const handleProofChange = (
    key: "isSuggestionMediaOrders" | "isVoucherKey",
    checked: boolean
  ) => {
    setState(
      produce(state, (draft) => {
        draft.proofSettings[key] = checked;
      })
    );
  };

  const handleSendEmailReplyCopyChange = (checked: boolean) => {
    setState(
      produce(state, (draft) => {
        draft.isSendEmailReplyCopy = checked;
      })
    );
  };

  const onSubmit = (values: IValues) => {
    const ids: string[] = [];
    const selectedProducts: Array<string> = Object.keys(state.products).map(
      (id) => (state.products[id].checked ? id : "")
    );
    ids.push(...selectedProducts.filter((p) => p !== ""));
    if (formik.isValid) {
      mutateMediateMaterial({
        variables: {
          input: {
            isSendEmailReplyCopy: state.isSendEmailReplyCopy,
            emailToAddress: values.sendEmail,
            emailReplyAddress: values.mediateEmail,
            message: values.msg,
            isOrdersProof: state.attachments.isOrdersProof,
            isOrderGalleryImages: state.attachments.isOrderGalleryImages,
            isOrderPreviewPng: state.attachments.isOrderPreviewPng,
            orderIds: ids,
            proofSetting: {
              isSuggestionMediaOrders:
                state.proofSettings.isSuggestionMediaOrders,
              isVoucherKey: state.proofSettings.isVoucherKey,
            },
          },
        },
      }).then((result) => {
        const data = result.data?.messagingOrdersMaterials
        if(data && !data.isError) {
          setToastify(true)
        }
      });
    }
  };


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: true,
  });
  
  return (
    <Modal
      isVisible={props.isOpen}
      setIsVisible={props.setIsIOpen}
      doClose={props.doClose}
      isAnimated={true}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      mode={"ios"}
    >
      <IonToolbar></IonToolbar>
      <IonContent>
        <ProofModalStyleWrapper>
          <IonGrid>
            <IonRow>
              <IonCol sizeSm="6" sizeXs="12">
                <Attachments
                  attachments={state.attachments}
                  onChange={handleAttachmentsChange}
                />
              </IonCol>
              <IonCol sizeSm="6" sizeXs="12">
                <Products
                  products={state.products}
                  onChange={handleProductsChange}
                />
              </IonCol>
              <IonCol size="12">
                <ProofSettings
                  proofSettings={state.proofSettings}
                  onChange={handleProofChange}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className={"custom-line"}></div>
          <Mediate formik={formik} />

          <div className="buttonContainer">
            <IonItem lines="none">
              <IonLabel className="checkboxLabel">
                Kopia till svarsadress
              </IonLabel>
              <IonCheckbox
                className="checkbox"
                slot="start"
                color="success"
                checked={state.isSendEmailReplyCopy}
                onIonChange={(e) =>
                  handleSendEmailReplyCopyChange(e.detail.checked)
                }
              />
            </IonItem>

            <Button
              type="submit"
              onClick={() => onSubmit(formik.values)}
              isLoading={loading}
            >
              Skicka
            </Button>
          </div>
        </ProofModalStyleWrapper>
      </IonContent>
      {data?.messagingOrdersMaterials.material && (
        <Toastify
          isOpen={toastify}
          doClose={() => setToastify(false)}
          color={"success"}
          position={"bottom"}
          msg={"Email är skickad"}
          duration={2000}
        />
      )}

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </Modal>
  );
};

interface IState {
  products: any;
  attachments: AttachmentsObj;
  proofSettings: IProofSetting;
  isSendEmailReplyCopy: boolean;
}

export interface IProduct {
  label: string;
  checked: boolean;
}

export interface AttachmentsObj {
  isOrdersProof: boolean;
  isOrderGalleryImages: boolean;
  isOrderPreviewPng: boolean;
}

export interface IProofSetting {
  isSuggestionMediaOrders: boolean;
  isVoucherKey: boolean;
}

export interface IValues {
  sendEmail: string;
  mediateEmail: string | null;
  msg: string;
}

const ProofModalStyleWrapper = styled.div`
  .label {
    font-weight: bold;
  }
  .checkbox {
    --size: 20px;
  }
  .checkboxLabel {
    font-size: 15px;
  }
  .item {
    --min-height: 30px;
  }
  .input {
    margin-top: 10px;
    .rcLabel {
      font-weight: bold;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
  }
`;

export default ProofModal;
