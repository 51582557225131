import React from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { IonCol, IonGrid, IonImg, IonRow, useIonRouter } from "@ionic/react";
import LOGIN from "../../graphql/mutation/login";
import { Login, LoginVariables } from "../../schema";
import RcInput from "../../components/RcInput";
import Button from "../../components/Button";
import styled from "styled-components";
import Logo from "./timecutLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PATH_LINK } from "../../App";
import { setLocalStorageItem } from "../../utilities";
import ErrorAlert from "../../components/ErrorAlert";

interface IValues {
  username: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const client = useApolloClient();
  const route = useIonRouter();
  const [mutateLogin, { loading, error }] = useMutation<Login, LoginVariables>(
    LOGIN,
    { client }
  );

  const initialValues: IValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Obligatoriskt fält")
      .min(2, "min 2 bokstaver")
      .max(500),
    password: Yup.string()
      .required("Obligatoriskt fält")
      .min(2, "min 2 bokstaver")
      .max(500),
  });

  const onSubmit = (values: IValues) => {
    if (formik.isValid) {
      mutateLogin({
        variables: {
          userCredentialInput: {
            username: values.username,
            password: values.password,
          },
        },
      }).then((result) => {
        if (
          result.data &&
          result.data.loginByCredential &&
          result.data.loginByCredential.authorizationToken
        ) {
          route.push(PATH_LINK.DASHBOARD, "root", "replace");
          setLocalStorageItem(
            "token",
            result.data.loginByCredential.authorizationToken
          );
          window.location.reload();
        }
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: true,
  });

  return (
    <LoginPageStyleWrapper>
      <div className="bg-transparent">
        <form onSubmit={formik.handleSubmit}>
          <IonGrid className="container">
            <IonRow>
              <IonCol className="logoWrapper" size="12">
                <IonImg className="logo" src={Logo} />
              </IonCol>
              <IonCol size="12">
                <RcInput
                  label="Användarnamn"
                  type={"text"}
                  placeholder="Användarnamn"
                  name={"username"}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.username}
                />
              </IonCol>
              <IonCol size="12">
                <RcInput
                  label="lösenord"
                  type={"password"}
                  placeholder="lösenord"
                  name={"password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  errorMsg={formik.errors.password}
                />
              </IonCol>
              <IonCol size="12">
                <Button type={"submit"} isLoading={loading}>
                  Logga in
                </Button>
              </IonCol>
              {error && (
                <ErrorAlert
                  title={"inloggning misslyckades"}
                  subTitle={""}
                  msg={"användanamn eller lösenord stämmer inte"}
                />
              )}
            </IonRow>
          </IonGrid>
        </form>
      </div>
    </LoginPageStyleWrapper>
  );
};

const LoginPageStyleWrapper = styled.div`
  .container {
    background-color: var(--ion-color-lock);
    -webkit-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
    -moz-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
    box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
    .logoWrapper {
      display: flex;
      justify-content: center;
      .logo {
        width: 250px;
        height: auto;
      }
    }
  }
`;

export default React.memo(LoginPage);
