import React from "react";
import styled from "styled-components";
import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonProgressBar,
  useIonRouter,
} from "@ionic/react";
import { GetCaseFolders, GetCaseFoldersVariables } from "../../../schema";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import GET_DASHBOARD_CASE_FOLDERS from "../../../graphql/queries/getDashboardCaseFolder";
import ErrorAlert from "../../../components/ErrorAlert";
import Button from "../../../components/Button";
import { createOutline, folderOpenOutline } from "ionicons/icons";
import CaseFolderFormModal from "./CaseFolderFormModal";
import Status from "../../../components/Status";
import OrderRemoveButton from "./OrderRemoveButton";

const CaseFolderDetailsTable: React.FC = () => {
  const router = useIonRouter();
  const [isEditModal, setEditModal] = React.useState<boolean>(false);
  const params: { caseFolderId: string } = useParams();

  const { data, loading, error } = useQuery<
    GetCaseFolders,
    GetCaseFoldersVariables
  >(GET_DASHBOARD_CASE_FOLDERS, {
    context: {
      debounceKey: "caseFolderTableDetails",
      debounceTimeout: 500,
    },
  });

  const selectedCaseFolder =
    data &&
    params &&
    data.findCaseFoldersByCurrentCustomer.caseFolders!.find(
      (caseFolder) => caseFolder?.id === params.caseFolderId
    );

  const productPackages =
    selectedCaseFolder && selectedCaseFolder?.productPackages;

  const doOpenEditModal = () => setEditModal(true);
  const doCloseEditModal = () => setEditModal(false);

  const handleMoveEditPage = (
    caseFolderId: string | null,
    orderId: string | null
  ) => {
    router.push(`/editOrder/c/${caseFolderId}/o/${orderId}`, "forward", "push");
  };

  const handleMoveToPublishPage = (
    orderId: string,
    documentId: string,
    caseFolderId: string
  ) => {
    router.push(
      `/publishOrder/c/${caseFolderId}/d/${documentId}/o/${orderId}`,
      "forward",
      "push"
    );
  };
  return (
    <IonContent className="bg-transparent">
      <CaseFolderDetailsTableStyleWrapper>
        <IonHeader className="header">
          <div className="toolbar">
            <div className="profile">
              <IonIcon
                className="icon"
                color={"secondary"}
                icon={folderOpenOutline}
              />
              <span className="name">{selectedCaseFolder?.name}</span>
            </div>
            <div>
              <Button
                size={"small"}
                variants={"secondary_noLine"}
                icon={createOutline}
                // onClick={doOpenEditModal}
                iconDir={"icon-only"}
                id={"openCaseFolderForm"}
              />
            </div>
          </div>

          {loading ? (
            <IonProgressBar type="indeterminate"></IonProgressBar>
          ) : (
            <div className="fill" style={{ height: "3px" }}></div>
          )}
        </IonHeader>

        <div className="caseFolderDetailsFolderContainer">
          {productPackages?.map((pk, index) => {
            return (
              <IonAccordionGroup key={index} className={"accordionGroup"}>
                {pk?.documents.map((doc, index) => {
                  return (
                    <div key={index} className={"documentContainer"}>
                      <div className="documentTitleWrapper">
                        <IonLabel className={"documentTitle"}>
                          {doc?.documentFormat.documentType.name}
                        </IonLabel>
                      </div>

                      {doc?.orders.map((order, index) => {
                        const documentType =
                          doc.documentFormat.documentType.name.toLowerCase();
                        return (
                          <IonAccordion
                            key={index}
                            className={"accordion"}
                            disabled={
                              documentType.toLowerCase() === "tackannons" ||
                              documentType.toLowerCase() === "programkort" ||
                              documentType.toLowerCase() === "tackkort"
                            }
                          >
                            <IonItem
                              slot="header"
                              lines="none"
                              className="accordionItem"
                            >
                              <IonLabel className="label">
                                {order.mediaOrder?.media.friendlyName}
                              </IonLabel>
                              <div>
                                <Status
                                  color={order.mediaOrder?.status.color!}
                                />
                              </div>
                            </IonItem>
                            <div
                              className="accordionContent ion-padding "
                              slot="content"
                            >
                              <div className="leftCol">
                                <Button
                                  onClick={() =>
                                    handleMoveEditPage(
                                      params.caseFolderId,
                                      order.id
                                    )
                                  }
                                  className="button"
                                  variants={"secondary_outline"}
                                >
                                  Redigera
                                </Button>
                                {order.mediaOrder?.status.color !== "GREEN" && (
                                  <Button
                                    className="button"
                                    variants={"secondary_outline"}
                                    onClick={() =>
                                      handleMoveToPublishPage(
                                        order.id!,
                                        order.document.id!,
                                        params.caseFolderId
                                      )
                                    }
                                  >
                                    Boka
                                  </Button>
                                )}
                              </div>

                              <div className="rightCol">
                                <OrderRemoveButton
                                  orderId={order.id!}
                                  isLocked={order.mediaOrder?.isLocked!}
                                  className={"removeButton"}
                                />
                              </div>
                            </div>
                          </IonAccordion>
                        );
                      })}
                    </div>
                  );
                })}
              </IonAccordionGroup>
            );
          })}
        </div>
      </CaseFolderDetailsTableStyleWrapper>

      <CaseFolderFormModal
        isOpen={isEditModal}
        doClose={doCloseEditModal}
        doOpen={doOpenEditModal}
        funeralProperties={selectedCaseFolder?.propertiesFuneralStandard}
        documentId={selectedCaseFolder?.id}
        customer={selectedCaseFolder?.customer}
      />

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </IonContent>
  );
};

const CaseFolderDetailsTableStyleWrapper = styled.div`
  .header {
    background-color: white;
    margin-bottom: 10px;
    -webkit-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
    -moz-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
    box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
    .toolbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px;
      .profile {
        display: flex;
        align-items: center;
        .icon {
          font-size: 25px;
        }
        .name {
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
  }

  .caseFolderDetailsFolderContainer {
    margin: 5px;
    .accordionGroup {
      .documentContainer {
        .documentTitleWrapper {
          margin: 15px 0;
          .documentTitle {
            font-weight: bold;
          }
        }
        .accordion {
          margin: 10px 0;
          border: 1px solid transparent;
          border-radius: 5px;
          -webkit-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
          -moz-box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
          box-shadow: -1px 0px 8px 0px rgba(179, 179, 179, 1);
          .ion-accordion-toggle-icon {
            color: var(--ion-color-secondary-tint);
          }
          &.accordion-expanded {
            border: 1px solid var(--ion-color-tertiary);
            .accordionItem {
              .label {
                color: var(--ion-color-tertiary);
                font-size: 15px;
                font-weight: bold;
              }
            }
          }

          .accordionItem {
            --border-color: transparent;
            .label {
              font-size: 15px;
            }
          }

          .accordionContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .leftCol {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              column-gap: 15px;
            }
            .rightCol {
              margin-right: 36px;
            }
          }
        }
      }
    }
  }
`;

export default CaseFolderDetailsTable;

{
  /* <IonGrid className="table">
          <IonRow className="tHeader">
            <IonCol className="title" size={columnsSize.col}>
              <IonLabel>Produkter</IonLabel>
            </IonCol>
            <IonCol className="title" size={columnsSize.col2}>
              <IonLabel>Status</IonLabel>
            </IonCol>
          </IonRow>
          {loading &&
            createFakeArray(10).map((a, b) => (
              <IonRow className="tRow" key={a + b}>
                <IonCol size={columnsSize.col}>
                  <IonSkeletonText
                    animated
                    style={{ width: "60%", height: "25px" }}
                  />
                </IonCol>
                <IonCol size={columnsSize.col2}>
                  <IonSkeletonText
                    animated
                    style={{ width: "60%", height: "25px" }}
                  />
                </IonCol>
              </IonRow>
            ))}
          {selectedCaseFolder &&
            selectedCaseFolder.productPackages.map((prodPack) =>
              prodPack?.documents.map((doc, index) => {
                return (
                  <div key={index}>
                    <IonRow className="innerTitleWrapper friendly_Name">
                      <IonCol size="12">
                        <IonLabel className="title">
                          {doc?.documentFormat.documentType.name}
                        </IonLabel>
                      </IonCol>
                    </IonRow>

                    {doc?.orders.map((order, index) => {
                      const expanded = order.id === selectedId;
                      const editModelType =
                        order?.mediaOrder?.edit?.editModelType!;

                      return (
                        <div
                          className={cx("innerRow", {
                            expanded: expanded,
                          })}
                          key={index}
                        >
                          <IonRow
                            onClick={() => handleRowExpanded(order.id)}
                            className={"innerCellWrapper"}
                          >
                            <IonCol
                              className={cx("cell", {
                                expanded: expanded,
                              })}
                              size={columnsSize.col}
                            >
                              <IonLabel className="title" position={"stacked"}>
                                {order.mediaOrder?.media.friendlyName}
                              </IonLabel>
                            </IonCol>
                            <IonCol className="cell" size={columnsSize.col2}>
                              <Status color={order.mediaOrder?.status.color!} />
                            </IonCol>
                          </IonRow>

                          <div
                            className={cx("expandedWrapper", {
                              expanded: expanded,
                            })}
                          >
                            <IonRow className="btnWrapper">
                              <IonCol
                                className="ButtonRouteWrapper"
                                size={columnsSize.col}
                              >
                                <Button
                                  size={"small"}
                                  onClick={() =>
                                    handleMoveEditPage(
                                      params.caseFolderId,
                                      order.id
                                    )
                                  }
                                  className="button"
                                  variants={"secondary_outline"}
                                >
                                  Redigera
                                </Button>
                                {editModelType === "ADVERT" && (
                                  <Button
                                    size={"small"}
                                    className="button"
                                    variants={"secondary_outline"}
                                    onClick={() =>
                                      handleMoveToPublishPage(
                                        order.id!,
                                        order.document.id!,
                                        params.caseFolderId
                                      )
                                    }
                                  >
                                    Boka
                                  </Button>
                                )}
                              </IonCol>

                              <IonCol className="RemoveButtonWrapper" size={columnsSize.col2}>
                                <OrderRemoveButton
                                  orderId={order.id!}
                                  isLocked={order.mediaOrder?.isLocked!}
                                  className={"removeButton"}
                                />
                              </IonCol>
                            </IonRow>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
        </IonGrid> */
}
