import { InputChangeEventDetail } from "@ionic/react";
import React from "react";
import styled from "styled-components";
import { PublishOrder, ReducerAction } from "../..";
import RcInput from "../../../../components/RcInput";
import { GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishConversation } from "../../../../schema";
import cx from "classnames";


const ConversationInputs: React.FC<{
  dispatch: React.Dispatch<ReducerAction>;
  publishConversation:
    | GetPublishOrders_caseFolder_documents_orders_mediaOrder_publishConversation
    | undefined;
  publishOrder: PublishOrder;
}> = (props) => {
  const { dispatch, publishOrder, publishConversation } = props;
  const {
    isCodeLate,
    isMediaCustomerSubriberNumber,
    isMediaSystemOrderNumber,
    isMessageToMedia,
  } = publishConversation!;

  const handleMessageInputChange = (e: CustomEvent<InputChangeEventDetail>) => {
    dispatch({
      type: "updateValue",
      payload: {
        id: props.publishOrder.orderId,
        name: "messageToMedia",
        newValue: e.detail.value,
      },
    });
  };

  const handleCodeLate = (e: CustomEvent<InputChangeEventDetail>) => {
    dispatch({
      type: "updateValue",
      payload: {
        id: props.publishOrder.orderId,
        name: "codeLate",
        newValue: e.detail.value,
      },
    });
  };

  const handleMediaSystemOrderNumberChange = (
    e: CustomEvent<InputChangeEventDetail>
  ) => {
    dispatch({
      type: "updateValue",
      payload: {
        id: props.publishOrder.orderId,
        name: "mediaSystemOrderNumber",
        newValue: e.detail.value,
      },
    });
  };

  const handleMediaCustomerSubscriberNumber = (
    e: CustomEvent<InputChangeEventDetail>
  ) => {
    dispatch({
      type: "updateValue",
      payload: {
        id: props.publishOrder.orderId,
        name: "mediaCustomerSubriberNumber",
        newValue: e.detail.value,
      },
    });
  };

  const isCodeLateActive =
    publishOrder.codeLate ===
    publishOrder.order.mediaOrder?.publishConversation.codeLate;

  return (
    <ConversationInputsStyleWrapper>
      {isMessageToMedia && (
        <RcInput
          onChange={(e) => handleMessageInputChange(e)}
          value={publishOrder.messageToMedia}
          placeholder={"Meddelande"}
          debounce={1500}
          className={"convInput"}
        />
      )}

      <div className="twoColumnInput">
        {isCodeLate && (
          <RcInput
            onChange={(e) => handleCodeLate(e)}
            value={publishOrder.codeLate}
            placeholder={"Kod"}
            debounce={1500}
            disabled={isCodeLateActive}
            isSuccess={isCodeLateActive}
            className={cx("convInput")}
          />
        )}

        {isMediaSystemOrderNumber && (
          <RcInput
            onChange={(e) => handleMediaSystemOrderNumberChange(e)}
            value={publishOrder.mediaSystemOrderNumber}
            placeholder={"Ordernummer"}
            debounce={1500}
            className={"convInput"}
          />
        )}

        {isMediaCustomerSubriberNumber && (
          <RcInput
            onChange={(e) => handleMediaCustomerSubscriberNumber(e)}
            value={publishOrder.mediaCustomerSubriberNumber}
            placeholder={"Prenumarationsnummer"}
            debounce={1500}
            className={"convInput"}
          />
        )}
      </div>
    </ConversationInputsStyleWrapper>
  );
};

const ConversationInputsStyleWrapper = styled.div`
  .twoColumnInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .convInput {
      margin: 5px 0;
      input {
        width: 145px;
        height: 40px;
      }
    }
  }
`;

export default ConversationInputs;
