import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline, warningOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import { PublishOrder, ReducerAction } from "../..";

const ValidateIcon: React.FC<{
  publishOrder: PublishOrder;
  dispatch: React.Dispatch<ReducerAction>;
}> = (props) => {
  const mediaOrder = props.publishOrder.order.mediaOrder;
  const pc = props.publishOrder.order.mediaOrder?.publishConversation!;

  

  React.useEffect(() => {
    validate();
    return () => validate();
  }, [props.publishOrder]);

  const validate = React.useCallback(() => {
    let validateDescription = "";

    if (!pc.isBookingSent) {
      if (!mediaOrder?.publishTimes[0])
        validateDescription = "Införandedatum saknas";

      if (pc.isCodeLate && props.publishOrder.codeLate.length === 0)
        validateDescription =
          "Du måste ange koden för att kunna publicera sent";
      else if (pc.isCodeLate && props.publishOrder.codeLate !== pc.codeLate) {
        validateDescription =
          "Du måste ange koden för att kunna publicera sent";
      }

      if (
        pc.isMediaCustomerSubriberNumber &&
        props.publishOrder.mediaCustomerSubriberNumber.length === 0
      )
        validateDescription =
          "Du måste ange prenumarationsnummer för att kunna publicera sent";

      if (
        pc.isMediaSystemOrderNumber &&
        props.publishOrder.mediaSystemOrderNumber.length === 0
      )
        validateDescription =
          "Du måste ange ordernummer för att kunna publicera sent";

      props.dispatch({
        type: "checkError",
        payload: {
          id: props.publishOrder.orderId,
          errors: validateDescription,
        },
      });
    }
  }, [props.publishOrder]);

  
  return (
    <ValidationStyleWrapper>
      {!pc.isBookingSent &&  props.publishOrder.errors.length > 0  ? (
        <IonIcon className="icon" icon={warningOutline} color={"warning"} />
      ) : (
        <IonIcon
          className="icon"
          icon={checkmarkCircleOutline}
          color={"success"}
        />
      )}
    </ValidationStyleWrapper>
  );
};

const ValidationStyleWrapper = styled.div`
  .icon {
    font-size: 20px;
    font-weight: 600;
    margin-top: 3px;
    margin-right: 5px;
  }
`;

export default ValidateIcon;
