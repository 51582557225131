import React from "react";
import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import { GetGalleryImages_galleryImagesByTags_images } from "../../../../../../../../../schema";
import { useContextSelector } from "use-context-selector";
import { AreasContext } from "../../..";

const Symbols: React.FC<{
  symbols: GetGalleryImages_galleryImagesByTags_images[] | undefined;
  selectSymbol: (
    imageId: string,
    publicId: number,
    thumbnailImage: string
  ) => void;
  doCloseSymbolModal: () => void;
}> = (props) => {
  const areaContextProps = useContextSelector(AreasContext, (v) => v!);
  const handleSelectSymbol = (
    imgId: string,
    publicId: number,
    imgSrc: string
  ) => {
    if (areaContextProps.selectSymbol) {
      areaContextProps.selectSymbol(imgId, publicId, imgSrc);
      props.doCloseSymbolModal();
      areaContextProps.doCloseAreaModal();
    }
  };
  return (
    <IonGrid className="symbolsContainer" >
      <IonRow>
        {props.symbols &&
          props.symbols.map((img, index) => {
            return (
              <IonCol
                key={index}
                className="symbolWrapper"
                size="4"
                onClick={() =>
                  handleSelectSymbol(
                    img.id,
                    img.publicId,
                    img.thumbnail100x80Url
                  )
                }
              >
                <IonImg className="symbol" src={img.thumbnail100x80Url} />
                <span>{img.publicId}</span>
              </IonCol>
            );
          })}
      </IonRow>
    </IonGrid>
  );
};

export default Symbols;
