import React from "react";
import { IonLabel, IonRange } from "@ionic/react";
import produce from "immer";
import { ISlider } from "..";
import styled from "styled-components";
import {type Swiper as SwiperRef } from 'swiper'

const Slider: React.FC<{
  heightMM: number;
  updateImageHeight: (height: number) => void;
  maxImageHeight: number;
  minImageHeight: number;
  slider: ISlider;
  setSlider: (arg: ISlider) => void;
  getScalaValue: (value: number) => void;
  isLocked: boolean
  swpRef: React.MutableRefObject<SwiperRef | null>
}> = ({
  slider,
  setSlider,
  heightMM,
  updateImageHeight,
  maxImageHeight,
  minImageHeight,
  getScalaValue,
  isLocked,
  swpRef
}) => {
    
  React.useEffect(() => {
    if (heightMM === null && heightMM === undefined) return;
    setTimeout(() => {
      setSlider(
        produce(slider, (draft) => {
          draft.value = heightMM!;
          draft.scale = getScalaValue(heightMM)!;
        })
      );
    }, 200);
  }, [heightMM]);

  const updateRangeValue = (value: number) => {
    setSlider(
      produce(slider, (draft) => {
        draft.value = value;
        draft.scale = getScalaValue(value)!;
      })
    );
    updateImageHeight(value);
  };

  return (
    <SliderStyleWrapper>
      <div className="rangeInputWrapper">
        <IonRange
          className="rangeInput"
          color={"secondary"}
          value={slider.value!}
          onIonKnobMoveStart={() => {
            swpRef.current!.allowTouchMove = false
          }}
          onIonKnobMoveEnd={(e) => {
            updateRangeValue(Number(e.detail.value))
            swpRef.current!.allowTouchMove = true
          }}
          min={minImageHeight}
          max={maxImageHeight}
          ticks={true}
          pin={true}
          disabled={isLocked}
        >
          <IonLabel className="minLabel" color={"medium"} slot="start">
            {minImageHeight.toFixed(0)} mm
          </IonLabel>
          <IonLabel className="maxLabel" color={"medium"} slot="end">
            {maxImageHeight.toFixed(0)} mm
          </IonLabel>
        </IonRange>
      </div>
    </SliderStyleWrapper>
  );
};

const SliderStyleWrapper = styled.div`
  width: 100%;
  .rangeInputWrapper {
    width: 100%;
    .rangeInput {
      --knob-size: 15px;
      --knob-background: var(--ion-color-secondary);
      .minLabel, .maxLabel {
        font-size: 13px;
      }
    }
  }
`;

export default Slider;
