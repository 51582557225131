import React from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonText,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useQuery } from "@apollo/client";
import {
  CaseFolderCustomerScope,
  GetArchiveCaseFolders,
  GetArchiveCaseFoldersVariables,
  GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders,
  GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages,
  OrderStatus,
} from "../../schema";
import { GET_ARCHIVE_CASE_Folder } from "../../graphql/queries/getArchiveCaseFolder";
import ErrorAlert from "../../components/ErrorAlert";
import Status from "../../components/Status";
import styled from "styled-components";
import { folderOpenOutline, personCircleOutline } from "ionicons/icons";
import ArchiveDetailsModal from "./ArchiveDetailsModal";
import FilterTools from "./FilterTools";
import produce from "immer";

export interface IState {
  scope: CaseFolderCustomerScope;
  filterByOrderStatus: OrderStatus;
  textFilter: string;
  startindex: number;
  pageLength: number;
}

export type IProducts =
  | (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_productPackages | null)[]
  | undefined;

type ICaseFolder =
  | (GetArchiveCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders | null)[]
  | undefined;
const ArchivePage: React.FC = () => {
  const initialState = {
    scope: CaseFolderCustomerScope.Default,
    filterByOrderStatus: OrderStatus.NONE,
    textFilter: "",
    startindex: 0,
    pageLength: 10,
  };
  const [openDetailsModal, setOpenDetailsModal] =
    React.useState<boolean>(false);
  const [state, setState] = React.useState<IState | null>(initialState);
  const [selectedProducts, setSelectedProducts] = React.useState<{
    products: IProducts;
    caseFolderId: string;
  }>();
  const [isInfiniteDisabled, setInfiniteDisabled] =
    React.useState<boolean>(false);
  const [caseFolderList, setCaseFolderList] = React.useState<ICaseFolder>();

  const handleOpenModal = (
    products: IProducts | undefined,
    caseFolderId: string
  ) => {
    setSelectedProducts({ products: products, caseFolderId: caseFolderId });
    setOpenDetailsModal(true);
  };
  const handleCloseModal = () => setOpenDetailsModal(false);

  const { data, loading, error } = useQuery<
    GetArchiveCaseFolders,
    GetArchiveCaseFoldersVariables
  >(GET_ARCHIVE_CASE_Folder, {
    context: {
      debounceKey: "archivePage",
      debounceTimeout: 450,
    },
    variables: state!,
  });

  const caseFolders =
    data && data?.findCaseFoldersByCurrentCustomer.caseFolders;

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && caseFolders) {
      setCaseFolderList(caseFolders);
    }
    return () => {
      isMounted = false;
    };
  }, [data]);

  const pushData = () => {
    if (data && data.findCaseFoldersByCurrentCustomer.caseFolders) {
      setState(
        produce(state, (draft) => {
          draft!.pageLength = state!.pageLength + 10;
        })
      );
    }
  };

  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      ev.target.complete();
      if (
        caseFolderList!.length === data?.findCaseFoldersByCurrentCustomer.count
      ) {
        setInfiniteDisabled(true);
      }
    }, 200);
  };

  useIonViewWillEnter(() => {
    pushData();
  });

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <FilterTools state={state!} setState={setState} />
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={true} fullscreen>
        {loading ? (
          <IonProgressBar type="indeterminate"></IonProgressBar>
        ) : (
          <div className="fill" style={{ height: "3px" }}></div>
        )}
        <ArchivePageStyleWrapper>
          <div className="archivePageContainer">
            <div className="archivePageHeader">
              <IonLabel className="label">Arkiv</IonLabel>
            </div>
            <IonList className="caseFolderList" lines={"none"}>
              {caseFolderList?.map((caseFolder, index) => {
                return (
                  <IonItem
                    key={index}
                    detail
                    lines={"none"}
                    shape={"round"}
                    className="caseFolderItem"
                    onClick={() =>
                      handleOpenModal(
                        caseFolder?.productPackages,
                        caseFolder?.id!
                      )
                    }
                  >
                    <div className="itemContent" slot={"start"}>
                      <IonLabel className="itemLabel">
                        {caseFolder?.name}
                      </IonLabel>
                      <div className="itemContentInfo">
                        <div className="workerWrapper">
                          <IonIcon
                            icon={personCircleOutline}
                            className={"icon"}
                            color={"secondary"}
                          />
                          <IonText className="worker">
                            {caseFolder?.customer.name}
                          </IonText>
                        </div>
                        <div className="productNumberWrapper">
                          <IonIcon
                            icon={folderOpenOutline}
                            className={"icon"}
                            color={"secondary"}
                          />
                          <IonText className="number">
                            {caseFolder?.summary.countOrders}
                          </IonText>
                        </div>
                        <div className="statusWrapper">
                          <Status
                            color={caseFolder?.summary.mostCriticalColor!}
                            text={caseFolder?.summary.mostCriticalStatus}
                            isTextMode={true}
                          />
                        </div>
                      </div>
                    </div>
                  </IonItem>
                );
              })}
            </IonList>
          </div>

          <ArchiveDetailsModal
            isOpen={openDetailsModal}
            doClose={handleCloseModal}
            selectedProducts={selectedProducts!}
          />

          <IonInfiniteScroll
            onIonInfinite={loadData}
            threshold="10px"
            disabled={isInfiniteDisabled}
            position={"bottom"}
            className={"infiniteScroll"}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Anropar mer data ..."
              color="secondary"
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </ArchivePageStyleWrapper>
      </IonContent>
      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </>
  );
};

const ArchivePageStyleWrapper = styled.div`
  margin-bottom: 150px;
  .archivePageContainer {
    .archivePageHeader {
      margin: 15px 15px 10px 15px;
      .label {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .caseFolderList {
      background: none;
      .caseFolderItem {
        border: 1px solid var(--ion-color-light-shade);
        --padding-end: 0px;
        --inner-padding-end: 0px;
        margin: 8px 15px;
        border-radius: 5px;
        -webkit-box-shadow: 0px 2px 8px 0px rgba(245, 245, 245, 1);
        -moz-box-shadow: 0px 2px 8px 0px rgba(245, 245, 245, 1);
        box-shadow: 0px 2px 8px 0px rgba(245, 245, 245, 1);
        --detail-icon-color: var(--ion-color-secondary);
        --detail-icon-font-size: 25px;

        .itemContent {
          padding: 12px 6px;
          .itemLabel {
            font-weight: bold;
          }
          .itemContentInfo {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .workerWrapper {
              display: flex;
              align-items: center;
              .icon {
                font-size: 20px;
              }
              .worker {
                font-size: 13px;
                margin-left: 5px;
                white-space: nowrap;
              }
            }
            .productNumberWrapper {
              margin-left: 10px;
              display: flex;
              align-items: center;
              .icon {
                font-size: 18px;
              }
              .number {
                font-size: 13px;
                margin-left: 5px;
              }
            }
            .statusWrapper {
              margin-left: 5px;
              .text {
                font-size: 12px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .infiniteScroll {
    width: 100%;
    height: auto;
    padding: 10px;
  }
`;

export default ArchivePage;
