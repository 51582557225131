import React from "react";
import "./styles.css";
import { useParams } from "react-router";
import ErrorAlert from "../../components/ErrorAlert";
import GET_ORDER_BY_ID from "../../graphql/queries/getOrderById";
import { GetOrderById, GetOrderByIdVariables } from "../../schema";
import { useQuery } from "@apollo/client";
import OrderContainer from "./OrderContainer";
import { IonContent, IonProgressBar } from "@ionic/react";

interface IParams {
  caseFolderId: string;
  orderId: string;
}

const OrdersPage: React.FC = () => {
  const params: IParams = useParams();

  const { data, loading, error, refetch } = useQuery<
    GetOrderById,
    GetOrderByIdVariables
  >(GET_ORDER_BY_ID, {
    variables: {
      id: params.orderId,
    },
  });

  return (
    <IonContent className={"bg-transparent"} scrollY={false}>
      {loading && <IonProgressBar type="indeterminate"></IonProgressBar>}

      {data && data.orders && (
        <OrderContainer
          isLoading={loading}
          orders={data.orders}
          refetch={refetch}
          userInterface={data?.orders[0].mediaOrder?.edit.userInterface}
          caseFolderId={params.caseFolderId}
        />
      )}

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </IonContent>
  );
};

export default OrdersPage;
