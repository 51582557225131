import { useMutation } from "@apollo/client";
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";
import React from "react";
import { UPDATE_ORDER_LOCK } from "../../../../../graphql/mutation/updateOrderLock";
import {
  EditModelTypes,
  UpdateOrderLock,
  UpdateOrderLockVariables,
} from "../../../../../schema";
import Button from "../../../../../components/Button";
import ErrorAlert from "../../../../../components/ErrorAlert";
import { IonAlert } from "@ionic/react";

const LockButton: React.FC<{
  orderId: string | null | undefined;
  isLocked: boolean;
  lockButtonText: string | null | undefined;
  unlockButtonText: string | null | undefined;
  isDisabled: boolean;
  editModelType: EditModelTypes;
  dismissPopover: () => void;
}> = (props) => {
  const [alert, setAlert] = React.useState<boolean>(false);
  const { MEMORY_ROOM, ADVERT, LIVE_STREAM } = EditModelTypes;

  const [mutateOrderLock, { loading, error }] = useMutation<
    UpdateOrderLock,
    UpdateOrderLockVariables
  >(UPDATE_ORDER_LOCK, {
    context: {
      debounceKey: "handleLockAndUnlock",
      debounceTimeout: 500,
    },
  });

  const handleLockOrUnLockOrder = () => {
    props.dismissPopover();
    mutateOrderLock({
      variables: {
        input: {
          orderId: props.orderId!,
          isLockOrUnlock: !props.isLocked,
        },
      },
    });
  };
  console.log(props.editModelType)
  const titleType =
    (props.editModelType === ADVERT && "annons") ||
    (props.editModelType === MEMORY_ROOM && "Minnsrum") ||
    (props.editModelType === LIVE_STREAM && "Direktsändning")
  const orderType =
    (props.editModelType === ADVERT && "annonsen") ||
    (props.editModelType === MEMORY_ROOM && "Minnsrummet") ||
    (props.editModelType === LIVE_STREAM && "Direktsändning")

  const lockTitle =  `Lås ${titleType}`;
  const unlockTitle = `Lås upp ${titleType}`;
  const unLockText =
    `Du är på väg att låsa upp ${orderType}. Detta avbokar inte ordern, kontakta supporten för att få hjälp om du önskar att avboka.`
  const lockText =
  `Du är påväg att låsa ${orderType}. Det kommer inte att skicka iväg ordern. Klicka på 'Lås' för att fortsätta.`


  const confirmTitle = props.isLocked ? unlockTitle : lockTitle;
  const confirmText = props.isLocked ? unLockText : lockText;
  const buttonText = props.isLocked ? "lås upp" : "lås";

  return (
    <div>
      <Button
        icon={props.isLocked ? lockClosedOutline : lockOpenOutline}
        iconDir={"start"}
        variants={"secondary_noLine"}
        onClick={() => setAlert(true)}
        isLoading={loading}
        className="button"
        isDisabled={props.isDisabled}
      >
        {props.lockButtonText ?? props.unlockButtonText}
      </Button>

      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        header={confirmTitle}
        message={confirmText}
        buttons={[
          {
            text: buttonText,
            role: "confirm",
            handler: () => {
              handleLockOrUnLockOrder();
            },
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default LockButton;
