import React from "react";
import { IonNote, IonTextarea } from "@ionic/react";
import styled from "styled-components";

const RcTextarea: React.FC<{
  onChange?: (arg: any) => void;
  value?: any;
  rows?: number;
  columns?: number
  name?: string
  placeholder?: string
  className?: string
  errorMsg?: string
}> = (props) => {
  return (
    <RcTextareaStyleWrapper>
      <IonTextarea
        className={`textarea ${props.className}`}
        value={props.value}
        onIonChange={props.onChange}
        rows={props.rows}
        cols={props.columns}
        name={props.name}
        placeholder={props.placeholder}
        style={props.errorMsg ? {border: `1px solid ${"var(--ion-color-danger)"}` }: {}}
      ></IonTextarea>
        {props.errorMsg && (
            <IonNote className="errorMsg">{props.errorMsg}</IonNote>
          )}
    </RcTextareaStyleWrapper>
  );
};

const RcTextareaStyleWrapper = styled.div`
    .textarea {
        --background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%)
        0% 0% no-repeat padding-box;
        border: 1px solid #d8d8d8;
        border-radius: 6px;
    }
    .errorMsg {
      color: var(--ion-color-danger);
      font-size: 12px;
        font-weight: bold;
    }

`;

export default RcTextarea;
