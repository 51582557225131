import gql from "graphql-tag"

const GET_GALLERY_TEXT_GROUPS_BY_TAGS = gql`
  query getGalleryTextGroupsByTags($input: GalleryTextItemGroupInput!) {
    galleryTextGroupsByTags(input: $input) {
      count
      startIndex
      textGroups {
        count
        name
        items {
          id
          name
          textXml
          textPlain
          author
          tags
          internalId
          isAlisVerified
          __typename
        }
      }
      texts {
        id
        name
	    	textXml
        textPlain
        author
        tags
        internalId
        isAlisVerified
        __typename
      }
      __typename
    }
  }
`

export default GET_GALLERY_TEXT_GROUPS_BY_TAGS;