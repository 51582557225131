import gql from "graphql-tag";

const EDIT_MODEL_MEMORY_ROOM_FRAGMENT = gql`
  fragment editModelMemoryRoomFragment on EditModelMemoryRoom {
    id
    entries {
      memoryRoomUrl
      voucherKey
    }
    case {
      personFirstname
      personLastname
      personBornLastname
      personCityDied
      personTimeBorn {
        value
      }
      personTimeDied {
        value
      }
      personBornLastname
      personCityDied
    }
    features {
      isLightningCandle
    }
    contents {
      # avatarImageFilename
      # avatarImageId
      # backgroundImageId
      # fundId
      # funds {
      #   id
      #   name
      # }
      welcomeText
      avatarImage {
        value {
          id
          imageUrl
          name
        }
      }
      images {
        id
        imageUrl
        name
      }
    }
    funeral {
      ceremonies {
        lastBookingTime {
          value
        }
        time {
          value
        }
        locationText
        infoText
      }
    }
  }
`;

export default EDIT_MODEL_MEMORY_ROOM_FRAGMENT;
