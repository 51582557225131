import React from "react";
import styled from "styled-components";
import "./styles.css";
import cx from "classnames";
import { IonText } from "@ionic/react";
import { OrderStatus } from "../../schema";
import { statusText } from "../../utilities";

const Status: React.FC<{
  color: "BLUE" | "YELLOW" | "GREEN" | "RED" | "GRAY";
  className?: string;
  text?: OrderStatus;
  isTextMode?: boolean;
}> = (props) => {


  return (
    <StatusStyleWrapper className={props.className}>
      <div className={`statusContainer`}>
        {!props.isTextMode && (
          <div
            className={cx("", {
              green: props.color === "GREEN",
              blue: props.color === "BLUE",
              yellow: props.color === "YELLOW",
              red: props.color === "RED",
              gray: props.color === "GRAY",
            })}
          ></div>
        )}
      </div>
      <IonText
        className={cx("text", {
          green: props.isTextMode && props.color === "GREEN",
          blue: props.isTextMode && props.color === "BLUE",
          yellow: props.isTextMode && props.color === "YELLOW",
          red: props.isTextMode && props.color === "RED",
          gray: props.isTextMode && props.color === "GRAY",
        })}
      >
        {statusText[props.text!]}
      </IonText>
    </StatusStyleWrapper>
  );
};

const StatusStyleWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    margin-left: 3px;
    font-size: 14px;
    &.green {
      color: #1ee498;
    }
    &.blue {
      color: #1778e4;
    }
    &.yellow {
      color: #fea15e;
    }
    &.red {
      color: #fc5252;
    }
    &.gray {
      color: #89898a;
    }
  }
  .statusContainer {
    div {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 0.5px solid #d8d8d8;
      &.green {
        background-color: #1ee498;
      }
      &.blue {
        background-color: #1778e4;
      }
      &.yellow {
        background-color: #fea15e;
      }
      &.red {
        background-color: #fc5252;
      }
      &.gray {
        background-color: #89898a;
      }
    }
  }
`;

export default Status;
