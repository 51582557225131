import React from "react";
import { search } from "ionicons/icons";
import Button from "../../../../../../../../../components/Button";
import {
  EditModelAdvertAreaContentEditorBehaviours,
  TextAlignments,
} from "../../../../../../../../../schema";
import TextAlignment from "./TextAlignment";
import { useContextSelector } from "use-context-selector";
import { AreasContext } from "../../..";

const Tools: React.FC<{
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours;
  handleOpenVerseModal: () => void;
  isLocked: boolean;
  isEnabledMultiColumns: boolean;
  isAdvancedMode: boolean;
  //   isAllowedParagraphClasses: boolean;
  isFontStyleMode: boolean;
  handleSwitchFontStyleModeClose: () => void;
  handleSwitchFontStyleModeOpen: () => void;
  handleAdvancedModeOpen: () => void;
  handleAdvancedModeClose: () => void;
  textAlignment: TextAlignments | null;
  row: number;
  column: number;
}> = (props) => {
  const {
    allowedTextAdjustments,
    allowedParagraphClasses,
    handleTextAlignment,
  } = useContextSelector(AreasContext, (v) => ({
    allowedTextAdjustments: v?.editor_Text?.allowedTextAdjustments,
    allowedParagraphClasses: v?.editor_Text?.allowedParagraphClasses,
    handleTextAlignment: v?.handleTextAlignment,
  }));

  const isAllowedParagraphClasses =
    (allowedParagraphClasses && allowedParagraphClasses.length === 0) ||
    allowedParagraphClasses === null
      ? false
      : true;

  const { TEXT_HTML_VERSE } = EditModelAdvertAreaContentEditorBehaviours;

  return (
    <div className="TextAreaHeader">
      <TextAlignment
        allowedTextAdjustments={allowedTextAdjustments!}
        textAlignment={props.textAlignment}
        handleTextAlignment={handleTextAlignment!}
        row={props.row}
        column={props.column}
        isLocked={props.isLocked}
      />
      {props.editorBehaviour === TEXT_HTML_VERSE && (
        <Button
          variants={"secondary_outline"}
          onClick={props.handleOpenVerseModal}
          icon={search}
          iconDir={"start"}
          isDisabled={props.isLocked}
          size={"small"}
        >
          Vers
        </Button>
      )}

      {isAllowedParagraphClasses && (
        <Button
          variants={"secondary_outline"}
          isDisabled={props.isLocked}
          onClick={
            props.isFontStyleMode
              ? props.handleSwitchFontStyleModeClose
              : props.handleSwitchFontStyleModeOpen
          }
          size={"small"}
        >
          {!props.isFontStyleMode ? "stil" : "tillbaka"}
        </Button>
      )}

      {props.isEnabledMultiColumns && (
        <Button
          variants={
            !props.isAdvancedMode ? "secondary_outline" : "danger-noLine"
          }
          isDisabled={props.isLocked}
          onClick={
            !props.isAdvancedMode
              ? props.handleAdvancedModeOpen
              : props.handleAdvancedModeClose
          }
          size={"small"}
        >
          {!props.isAdvancedMode ? "Avancerad" : "Stäng"}
        </Button>
      )}
    </div>
  );
};

export default Tools;
