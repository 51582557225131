import React from "react";
import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { closeCircleOutline, documentTextOutline } from "ionicons/icons";
import Button from "../../../../components/Button";
import Status from "../../../../components/Status";
import {
  EditModelTypes,
  GetOrderById_orders_mediaOrder_status,
} from "../../../../schema";
import styled from "styled-components";
import ProofModal from "../../../../components/ProofModel";

const DetailsBlock: React.FC<{
  handleCloseExpandedDiv?: () => void;
  orderFriendlyId: string | undefined;
  editModelTypes: EditModelTypes | undefined;
  orderHeight: number | undefined;
  orderWidth: number | undefined;
  orderCurrency: string | undefined;
  orderPrice: number;
  status: GetOrderById_orders_mediaOrder_status | undefined;
}> = (props) => {
  const { ADVERT } = EditModelTypes;
  const [isProofOpen, setProofOpen] = React.useState<boolean>(false);

  return (
    <DetailsExpandedStyleWrapper>
      <div className="expandedContent">
        <div className="expandedContentHeader">
          <Button
            className="closeBtn"
            variants={"secondary_noLine"}
            icon={closeCircleOutline}
            iconDir={"icon-only"}
            size={"small"}
            onClick={props.handleCloseExpandedDiv}
          ></Button>
        </div>
        <div className="detailsContent">
          <IonList className="details" lines={"none"}>
            <IonItem className="item">
              <div className="detailsInnerItem">
                <IonLabel className="label">Order</IonLabel>
                <IonText className="text">{props.orderFriendlyId}</IonText>
              </div>
            </IonItem>
            {props.editModelTypes === ADVERT && (
              <IonItem className="item">
                <div className="detailsInnerItem">
                  <IonLabel className="label">Storlek</IonLabel>

                  <IonText className="text">
                    {props.orderHeight && props.orderWidth
                      ? `${Number(props.orderHeight).toFixed(2)}mm x ${Number(props.orderWidth).toFixed(2)}mm`
                      : "N/A"}
                  </IonText>
                </div>
              </IonItem>
            )}

            <IonItem className="item">
              <div className="detailsInnerItem">
                <IonLabel className="label">Pris</IonLabel>

                <IonText className="text">
                  {props.orderCurrency && props.orderPrice
                    ? `${Math.round(props.orderPrice)}   ${props.orderCurrency}`
                    : "N/A"}
                </IonText>
              </div>
            </IonItem>
            <IonItem className="item">
              <div className="detailsInnerItem">
                <IonLabel className="label">Status</IonLabel>
                <div className="status">
                  <Status
                    color={props.status?.color!}
                    text={props.status?.status}
                  />
                </div>
              </div>
            </IonItem>
          </IonList>
          {props.editModelTypes === ADVERT && (
            <div className="proofWrapper">
              <Button
                icon={documentTextOutline}
                iconDir={"start"}
                variants={"secondary_noLine"}
                className="proofBtn"
                onClick={() => setProofOpen(true)}
              >
                Korrektur
              </Button>
            </div>
          )}
        </div>
      </div>

      {isProofOpen === true ? (
        <ProofModal
          isOpen={isProofOpen}
          setIsIOpen={setProofOpen}
          doClose={() => setProofOpen(false)}
        />
      ) : null}
    </DetailsExpandedStyleWrapper>
  );
};

const DetailsExpandedStyleWrapper = styled.div`
  width: 100%;
  .expandedContent {
    width: 100%;
    height: 300px;
    .expandedContentHeader {
      display: flex;
      justify-content: flex-end;
      .closeBtn {
        font-size: 18px;
      }
    }
    .detailsContent {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      .details {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;
        .item {
          .detailsInnerItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .label {
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 15px;
          }
          .text {
            font-size: 14px;
          }
        }
        }

      }
      .proofWrapper {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .proofBtn {
          font-size: 15px;
        }
      }
    }
  }
`;

export default DetailsBlock;
