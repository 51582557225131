import {
  InputChangeEventDetail,
  IonImg,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import {
  checkmarkDoneOutline,
  cloudDownloadOutline,
  copyOutline,
} from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import {
  GetOrderById_orders_mediaOrder_edit_editModelLiveStream,
  MessagingOrderInfo,
  MessagingOrderInfoVariables,
} from "../../../../../schema";
import useWindowDimensions from "../../../../../useHook/useWindowDimensions";
import { standardMobileScreenSize } from "../../../../../utilities";
import { Clipboard } from "@capacitor/clipboard";
import Button from "../../../../../components/Button";

import RcInput from "../../../../../components/RcInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import MESSAGING_ORDER_INFO from "../../../../../graphql/mutation/messagingOrderInfo";
import cx from "classnames";
import QrModal from "./QrModal";
import produce from "immer";

export interface IQrTable {
  qualityText: string;
  qualityNr: string;
  link: string | any;
  isSelected?: boolean;
  larix?: any;
}

interface IAdvance {
  name: string;
  value: string;
  isCopied: boolean;
}
const mobileCss: any = {
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
};

const tabletCss: any = {
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
};

const formTablet: any = {
  display: "flex",
  flexDirection: "reverse-row",
  columnGap: "20px",
  alignItems: "flex-end",
};

const formMobile: any = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  flexWrap: "wrap",
  rowGap: "10px",
};

const ActiveLiveStream: React.FC<{
  liveStream:
    | GetOrderById_orders_mediaOrder_edit_editModelLiveStream
    | null
    | undefined;
  waitImageUrl: string | null | undefined;
  orderId: string | null | undefined;
}> = (props) => {
  const { liveStream, waitImageUrl } = props;
  const isActivated = liveStream?.isActivated;
  const { width } = useWindowDimensions();
  const isTabletView = standardMobileScreenSize < width;
  const [qrDetailModal, setQrDetailModal] = React.useState<boolean>(false);
  const [selectedQr, setSelectedQr] = React.useState<IQrTable | null>(null);

  const [advanceLink, setAdvanceLink] = React.useState<IAdvance[]>([
    {
      name: "Strömningsnamn",
      value: liveStream?.advanced.streamName!,
      isCopied: false,
    },
    {
      name: "Ingestion-url",
      value: liveStream?.advanced.ingestionUrl!,
      isCopied: false,
    },
    {
      name: "Spelarlänkar",
      value: liveStream?.advanced.playerUrl!,
      isCopied: false,
    },
  ]);
  const qrTable: IQrTable[] = [
    {
      qualityText: "Låg",
      qualityNr: "500",
      link: liveStream?.advanced.qrRecorderLarix500,
      isSelected: false,
      larix: liveStream?.advanced.RecorderLarix500,
    },
    {
      qualityText: "Mellan",
      qualityNr: "1000",
      link: liveStream?.advanced.qrRecorderLarix1000,
      isSelected: false,
      larix: liveStream?.advanced.RecorderLarix1000,
    },
    {
      qualityText: "Hög",
      qualityNr: "1500",
      link: liveStream?.advanced.qrRecorderLarix1500,
      isSelected: false,
      larix: liveStream?.advanced.RecorderLarix1500,
    },
  ];

  const [mutateMessagingInfo, { error, loading }] = useMutation<
    MessagingOrderInfo,
    MessagingOrderInfoVariables
  >(MESSAGING_ORDER_INFO, {});

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("ogiltig email form"),
    }),
    onSubmit: (values) => {
      console.log(values.email);
    },
    validateOnBlur: false,
    validateOnChange: true,
  });

  const handleOpenQrModal = (qr: IQrTable) => {
    setQrDetailModal(true);
    setSelectedQr(qr);
  };

  const downloadPdf = () => {
    const url = liveStream?.ceremonyAgendaUrl;
    if (url) {
      let link = document.createElement("a");
      link.href = url;
      link.download = "Taps5";
      link.target = "_blank";
      link.dispatchEvent(new MouseEvent("click"));
    }
  };

  const sendEmail = () => {
    if (formik.isValid) {
      mutateMessagingInfo({
        variables: {
          input: {
            orderIds: [props.orderId!],
            email: formik.values.email,
          },
        },
      });
    }
  };

  const writeToClipboard = async (text: string, name: string) => {
    setAdvanceLink(
      produce(advanceLink, (draft) => {
        draft.filter((d) => {
          if (name === d.name) {
            d.isCopied = true;
          }
          return null
        });
      })
    );
    await Clipboard.write({
      url: text,
      label: "data",
    });
    checkClipboard().then((result) => {
      if (result) {
        setTimeout(
          () =>
            setAdvanceLink(
              produce(advanceLink, (draft) => {
                draft.filter((d) => {
                  if (name === d.name) {
                    d.isCopied = false;
                  }
                  return null
                });

              })
            ),
          1000
        );
      }
    });
  };

  const checkClipboard = async () => {
    const { value } = await Clipboard.read();
    return value;
  };

  return (
    <ActiveLiveStreamStyleWrapper>
      <div
        className="activeLiveStream"
        style={isTabletView ? tabletCss : mobileCss}
      >
        {waitImageUrl && (
          <div className="imageContainer">
            <IonLabel className="label">
              Bakgrundsbild inför sändningen
            </IonLabel>
            <IonImg className="img" src={waitImageUrl!} alt={"taps5"} />
          </div>
        )}

        <div className="streamLink">
          <div>
            <IonLabel className="label">Strömningslänkar</IonLabel>
            <div className="qrCodeContainer">
              <IonLabel className="label">Autokonfiguration</IonLabel>
              <div className="qrCode">
                {qrTable.map((qr, index) => (
                  <div
                    className="qr"
                    key={index}
                    onClick={() => handleOpenQrModal(qr)}
                  >
                    <div>
                      <IonText className="qrText">{qr.qualityText}</IonText>
                      <br />
                      <IonText className="qrNumber">
                        {qr.qualityNr} kbps
                      </IonText>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="autoConfiguration"
            style={isTabletView ? formTablet : formMobile}
          >
            <div className="emailForm">
              <IonLabel className="label">Skicka autokonfiguration</IonLabel>
              <div className="inputWrapper">
                <div>
                  <RcInput
                    className="input"
                    type={"email"}
                    placeholder={"info@byra.se"}
                    name={"email"}
                    value={formik.values.email}
                    onChange={(
                      e: CustomEvent<
                        HTMLIonInputElement | InputChangeEventDetail
                      >
                    ) => {
                      formik.setFieldValue("email", e.detail.value);
                    }}
                    // disabled={!isActivated}
                    errorMsg={formik.errors.email || error?.message}
                  />
                </div>

                <Button
                  variants={"secondary_outline"}
                  className={"sendButton"}
                  onClick={sendEmail}
                  // isDisabled={!isActivated}
                  isLoading={loading}
                >
                  Skicka
                </Button>
              </div>
            </div>
            {liveStream?.ceremonyAgendaUrl && (
              <div className="downloadButtonWrapper">
                <Button
                  variants={"secondary_outline"}
                  icon={cloudDownloadOutline}
                  iconDir={"start"}
                  onClick={downloadPdf}
                  className={"pdfDownload"}
                >
                  ladda ner
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="advanceWrapper">
        <IonLabel className="advanceLabel">Avancerat</IonLabel>
        {advanceLink.map((link, index) => {
          return (
            <IonItem key={index} lines={"none"} className={"item"}>
              <div className="item-content">
                <h6 className="itemLabel">{link.name}</h6>
                <div className="inputWrapper">
                  <RcInput
                    value={link.value}
                    className={cx("input", {
                      tabletWidth: isTabletView,
                    })}
                  />
                  {!link.isCopied ? (
                    <Button
                      icon={copyOutline}
                      iconDir={"icon-only"}
                      size={"small"}
                      variants={"secondary_noLine"}
                      onClick={() => writeToClipboard(link.value, link.name)}
                    />
                  ) : (
                    <Button
                      icon={checkmarkDoneOutline}
                      iconDir={"icon-only"}
                      size={"small"}
                      variants={"secondary_noLine"}
                    />
                  )}
                </div>
              </div>
            </IonItem>
          );
        })}
      </div>
      {selectedQr && (
        <QrModal
          isOpen={qrDetailModal}
          onClose={() => setQrDetailModal(false)}
          selectedQr={selectedQr!}
        />
      )}
    </ActiveLiveStreamStyleWrapper>
  );
};

const ActiveLiveStreamStyleWrapper = styled.div`
  margin-bottom: 150px;
  .activeLiveStream {
    padding-bottom: 30px;
    .imageContainer {
      width: 250px;
      height: auto;
      .label {
        font-weight: bold;
      }
      .img {
        margin-top: 10px;
        width: 100%;
        min-width: 250px;
        height: 100%;
      }
    }
    .streamLink {
      .label {
        font-weight: bold;
      }
      .qrCodeContainer {
        width: 100%;
        .label {
          font-weight: bold;
          font-size: 14px;
        }
        .qrCode {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          border: 1px solid var(--ion-color-light-shade);
          padding: 10px;
          border-radius: 3px;
          .qr {
            border: 1px solid var(--ion-color-light-shade);
            width: 90px;
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 3px;
            margin: 10px;
            .qrText {
              font-weight: bold;
            }
            .qrNumber {
              font-size: 13px;
              color: var(--ion-color-medium);
            }
          }
        }
      }
      .autoConfiguration {
        margin-top: 20px;
        .emailForm {
          width: 100%;
          .label {
            font-weight: bold;
          }
          .inputWrapper {
            width: 100%;
            margin-top: 5px;
            display: flex;
            align-items: center;
            column-gap: 5px;
            .input {
              width: 180px;
            }
            .sendButton {
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
        .downloadButtonWrapper {
          .pdfDownload {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .advanceWrapper {
    width: 100%;
    height: auto;
    .advanceLabel {
      font-weight: bold;
    }
    .item {
      margin-top: 8px;
      .item-content {
        margin-top: 10px;
        width: 100%;
        .itemLabel {
          margin-bottom: 10px;
        }
        .inputWrapper {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .input {
            /* max-width: 350px; */
            flex: 2 1;
            font-size: 14px;
            &.tabletWidth {
              width: 450px;
            }
          }
        }
      }
    }
  }
`;

export default ActiveLiveStream;
