import gql from "graphql-tag";
import DASHBOARD_CASE_FOLDER_FRAGMENT from "../fragments/dashboardCaseFolderFragment";
import EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT from "../fragments/editOrderPageCaseFolderFragment";

export const DELETE_ORDER = gql`
  mutation DeleteOrder($input: MutationDeleteOrderInput!) {
    deleteOrder(input: $input) {
      isError
      errorReason
      caseFolders {
        ...EditOrderPageCaseFolderFragment
        ...DashboardCaseFolderFragment
      }
    }
  }
  ${EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT}
  ${DASHBOARD_CASE_FOLDER_FRAGMENT}
`;
