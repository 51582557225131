import React from "react";
import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonProgressBar,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import Modal from "../../../../../../../../components/Modal";
import {
  getGalleryTextGroupsByTags,
  getGalleryTextGroupsByTagsVariables,
  getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts,
} from "../../../../../../../../schema";
import { useApolloClient, useQuery } from "@apollo/client";
import GET_GALLERY_TEXT_GROUPS_BY_TAGS from "../../../../../../../../graphql/queries/getGalleryTextGroupsByTags";
import styled from "styled-components";
import ErrorAlert from "../../../../../../../../components/ErrorAlert";
import VersesContainer from "./VersesContainer";
import Button from "../../../../../../../../components/Button";
import { arrowBack } from "ionicons/icons";
import TipsBox from "../../../../../../../../components/TipsBox";
import produce from "immer";
// import {versesTips} from "../../../../../../../../../tipsBox.json"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointUp } from "@fortawesome/free-regular-svg-icons";

type IVersesList = getGalleryTextGroupsByTags_galleryTextGroupsByTags_texts;

interface IState {
  startIndex: number;
  count: number;
}

const VersesModal: React.FC<{
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  doClose: () => void;
  doCloseAreaModal: () => void;
  onPlainTextChange: (value: string, row: number, column: number) => void;
  column: number;
  row: number;
}> = (props) => {
  const [state, setState] = React.useState<IState>({
    startIndex: 0,
    count: 10,
  });
  const [versesList, setVersesList] = React.useState<IVersesList[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] =
    React.useState<boolean>(false);

  const [searchInputValue, setSearchInputValue] = React.useState<string>("");
  const client = useApolloClient();
  const ALIS_AGREEMENT_TAG = "@Common2021";
  const contentRef = React.useRef<HTMLIonContentElement | null>(null);

  const { data, loading, error } = useQuery<
    getGalleryTextGroupsByTags,
    getGalleryTextGroupsByTagsVariables
  >(GET_GALLERY_TEXT_GROUPS_BY_TAGS, {
    client,
    fetchPolicy: "network-only",
    variables: {
      input: {
        startIndex: state.startIndex,
        count: state.count,
        tags: [ALIS_AGREEMENT_TAG],
        filterText: searchInputValue.length === 0 ? "*" : searchInputValue,
      },
    },
  });

  React.useEffect(() => {
    if (data && data.galleryTextGroupsByTags) {
      setVersesList([...data.galleryTextGroupsByTags.texts]);
    }
  }, [data]);

  const pushData = () => {
    if (data && data.galleryTextGroupsByTags) {
      setState(
        produce(state, (draft) => {
          draft.count = state.count + 10;
        })
      );
    }
  };
  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      ev.target.complete();

      if (versesList.length === data?.galleryTextGroupsByTags.count! - 10) {
        //somehow the length of verseslist is 10 items less than actual length
        // therefore I had to took 10 item from the count
        setInfiniteDisabled(true);
      }
    }, 500);
  };

  useIonViewWillEnter(() => {
    pushData();
  });

  const selectedVerse = (value: string) => {
    if (props.onPlainTextChange) {
      props.onPlainTextChange(value, props.row, props.column);
      props.doCloseAreaModal();
      props.doClose();
    }
  };

  const handleSearchInput = (e: any) => {
    setSearchInputValue(e.detail.value!);
    scrollToTop();
    stateReset();
  };

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(1000);
  };
  const stateReset = () => {
    setState(
      produce(state, (draft) => {
        draft.count = 10;
      })
    );
  };

  return (
    <Modal
      isVisible={props.isOpen}
      setIsVisible={props.setIsOpen}
      doClose={props.doClose}
    >
      <VerseModalStyleWrapper>
        <IonHeader>
          <IonToolbar className="topToolbar">
            <div className="innerTopToolbar">
              <Button
                className="backBtn"
                variants={"secondary_noLine"}
                onClick={props.doClose}
                icon={arrowBack}
                iconDir={"icon-only"}
              />
              <IonTitle className="title">Verser</IonTitle>
            </div>
          </IonToolbar>

          <IonToolbar>
            <IonSearchbar
              className="searchInput"
              value={searchInputValue}
              debounce={450}
              onIonChange={handleSearchInput}
            />
          </IonToolbar>

          {loading ? (
            <IonProgressBar type="indeterminate"></IonProgressBar>
          ) : (
            <div className="fill" style={{ height: "3px" }}></div>
          )}
        </IonHeader>
        <IonContent
          className="versesContent"
          ref={contentRef}
          fullscreen
          scrollEvents={true}
          scrollY={true}
        >
          <TipsBox
            position="versesModal"
            icon={faHandPointUp}
            description="-Lägg till genom att trycka på önskad vers"
            description2="-ALIS är en stämpel för kvalitetssäkring"
          />
          <VersesContainer
            versesList={versesList!}
            selectedVerse={selectedVerse}
          />

          <IonInfiniteScroll
            onIonInfinite={loadData}
            threshold="100px"
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Anropar mer Verser ..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
        {error && (
          <ErrorAlert
            title={error.name}
            subTitle={"field to calling verses"}
            msg={error.message}
          />
        )}
      </VerseModalStyleWrapper>
    </Modal>
  );
};

const VerseModalStyleWrapper = styled.div`
  .topToolbar {
    .innerTopToolbar {
      width: 100%;
      display: flex;
      .title {
        text-align: center;
      }
    }
  }
  .subToolbar {
    .searchInput {
      .searchbar-search-icon {
        color: var(--ion-color-secondary);
      }
    }
  }
`;

export default VersesModal;
