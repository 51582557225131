import React from "react";
import { IonCol, IonGrid, IonRow, IonTextarea } from "@ionic/react";
import RcInput from "../../RcInput";
import RcTextarea from "../../RcTextarea";
import { FormikProps } from "formik";
import { IValues } from "..";

const Mediate: React.FC<{
  formik: FormikProps<IValues>;
}> = (props) => {
  const { formik } = props;
  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeSm="6" sizeXs="12">
          <RcInput
            className="input"
            placeholder="info@example.se"
            name={"sendEmail"}
            value={formik.values.sendEmail}
            onChange={(e) => formik.setFieldValue("sendEmail", e.detail.value)}
            errorMsg={formik.errors.sendEmail}
            label={"e-post"}
          />
          <RcInput
          className="input"
            placeholder="info@example.se"
            name={"mediateEmail"}
            value={formik.values.mediateEmail}
            onChange={(e) =>
              formik.setFieldValue("mediateEmail", e.detail.value)
            }
            errorMsg={formik.errors.mediateEmail}
            label={"Svarsadress"}
          />
        </IonCol>
        <IonCol sizeSm="6" sizeXs="12"  style={{marginTop: "10px"}}>
          <label className="label">Meddelande</label>
          <RcTextarea
            className={"input"}
            rows={5}
            name={"msg"}
            value={formik.values.msg}
            onChange={(e) => formik.setFieldValue("msg", e.detail.value)}
            placeholder={"Meddelande"}
            errorMsg={formik.errors.msg}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Mediate;
