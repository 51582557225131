import React from "react";
import { arrowUndoOutline } from "ionicons/icons";
import Button from "../../../../../components/Button";
import { IonAlert } from "@ionic/react";
import { useMutation } from "@apollo/client";
import { UNPUBLISH_ORDERS } from "../../../../../graphql/mutation/unPublishOrders";
import {
  UnPublishOrders,
  UnPublishOrdersVariables,
} from "../../../../../schema";
import ErrorAlert from "../../../../../components/ErrorAlert";

const UnpublishedButton: React.FC<{
  unPublishButtonText: string;
  orderId: string;
  dismissPopover: () => void
}> = (props) => {
  const [alert, setAlert] = React.useState<boolean>(false);

  const [mutateUnPublishOrders, { loading, error }] = useMutation<
    UnPublishOrders,
    UnPublishOrdersVariables
  >(UNPUBLISH_ORDERS);

  const confirmText =
    "Du är på väg att Avboka ordern vilken innebär att annonsen kommer att retunera. Vill du verkligen Avboka denna order ?";

  const handleUnPblishedOrder = () => {
    props.dismissPopover()
    mutateUnPublishOrders({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId!,
              messageToMedia: null,
              codeLate: null,
            },
          ],
        },
      },
    });
  };

  return (
    <div>
      <Button
        icon={arrowUndoOutline}
        iconDir={"start"}
        variants={"secondary_noLine"}
        onClick={() => setAlert(true)}
        className="button"
        isLoading={loading}
      >
        {props.unPublishButtonText}
      </Button>

      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        message={confirmText}
        buttons={[
          {
            text: props.unPublishButtonText,
            role: "confirm",
            handler: () => {
              handleUnPblishedOrder();
            },
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default UnpublishedButton;
