import gql from "graphql-tag";

export const GET_AVAILABLE_TAGS = gql`
  query GetAvailableTags($input: GalleryImageAvailableTagsInput!) {
    galleryImagesTagsByTags(input: $input) {
      tags
      __typename
    }
  }
`;
