import { Redirect, Route, Switch } from "react-router-dom";
import "./theme/global.css";
import {
  IonApp,
  IonContent,
  IonPage,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import DashboardPage from "./pages/DashboardPage";
import ArchivePage from "./pages/ArchivePage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import OrdersPage from "./pages/OrdersPage";
import PageContainer from "./components/PageContainer";
import SideMenu from "./components/SideMenu";
import CaseFolderDetailsTable from "./pages/DashboardPage/CaseFolderDetailsTable";
import PublishPageContainer from "./pages/PublishPage";
import LoginPage from "./pages/LoginPage";
import pkgJSON from "../package.json";
import React from "react";
import { getLocalStorageItem } from "./utilities";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";
import RenderOnAnonymous from "./components/RenderOnAnonymous";
import AppContextProvider from "./contexts/AppContextProvider";

setupIonicReact({
  mode: 'ios',
  scrollPadding: false,
  scrollAssist: false,
  swipeBackEnabled: false
});

interface IRouter {
  isExact: boolean;
  path: string;
  component: React.FC;
}

interface I_PathLink {
  DASHBOARD: string;
  DASHBOARD_CASE_FOLDER: string;
  ARCHIVE: string;
  EDIT_ORDER: string;
  PUBLISH_PAGE: string;
}

export const PATH_LINK: I_PathLink = {
  DASHBOARD: "/dashboard",
  DASHBOARD_CASE_FOLDER: "/dashboard/c/:caseFolderId",
  ARCHIVE: "/archive",
  EDIT_ORDER: "/editOrder/c/:caseFolderId/o/:orderId",
  PUBLISH_PAGE: "/publishOrder/c/:caseFolderId/d/:documentId/o/:orderId",
};

const App: React.FC = () => {
  const [token, setToken] = React.useState<string | null>(null);
  const [isSideMenuDisabled, setSideMenuDisabled] = React.useState<boolean>(false)

  const routers: Array<IRouter> = [
    {
      isExact: true,
      path: PATH_LINK.DASHBOARD,
      component: DashboardPage,
    },
    {
      isExact: true,
      path: PATH_LINK.DASHBOARD_CASE_FOLDER,
      component: CaseFolderDetailsTable,
    },
    {
      isExact: true,
      path: PATH_LINK.DASHBOARD_CASE_FOLDER,
      component: CaseFolderDetailsTable,
    },
    {
      isExact: true,
      path: PATH_LINK.ARCHIVE,
      component: ArchivePage,
    },
    {
      isExact: true,
      path: PATH_LINK.EDIT_ORDER,
      component: OrdersPage,
    },
    {
      isExact: true,
      path: PATH_LINK.PUBLISH_PAGE,
      component: PublishPageContainer,
    },
  ];

  React.useEffect(() => {
    getLocalStorageItem("token")
      .then((value) => {
        if (value !== undefined && value !== null) setToken(value);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(
    "%c APP_VERSION:" + pkgJSON.version,
    "background: #ff9d00; color: #ffffff; padding: 5px; font-weight: bold; font-size: 15px;"
  );
  return (
    <IonApp className="appContainer">
      <RenderOnAuthenticated token={token}>
        <AppContextProvider values={{
          isSideMenuDisabled: isSideMenuDisabled,
          setSideMenuDisabled: setSideMenuDisabled as any
        }}>
          <IonReactRouter>
            <SideMenu isMenuDisabled={isSideMenuDisabled} />
            <IonRouterOutlet id={"main"}>
              <PageContainer>
                <Switch>
                  {routers.map((route, index) => (
                    <Route
                      key={index}
                      exact={route.isExact}
                      path={route.path}
                      component={route.component}
                    />
                  ))}

                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </PageContainer>
            </IonRouterOutlet>
          </IonReactRouter>
        </AppContextProvider>
      </RenderOnAuthenticated>

      <RenderOnAnonymous token={token}>
        <IonReactRouter>
          <IonPage>
            <IonContent className="background-image">
              <Switch>
                <Route path={"/login"} component={LoginPage} />
                <Route exact path={"/"}>
                  <Redirect to="/login" />
                </Route>
                
              </Switch>
            </IonContent>
          </IonPage>
        </IonReactRouter>
      </RenderOnAnonymous>
    </IonApp>
  );
};

export default App;
