import React from "react";
import { IonContent, IonProgressBar } from "@ionic/react";
import { FormikProps, useFormik } from "formik";
import styled from "styled-components";
import { useContextSelector } from "use-context-selector";
import { OrderContext } from "..";
import moment from "moment";
import CaseInputs from "./CaseInputs";
import ContentInputs from "./ContentInputs";
import FuneralInputs from "./FuneralInputs";
import FooterToolbarContainer from "../../FooterToolbarContainer";
import UserInterFace from "../../../../components/UserInterface";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_ORDER_CONTENT } from "../../../../graphql/mutation/updateOrderContent";
import {
  UpdateOrderContent,
  UpdateOrderContentVariables,
} from "../../../../schema";
import * as Yup from "yup";
import cx from "classnames";

export interface I_FormikValue {
  personFirstname: string | undefined;
  personLastname: string | undefined;
  personTimeBorn: Date | null;
  personTimeDied: Date | null;
  personCityDied: string | undefined;
  personBornLastname: string | undefined;
  //ContentInfo //
  welcomeText: string | undefined;
  // ceremonies //
  infoText: string | undefined;
  locationText: string | undefined;
  lastBookingTime: Date | null;
  time: Date | null;
}

export interface ICase {
  personFirstname?: string | undefined;
  personLastname?: string | undefined;
  personTimeBorn?: Date | null;
  personTimeDied?: Date | null;
  personCityDied?: string | undefined;
  personBornLastname?: string | undefined;
}

export interface IOrderContentVariable {
  case: ICase;
  contents?: any;
  entries?: any;
  features?: any;
  funeral?: any;
  id?: string;
}

const MemoryRoom: React.FC = () => {
  const client = useApolloClient();
  const orderContextValues = useContextSelector(OrderContext, (v) => v);
  const memoryRoomInput =
    orderContextValues && orderContextValues?.editModelMemoryRoom;
  // console.log("memoryRoomInput", memoryRoomInput)
  const order = orderContextValues && orderContextValues.order;
  const userInterface = order?.mediaOrder?.edit.userInterface;
  const FORMAT = "yyyy-MM-DD";
  const caseInfo = memoryRoomInput && memoryRoomInput?.case;
  const contentInfo = memoryRoomInput && memoryRoomInput.contents;
  const funeralInfo = memoryRoomInput && memoryRoomInput.funeral.ceremonies[0];
  const refetch = orderContextValues?.refetch;

  const DEFAULT_DEBOUNCE = 2000;

  //convert Date to UTC
  const personBornUTCDate = moment.utc(caseInfo?.personTimeBorn.value);
  const personDiedUTCDate = moment.utc(caseInfo?.personTimeDied.value);

  const ceremoniesLastBookingTime = funeralInfo?.lastBookingTime.value
    ? moment(funeralInfo?.lastBookingTime.value).toDate()
    : null;

  const funeralTime = funeralInfo?.time.value
    ? moment(funeralInfo?.time.value).toDate()
    : null;

  const initialValues: I_FormikValue = {
    // caseInfo //
    personFirstname: caseInfo?.personFirstname,
    personLastname: caseInfo?.personLastname,
    personTimeBorn: personBornUTCDate.toDate() ?? null,
    personTimeDied: personDiedUTCDate.toDate() ?? null,
    personCityDied: caseInfo?.personCityDied,
    personBornLastname: caseInfo?.personBornLastname,
    //ContentInfo //
    welcomeText: contentInfo?.welcomeText,
    // ceremonies //
    infoText: funeralInfo?.infoText,
    locationText: funeralInfo?.locationText,
    // ceremonies --> minnsStund//
    lastBookingTime: ceremoniesLastBookingTime,
    // ceremonies --> tid för begrävning//
    time: funeralTime,
  };

  const validationSchema = Yup.object().shape({
    personFirstname: Yup.string().required("obligatoriskt fält"),
    personLastname: Yup.string().required("obligatoriskt fält"),
    personTimeBorn: Yup.date().required("obligatoriskt fält"),
    personTimeDied: Yup.date()
      .when("personTimeBorn", (personTimeBorn, schema) => {
        let dayBefore = new Date(personTimeBorn);
        dayBefore.setDate(dayBefore.getDate() - 1);
        if (personTimeBorn) {
          return schema.min(
            new Date(dayBefore),
            "Dödsdag kan inte vara tidigare än födelsdag."
          );
        }
        return schema;
      })
      .required("obligatoriskt fält"),
  });

  const formik: FormikProps<I_FormikValue> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
    validateOnBlur: false,
    validateOnChange: true,
  });

  const [mutateUpdateContent, { loading }] = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
    context: {
      debounceKey: "updateAdvertContentMemoryRoom",
      debounceTimeout: 500,
    },
  });

  const updateContent = (arg: UpdateOrderContentVariables) => {
    mutateUpdateContent({
      variables: {
        input: {
          orderId: order?.id!,
          editModel: {
            editModelMemoryRoom: {
              id: order?.id!,
              ...arg,
            },
          },
        },
      },
    });
  };

  return (
    <>
      {loading ? (
        <IonProgressBar type="indeterminate"></IonProgressBar>
      ) : (
        <div className="fill" style={{ height: "3px" }}></div>
      )}
      <IonContent className="bg-transparent">
        <MemoryRoomStyleWrapper>
          {userInterface?.editStatusText && (
            <div className="userInterfaceWrapper">
              <UserInterFace userInterface={userInterface} />
            </div>
          )}

          <div className="LockCanvas"></div>

          <div
            className={cx("inputsWrapper", {
              lockCanvas: order?.mediaOrder?.isLocked,
            })}
          >
            <CaseInputs
              updateContent={updateContent}
              dateFormat={FORMAT}
              formik={formik}
              debounce={DEFAULT_DEBOUNCE}
            />
            <ContentInputs
              updateContent={updateContent}
              contentInfo={contentInfo!}
              formik={formik}
              debounce={DEFAULT_DEBOUNCE}
              refetchData={refetch!}
            />
            <FuneralInputs
              dateFormat={FORMAT}
              formik={formik}
              debounce={DEFAULT_DEBOUNCE}
              updateContent={updateContent}
              orderId={order?.id}
            />
            <div className="filler"></div>
          </div>
          {order && (
            <FooterToolbarContainer
              userInterface={order.mediaOrder?.edit.userInterface}
              publishTime={order.mediaOrder?.publishTimes[0]?.time!}
              alert={order.alerting}
              orderFriendlyId={order.customerFriendlyId}
              preview={order.mediaOrder?.preview}
              orderId={order.id}
              isLocked={order.mediaOrder?.isLocked!}
              documentId={order.document.id}
              status={order.mediaOrder?.status}
              editModelTypes={order.mediaOrder?.edit.editModelType}
              formik={formik}
            />
          )}
        </MemoryRoomStyleWrapper>
      </IonContent>
    </>
  );
};

const MemoryRoomStyleWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 90px;
  .userInterfaceWrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
  .inputsWrapper {
    height: 100%;
    padding: 5px;
    background-color: white;
    position: relative;
    padding-bottom: 50px;
    &.lockCanvas {
      &::before {
        content: "";
        background-color: var(--ion-color-lock);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100% - 0px);
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 180px;
      }
    }
  }
  .textareaInputWrapper {
    margin: 10px 0;
    .label {
      font-size: 14px;
    }
    .textareaInput {
      margin-top: 5px;
      --background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%)
        0% 0% no-repeat padding-box;
      border-radius: 3px;
      border: 1px solid #d8d8d8;
    }
  }
  .filler {
    background-color: transparent;
    width: 100%;
    height: 150px;
    visibility: hidden;
  }
`;

export default MemoryRoom;
