import gql from "graphql-tag"
import  OrderFragment  from "../fragments/orderFragment";

export const UPDATE_ORDER_CONTENT = gql`
mutation UpdateOrderContent($input: MutationUpdateOrderContentInput!) {
  updateOrderContent(input: $input) {
    isError
    errorReason
    order {
      ...OrderFragment
      __typename
    }
    __typename
  }
}
 ${OrderFragment}
`;


// $previewImageWidthPx: Float
// previewImageWidthPx:$previewImageWidthPx