import React from "react";
import { IonCard, IonCardContent } from "@ionic/react";
import styled from "styled-components";
import {
  EditModelAdvertAreaContentEditorTypes,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines,
  TextAlignments,
  UpdateOrderContentVariables,
} from "../../../../../../schema";
import GalleyContent from "./GalleryContent";
import SeparatorContent from "./SeparatorContent";
import TextContent from "./TextContent";
import produce from "immer";
import { createContext, useContextSelector } from "use-context-selector";
import { OrderContext } from "../../..";
import CardHeader from "./CardHeader";
import {type Swiper as SwiperRef } from 'swiper'

export const AreasContext = createContext<IAreaContentProps | undefined>(
  undefined
);

const AreaContentContainer: React.FC<{
  title: string;
  editor: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null;
  value: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value | null;
  area: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null;
  mutateUpdateContent: (
    variables: UpdateOrderContentVariables
  ) => Promise<unknown>;
  closeAreaEditModal: () => void;
  orderId: string | null | undefined;
  doCloseAreaModal: () => void;
  isUpdateContentLoading: boolean;
  swpRef: React.MutableRefObject<SwiperRef | null>
  mediaId: string
}> = (props) => {
  const orderContextValue = useContextSelector(OrderContext, (v) => v);
  const { GALLERY_IMAGE, TEXT_HTML, SEPARATOR } =
    EditModelAdvertAreaContentEditorTypes;

  const [state, setState] = React.useState<{
    contentRows: ROW_TYPE[];
    visible: boolean;
  }>({ contentRows: [], visible: true });

  const getTextProperty = (propertyName: textKeys, column: COLUMNS_TYPE) => {
    let result = null;
    if (
      column &&
      column.item &&
      column.item.text !== undefined &&
      column.item.text !== null &&
      column.item.text[propertyName] !== undefined &&
      column.item.text[propertyName] !== null
    ) {
      result = column.item.text[propertyName];
    }
    return result;
  };

  const initSeparatorContentRows = (() => {
    const area = props.area!;
    return area.content.value.rows.map((row: ROW_TYPE) => ({
      columns: row.columns.map((column: COLUMNS_TYPE) => ({
        item: {
          ...column.item,
          text: null,
        },
      })),
    }));
  })();

  const initContentRows = ((): Array<any> => {
    const area = props.area!;
    return area.content.value.rows.map((row: ROW_TYPE) => ({
      columns: row.columns.map((column: COLUMNS_TYPE) => {
        return {
          item: {
            ...column.item,
            text: {
              ...column.item.text!,
              xmlText: null,
              lines: null,
              [fontSizeKey]: getTextProperty(fontSizeKey, column),
              [fontFamilyKey]: getTextProperty(fontFamilyKey, column),
              [lineHeightKey]: getTextProperty(lineHeightKey, column),
              [letterSpacingKey]: getTextProperty(letterSpacingKey, column),
              [textScaleWidthKey]: getTextProperty(textScaleWidthKey, column),
            },
          },
        };
      }),
    }));
  })();

  const initialState = React.useCallback(() => {
    //this should mount once at every render
    // don't add item in empty array bellow
    setState(
      produce(state, (draft) => {
        draft.visible = draft.visible = props.area?.content.value.visible!;
        if (props.editor!.type === GALLERY_IMAGE) {
          draft.contentRows = props.value!.rows.map((row) => ({
            ...row,
            columns: row.columns.map((column) => ({
              ...column,
              item: {
                ...column.item,
              },
            })),
          }));
        } else if (props.editor!.type === TEXT_HTML) {
          draft.contentRows = initContentRows;
        }
        if (props.editor!.type === SEPARATOR) {
          draft.contentRows = initSeparatorContentRows as any;
        }
      })
    );
  }, []);

  React.useEffect(() => {
    initialState();
  }, [initialState]);

  //----------------//
  const selectSymbol = (
    imageId: string,
    publicId: number,
    thumbnailImage: string
  ) => {
    const newContent = produce(state.contentRows, (draft) => {
      const image = draft[0].columns[0].item!.image;
      draft[0].columns[0].item.text = null;
      image!.imageId = imageId;
      image!.imagePublicId = publicId.toString();
      image!.imageThumbnaill100x80Url = thumbnailImage;
    });
    updateStateContent(newContent);
  };

  const updateImageHeight = (height: number) => {
    const updateContent = produce(state.contentRows, (draft) => {
      const image = draft[0].columns[0].item!.image;
      image!.heightMM = height;
    });
    updateStateContent(updateContent);
  };

  //----------------//
  const onPlainTextChange = (value: string, row: number, column: number) => {
    const newContent = produce(state.contentRows, (draft) => {
      draft[row].columns[column].item.text!.plainText = value;
      draft[row].columns[column].item.text!.xmlText = null;
      draft[row].columns[column].item.text!.lines = null;
    });
    updateStateContent(newContent);
  };

  const onLinesUpdate = (lines: Line[]) => {
    const newContent = produce(state.contentRows, (draft) => {
      draft[0].columns[0].item.text!.lines = lines;
    });
    updateStateContent(newContent);
  };

  const handleTextAlignment = (value:TextAlignments, row: number, column: number) => {
    const newContent = produce(state.contentRows, (draft) => {
      draft[row].columns[column].item.text!.textAlignment = value
    })
    updateContent(newContent)
  }

  const handleAreaInvisible = () => {
    setState({ visible: false, contentRows: state.contentRows });
    updateStateContent();
  };

  const saveContentRows = (contentRow: any[]) => {
    setState(
      produce(state, (draft) => {
        draft.contentRows = contentRow;
      })
    );
    updateContent(contentRow);
  };

  const updateStateContent = (rows?: ROW_TYPE[]) => {
    if (rows) {
      setState(
        produce(state, (draft) => {
          draft.contentRows = rows;
        })
      );
      updateContent(rows);
    } else {
      updateContent(state.contentRows);
    }
  };

  const updateContent = (rows: ROW_TYPE[]) => {
    if (props.mutateUpdateContent)
      props
        .mutateUpdateContent({
          input: {
            orderId: props.orderId!,
            editModel: {
              editModelAdvertAreaContentValues: [
                {
                  id: props.value!.id,
                  templateAreaId: props.value!.templateAreaId,
                  visible: state.visible,
                  hasContent: true,
                  isEditorVisible: true,
                  rows: rows,
                },
              ],
            },
          },
        })
        .catch((err) => console.log(err));
  };


  const contextValue = {
    selectSymbol,
    onPlainTextChange,
    doCloseAreaModal: props.doCloseAreaModal,
    paragraphClasses: props.editor?.text?.allowedParagraphClasses!,
    onLinesUpdate: onLinesUpdate,
    isUpdateContentLoading: props.isUpdateContentLoading,
    editor_Text: props.editor?.text,
    handleTextAlignment: handleTextAlignment
  };
  return (
    <AreasContext.Provider value={contextValue}>
      <AreaContentContainerStyleWrapper>
        <IonCard className="areaCardWrapper">
          <CardHeader
            doCloseAreaModal={props.doCloseAreaModal}
            title={props.title}
            handleAreaInvisible={handleAreaInvisible}
            isVisible={state.visible}
            isLocked={orderContextValue?.isLocked!}
            isLoading={props.isUpdateContentLoading!}
          />
          <IonCardContent>
            {props.editor?.type === GALLERY_IMAGE && (
              <GalleyContent
                imageProps={props.value?.rows[0].columns[0].item.image}
                closeAreaEditModal={props.closeAreaEditModal}
                selectSymbol={selectSymbol}
                isLocked={orderContextValue?.isLocked!}
                editorImage={props.editor.image}
                updateImageHeight={updateImageHeight}
                heightMM={props.value?.rows[0].columns[0].item.image?.heightMM!}
                editor={props.editor}
                templateAreaId={props.area?.content.value.templateAreaId}
                mediaId={props.mediaId}
                swpRef={props.swpRef}
              />
            )}
            {props.editor?.type === TEXT_HTML && (
              <TextContent
                rows={props.value?.rows}
                editorBehaviour={props.editor.behaviour}
                isLocked={orderContextValue?.isLocked!}
                saveContentRow={saveContentRows}
                isEnabledMultiColumns={
                  props.editor.common?.isEnabledMultiColumns!
                }
                isUpdateContentLoading={props.isUpdateContentLoading}
                swpRef={props.swpRef}
              />
            )}
            {props.editor?.type === SEPARATOR && <SeparatorContent />}
          </IonCardContent>
        </IonCard>
      </AreaContentContainerStyleWrapper>
    </AreasContext.Provider>
  );
};

//-----------------//
//---Typescript----//
//-----------------//
interface IAreaContentProps {
  selectSymbol: (
    imageID: string,
    publicId: number,
    thumbnailImage: string
  ) => void;
  doCloseAreaModal: () => void;
  onPlainTextChange: (value: string, row: number, column: number) => void;
  onLinesUpdate: (lines: Line[]) => void | undefined;
  paragraphClasses: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text_allowedParagraphClasses[];
  isUpdateContentLoading: boolean;
  editor_Text: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_text | null | undefined
  handleTextAlignment: (value: TextAlignments,  row: number, column: number) => void
}

type Line =
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines;

type textKeys =
  keyof GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text;
const fontFamilyKey: textKeys = "fontFamily";
const fontSizeKey: textKeys = "fontSize";
const letterSpacingKey: textKeys = "letterSpacing";
const lineHeightKey: textKeys = "lineHeight";
// const textAlignmentKey: textKeys = "textAlignment"
// const textNoWrapKey: textKeys = "textNoWrap"
const textScaleWidthKey: textKeys = "textScaleWidth";

type ROW_TYPE =
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows;
type COLUMNS_TYPE =
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns;

const AreaContentContainerStyleWrapper = styled.div`
  .areaCardWrapper {
    height: 365px;
    border: 2px solid var(--ion-color-tertiary);
    margin-bottom: 5px;
    .areaCardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      .left {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }
      .right {
        -webkit-box-shadow: 1px 4px 25px 0px rgba(212, 212, 212, 1);
        -moz-box-shadow: 1px 4px 25px 0px rgba(212, 212, 212, 1);
        box-shadow: 1px 4px 25px 0px rgba(212, 212, 212, 1);
        border-radius: 6px;
      }
      .areaHeaderTitle {
        text-align: left;
        font-size: 18px;
      }
    }
  }
`;

export default AreaContentContainer;
