import React from "react";
import "./styles.css";
// import PublishContainer from "./PublishContainer";
import CaseFolderTable from "./CaseFolderTable";
import { IonContent } from "@ionic/react";


const DashboardPage: React.FC = () => {

  return (
    <IonContent scrollY={true} className={"bg-transparent"}>
      {/* pending */}
      {/* <PublishContainer /> */}
      <CaseFolderTable />
    </IonContent>
  );
};
     

export default DashboardPage;
