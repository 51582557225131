import React from "react";
import {
  IonAlert,
  IonImg,
  IonLabel,
  IonNote,
} from "@ionic/react";
import styled from "styled-components";
import Button from "../../../../../components/Button";
import {
  addCircleOutline,
  closeCircleOutline,
} from "ionicons/icons";
import { useDropzone } from "react-dropzone";
import ImageEditorModal from "../../../../../components/ImageEditorModal";
import { EditModelLiveStreamInput } from "../../../../../schema";
import { blobToBase64, standardMobileScreenSize } from "../../../../../utilities";
import useWindowDimensions from "../../../../../useHook/useWindowDimensions";
import UploadPdfButton from "../UploadPdfButton";

export interface IImageFile {
  fileFormInput: any;
  name: any;
  type: any;
  lastModified: any;
  lastModifiedDate: any;
  size: any;
  path: any;
  preview: any;
}

const BeforeActiveLiveSteam: React.FC<{
  waitImageUrl: string | null | undefined;
  orderId: string | null | undefined;
  updateContent: (arg: EditModelLiveStreamInput) => void;
  ceremonyAgendaUrl: string | null | undefined;
}> = (props) => {
  const [isImageEditorModal, setImageEditorModal] =
    React.useState<boolean>(false);
  const [imageFile, setImageFile] = React.useState<IImageFile | null>(null);
  const [alert, setAlert] = React.useState<boolean>(false);
  const {width} = useWindowDimensions()
  const isTabletView = standardMobileScreenSize < width

  const openImageModal = (file: IImageFile) => {
    setImageFile(file);
    setImageEditorModal(true);
  };

  const successImageCrop = (blob: any) => {
    blobToBase64(blob).then((base64data) => {
      props.updateContent({
        waitImageBase64: base64data as any,
      });
    });
  };

  const clearImage = () => {
    props.updateContent({
      waitImageBase64: "",
    });
  };

  const onUploadPdf = (file: IImageFile) => {
    fetch(file.preview)
      .then((res) => res.blob())
      .then((result) => {
        blobToBase64(result).then((base64data) => {
          props.updateContent({
            ceremonyAgendaFilename: file.name,
            ceremonyAgendaBase64: base64data as any,
          });
        });
      })
      .catch((err) => console.log(err));
  };

  const downloadPdf = () => {
    const url = props.ceremonyAgendaUrl
    if(url) {
      let link = document.createElement("a")
      link.href = url
      link.download = "Taps5"
      link.target = "_blank"
      link.dispatchEvent(new MouseEvent("click"))
    }
  }

  const clearPdfFile = () => {
    props.updateContent({
      ceremonyAgendaFilename: "",
      ceremonyAgendaBase64: "",
    });
  };

  return (
    <BeforeActiveLiveStreamStyleWrapper>
      <div className="beforeActiveLiveStream" style={{flexDirection: isTabletView ? "row" : "column"}}>
        <div className="ImageContainer">
          <IonLabel className="label">Bakgrundsbild inför sändningen</IonLabel>
          <div className="imageWrapper">
            {props.waitImageUrl && (
              <div className="imageBlock">
                <IonImg className="img" src={props.waitImageUrl} />
                <div className="imageFooter">
                  <Button
                    size={"small"}
                    iconDir={"icon-only"}
                    variants={"secondary_outline"}
                    icon={closeCircleOutline}
                    onClick={() => setAlert(true)}
                  ></Button>
                </div>
              </div>
            )}
            {!props.waitImageUrl && (
              <UploadAvatarButton onDrop={openImageModal} />
            )}
          </div>
        </div>
        <div className="uploadContainer">
          <IonLabel className="label">Programblad</IonLabel>
          <div className="uploadButtonWrapper">
            <UploadPdfButton
              onDrop={onUploadPdf}
              isUploaded={props.ceremonyAgendaUrl ? true : false}
              clearPdfFile={clearPdfFile}
              downloadFile={downloadPdf}
            />
          </div>
        </div>
      </div>
      <ImageEditorModal
        isOpen={isImageEditorModal}
        onDidDismiss={() => setImageEditorModal(false)}
        imageFile={imageFile}
        onSuccess={successImageCrop}
      />
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        message={"Är du säkert radera bilden?"}
        buttons={[
          {
            text: "Ja",
            role: "confirm",
            handler: () => clearImage(),
          },
          {
            text: "Nej",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>
    </BeforeActiveLiveStreamStyleWrapper>
  );
};

const BeforeActiveLiveStreamStyleWrapper = styled.div`
  .pageLabel {
    font-weight: 900;
  }
  .beforeActiveLiveStream {
    display: flex;
    column-gap: 20px;
    .ImageContainer {
      margin-top: 10px;
      .imageWrapper {
        min-width: 200px;
        min-height: 200px;
        max-width: 300px;
        max-height: 300px;
        margin-top: 10px;
        border: 1px solid var(--ion-color-light-shade);
        border-radius: 3px;
        position: relative;
        .imageBlock {
          position: relative;
          .img {
            width: 100%;
            height: 100%;
          }
          .imageFooter {
            width: 100%;
            height: 50px;
            position: absolute;
            bottom: -8px;
            background-color: #ffffff78;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
        .uploadBtn {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .uploadContainer {
      margin-top: 10px;
      .uploadButtonWrapper {
        margin-top: 10px;
      }
    }
  }

  .label {
    font-weight: bold;
  }
`;

const UploadAvatarButton: React.FC<{
  onDrop?: (file: IImageFile) => void;
}> = (props) => {
  const [error, setError] = React.useState<string | null>(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const mimeTypes = ["image/jpeg", "image/png"];
    const incorrectMimetype = mimeTypes.indexOf(file.type) === -1;

    if (incorrectMimetype) {
      setError("kunde inte ladda upp. filtyp är felaktig");
      return;
    }

    const houndedKb = 1 * 1024;
    const maxSize = 20 * 1024 * 1024;
    const incorrectFileSize = file.size < houndedKb || file.size > maxSize;

    if (incorrectFileSize) {
      setError("bildstorlek är ogiltigt");
      return;
    }

    if (props.onDrop) {
      const imageSelectDialogFile: IImageFile = {
        fileFormInput: file,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        size: file.size,
        path: file.path,
        preview: URL.createObjectURL(file),
      };
      props.onDrop(imageSelectDialogFile);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        iconDir={"start"}
        icon={addCircleOutline}
        className={"uploadBtn"}
        variants={"secondary_noLine"}
      >
        Ladda upp
      </Button>
      <IonNote style={{ color: "red", fontSize: "14px" }}>{error}</IonNote>
    </div>
  );
};



export default BeforeActiveLiveSteam;
