import React from "react";
import styled from "styled-components";
import { IonIcon, IonItem, IonText } from "@ionic/react";
import { GetOrderById_orders_mediaOrder_edit_userInterface } from "../../schema";
import cx from "classnames";
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";

enum EditUIEditStatusColor {
  BLACK = "BLACK",
  BLUE = "BLUE",
  YELLOW = "YELLOW",
  RED = "RED",
  GREEN = "GREEN",
}

enum EditUIEditStatusIcon {
  NONE = "NONE",
  LOCKED = "LOCKED",
  UNLOCKED = "UNLOCKED",
}

const UserInterFace: React.FC<{
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined;
}> = (props) => {
  const { userInterface } = props;

  return (
    <UserInterFaceStyleWrapper>
      <IonItem
        className={cx("interfaceWrapper", {
          black: userInterface?.editStatusColor === EditUIEditStatusColor.BLACK,
          blue: userInterface?.editStatusColor === EditUIEditStatusColor.BLUE,
          yellow:
            userInterface?.editStatusColor === EditUIEditStatusColor.YELLOW,
          red: userInterface?.editStatusColor === EditUIEditStatusColor.RED,
          green: userInterface?.editStatusColor === EditUIEditStatusColor.GREEN,
        })}
        lines={"none"}
        fill={"solid"}
        shape={"round"}
        slot={"end"}
      >
        {userInterface?.editStatusIcon !== EditUIEditStatusIcon.NONE &&
          userInterface?.editStatusIcon === EditUIEditStatusIcon.LOCKED && (
            <IonIcon
              className={"icon"}
              icon={lockClosedOutline}
              color={"light"}
              slot={"start"}
            />
          )}
        {userInterface?.editStatusIcon !== EditUIEditStatusIcon.NONE &&
          userInterface?.editStatusIcon === EditUIEditStatusIcon.UNLOCKED && (
            <IonIcon
              className={"icon"}
              icon={lockOpenOutline}
              color={"light"}
              slot={"start"}
            />
          )}

        <IonText color={"light"} slot={"end"} className="desc">
          {userInterface?.editStatusText}
        </IonText>
      </IonItem>
    </UserInterFaceStyleWrapper>
  );
};

const UserInterFaceStyleWrapper = styled.div`
  .interfaceWrapper {
    margin: 5px;
    border-radius: 3px;
    width: auto;
    &.black {
      --background: var(--ion-color-custom-dark);
    }
    &.blue {
      --background: var(--ion-color-tertiary);
    }
    &.yellow {
      --background: var(--ion-color-warning);
    }
    &.red {
      --background: var(--ion-color-danger);
    }
    &.green {
      --background: var(--ion-color-success);
    }
    .desc {
      font-size: 13px;
    }
  }
`;

export default UserInterFace;
