import React from "react";
import WindowModal from "../WindowModal";
import { IonIcon, IonRange } from "@ionic/react";
import { imageOutline, refreshCircle } from "ionicons/icons";
import styled from "styled-components";
import { IImageFile } from "../../pages/OrdersPage/OrderContainer/MemoryRoom/ContentInputs";
import AvatarEditor from "react-avatar-editor";
import Button from "../Button";

const ImageEditor: React.FC<{
  isOpen: boolean;
  onDidDismiss: () => void;
  imageFile: IImageFile | null;
  onSuccess: (file: any) => void
}> = (props) => {
  const avatarEditorRef = React.useRef<any>()
  const [scale, setScale] = React.useState<number>(1);
  const [rotate, setRotate] = React.useState<number>(0);

  const zoomIn = () => {
    let newScale = scale - 0.1;
    if (newScale <= 1) {
      newScale = 1;
    }
    setScale(newScale);
  };
  const zoomOut = () => {
    let newScale = scale + 0.1;
    if (newScale >= 4) {
      newScale = 4;
    }
    setScale(newScale);
  };

  const rotateLeft = () => {
    let newRotate = rotate + 90;
    if (newRotate === 360) {
      newRotate = 0;
    }
    setRotate(newRotate);
  };
  const rotateRight = () => {
    let newRotate = rotate - 90;
    if (newRotate === -360) {
      newRotate = 0;
    }
    setRotate(newRotate);
  };

  const onRangeChange = (value: any) => {
    setScale(value);
  };

  const onSave = (event?: any) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    if (
      avatarEditorRef !== undefined &&
      avatarEditorRef !== null &&
      avatarEditorRef.current !== undefined &&
      avatarEditorRef.current !== null
    ) {
      const canvasScaled = (avatarEditorRef.current as any).getImageScaledToCanvas()
      canvasScaled.toBlob((blob: any) => {
        props.onSuccess(blob)
        props.onDidDismiss()
      })
    }
    return null
  }

  return (
    <WindowModal isOpen={props.isOpen} onDidDismiss={props.onDidDismiss}>
      <ImageModalStyleWrapper>
        <div className="imageWrapper">
          <AvatarEditor
            ref={avatarEditorRef}
            image={props.imageFile?.preview}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={rotate}
          />
          <div className="ImageEditorTools">
            <IonRange
              className="range"
              min={1}
              max={5}
              step={0.1}
              onIonChange={(e) => onRangeChange(e.detail.value)}
              value={scale}
              color={"secondary"}
            >
              <IonIcon
                className="startIcon"
                color={"primary"}
                slot="start"
                icon={imageOutline}
                onClick={zoomIn}
              />
              <IonIcon
                className="endIcon"
                color={"primary"}
                slot="end"
                icon={imageOutline}
                onClick={zoomOut}
              />
            </IonRange>
            <div className="rotateWrapper">
              <IonIcon
                color={"primary"}
                className="icon rotateLeft"
                icon={refreshCircle}
                onClick={rotateLeft}
              />
              <IonIcon
                color={"primary"}
                className="icon rotateRight"
                icon={refreshCircle}
                onClick={rotateRight}
              />
            </div>
          </div>
        </div>
        <div className="buttonWrapper">
          <Button onClick={onSave} variants={"secondary"}>Spara</Button>
          <Button onClick={props.onDidDismiss} variants={"secondary_outline"}>Avbryta</Button>
        </div>
      </ImageModalStyleWrapper>
    </WindowModal>
  );
};

const ImageModalStyleWrapper = styled.div`
  padding: 0 10px;
  .imageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ImageEditorTools {
      padding: 5px;
      display: flex;
      align-items: center;
      .range {
        .startIcon {
          font-size: 20px;
        }
        .endIcon {
          font-size: 30px;
        }
      }
    }
    .rotateWrapper {
      .icon {
        font-size: 35px;
      }
      .rotateLeft {
        transform: scaleX(-1);
      }
    }
  }
  .buttonWrapper {
    padding: 10px 0;
    display: flex;
    justify-content: center;


  }
`;

export default ImageEditor;
