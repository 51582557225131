import React from "react";
import {
  IonFab,
  IonFabButton,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import Button from "../../../components/Button";
import {
  EditModelTypes,
  GetAvailablePublishTimes,
  GetAvailablePublishTimesVariables,
  GetAvailablePublishTimes_mediaPublishCalendarDays,
  GetOrderById,
  GetOrderByIdVariables,
  GetOrderById_orders_alerting,
  GetOrderById_orders_mediaOrder_edit_userInterface,
  GetOrderById_orders_mediaOrder_preview,
  GetOrderById_orders_mediaOrder_status,
  PublishOrdersVariables,
  UpdatePublishTime,
  UpdatePublishTimeVariables,
} from "../../../schema";
import { readerOutline } from "ionicons/icons";
import styled from "styled-components";
import useWindowSize from "../../../useHook/useWindowSize";
import { standardMobileScreenSize } from "../../../utilities";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { UPDATE_PUBLISH_TIME } from "../../../graphql/mutation/updatePublishTime";
import moment from "moment";
import ErrorAlert from "../../../components/ErrorAlert";
import { useParams } from "react-router";
import { IParams } from "../../../custom.Interface";
import DetailsBlock from "./DetailsBlock";
import { PublishOrder } from "../../PublishPage";
import { PUBLISH_ORDER } from "../../../graphql/mutation/publishOrders";
import { FormikProps } from "formik";
import { I_FormikValue } from "../OrderContainer/MemoryRoom";
import RcDatePicker from "../../../components/RcDatePicker";
import { GET_AVAILABLE_PUBLISH_TIMES } from "../../../graphql/queries/getAvailablePublishTimes";
import GET_ORDER_BY_ID from "../../../graphql/queries/getOrderById";
import produce from "immer";
import WindowModal from "../../../components/WindowModal";

const FooterToolbarContainer: React.FC<{
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined;
  publishTime: any;
  alert: GetOrderById_orders_alerting | undefined;
  orderFriendlyId: string | undefined;
  preview: GetOrderById_orders_mediaOrder_preview | undefined;
  orderId: string | null | undefined;
  isLocked: boolean;
  documentId: string | null | undefined;
  status: GetOrderById_orders_mediaOrder_status | undefined;
  editModelTypes: EditModelTypes | undefined;
  formik?: FormikProps<I_FormikValue>;
}> = (props) => {
  const router = useIonRouter();
  const client = useApolloClient();
  const params: IParams = useParams();
  const [isExpanded, setExpanded] = React.useState<boolean>(false);
  const { width } = useWindowSize();
  const isTabletView = width > standardMobileScreenSize;
  const FORMAT = "yyyy-MM-DD";
  const [loadingPublishOrder, setLoadingPublishOrder] =
    React.useState<boolean>(false);
  const { ADVERT, MEMORY_ROOM, LIVE_STREAM } = EditModelTypes;
  const [isPublishTimeModal, setPublishTimeModal] =
    React.useState<boolean>(false);
  // const [availableDates, setAvailableDates] =
  //   React.useState<GetAvailablePublishTimes_mediaPublishCalendarDays[]>();
  // const todayDate = moment().subtract(1, "days").format(FORMAT);

  const orderHeight = props.preview?.material.basePage.size?.height;
  const orderWidth = props.preview?.material.basePage.size?.width;
  const orderPrice = props.preview?.price?.endCustomer.pricePay;
  const orderCurrency = props.preview?.price?.currency;

  const { data } = useQuery<
    GetAvailablePublishTimes,
    GetAvailablePublishTimesVariables
  >(GET_AVAILABLE_PUBLISH_TIMES, {
    client,
    variables: {
      orderId: props.orderId,
    },
  });

  const availableBookingTimes =
    data &&
    data.mediaPublishCalendarDays &&
    data.mediaPublishCalendarDays.filter((availableDate) => {
      // const formatAvailableDate = moment(availableDate.timeDay).format(FORMAT);
      if (availableDate.isEnabled) return availableDate;
    });

  const [mutatePublishTime, { loading, error }] = useMutation<
    UpdatePublishTime,
    UpdatePublishTimeVariables
  >(UPDATE_PUBLISH_TIME, {
    client,
  });

  const [mutatePublishOrder] = useMutation<
    PublishOrder,
    PublishOrdersVariables
  >(PUBLISH_ORDER, {
    context: {
      debounceKey: "publishOrderMemoryRoom",
      debounceTimeout: 500,
    },
  });

  const handleFooterVisible = () => setExpanded(true);

  const handleFooterInVisible = () => setExpanded(false);

  const handleMoveToPublishPage = () => {
    router.push(
      `/publishOrder/c/${params.caseFolderId}/d/${props.documentId}/o/${params.orderId}`
    );
  };

  const selectedAvailableDate = (value: Date) => {
    const selectedAvailableBookingTime =
      availableBookingTimes &&
      availableBookingTimes?.find((availableDate) => {
        const selectedDate =
          moment(availableDate.timeDay).format(FORMAT) ===
          moment(value).format(FORMAT);
        if (!selectedDate) return;
        const date = new Date(availableDate?.timeDay);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      });
    return selectedAvailableBookingTime;
  };

  function handleAdvertPublishDate(value: Date) {
    const selectedAvailableBookingTime = selectedAvailableDate(value);
    if (!loading)
      if (
        selectedAvailableBookingTime?.timeDay === null ||
        selectedAvailableBookingTime?.timeDay === undefined
      ) {
        setPublishTimeModal(true);
      } else {
        setPublishTimeModal(false);
        mutatePublishTime({
          variables: {
            input: {
              orderId: props.orderId!,
              publishTimes: [selectedAvailableBookingTime!.timeDay],
            },
          },
          update: (cache, result) => {
            const query = GET_ORDER_BY_ID;
            const variables = {
              id: props.orderId!,
            };
            const cacheRead = cache.readQuery<
              GetOrderById,
              GetOrderByIdVariables
            >({
              query,
              variables,
            });

            if (cacheRead && result) {
              const _data = produce(cacheRead, (draft) => {
                if (
                  draft.orders[0].mediaOrder &&
                  draft.orders[0].mediaOrder.preview
                ) {
                  draft.orders[0].mediaOrder.preview =
                    (result.data?.updateOrderPublishTimes.order.mediaOrder
                      ?.preview as any) ?? {};
                }
                if (
                  draft.orders[0].mediaOrder &&
                  draft.orders[0].mediaOrder.publishTimes
                ) {
                  draft.orders[0].mediaOrder.publishTimes =
                    (result.data?.updateOrderPublishTimes.order.mediaOrder
                      ?.publishTimes as any) ?? {};
                }
              });

              cache.writeQuery({
                query,
                variables,
                data: _data,
              });
            }
          },
        });
      }
  }

  const handleOrderPublish = (date: Date) => {
    mutatePublishTime({
      variables: {
        input: {
          orderId: props.orderId!,
          publishTimes: [date],
        },
      },
    });
  };

  const publishMemoryRoom = () => {
    setLoadingPublishOrder(true);
    if (props.formik!.isValid) {
      mutatePublishOrder({
        variables: {
          input: {
            orders: [
              {
                orderId: props.orderId!,
              },
            ],
          },
        },
      }).then((result) => {
        if (result.data) {
          setLoadingPublishOrder(false);
        }
      });
    }
  };

  const publishLiveStream = () => {
    setLoadingPublishOrder(true);
    mutatePublishOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId!,
            },
          ],
        },
      },
    }).then((result) => {
      if (result.data) {
        setLoadingPublishOrder(false);
      }
    });
  };

  return (
    <FooterToolbarContainerStyleWrapper>
      {!isExpanded && (
        <IonFab
          className="fadButton"
          vertical="bottom"
          horizontal="end"
          edge
          slot="fixed"
        >
          <IonFabButton className="button" onClick={handleFooterVisible}>
            <IonIcon color={"secondary"} icon={readerOutline} />
          </IonFabButton>
        </IonFab>
      )}
      {isExpanded && (
        <IonFooter className="footer">
          <DetailsBlock
            handleCloseExpandedDiv={handleFooterInVisible}
            orderFriendlyId={props.orderFriendlyId}
            editModelTypes={props.editModelTypes}
            orderHeight={orderHeight}
            orderWidth={orderWidth}
            orderCurrency={orderCurrency}
            orderPrice={orderPrice}
            status={props.status}
          />
          <div className="publishWrapper">
            <form className="footerForm">
              <div className="FooterFormInputWrapper">
                <IonLabel>{isTabletView && "Införande"}</IonLabel>

                {/* 
              /---------------------------------------/
              TODO 2022-7-25
              - disabled in valid Dates 
              - show booking stop  
              /---------------------------------------/
              */}

                {props.editModelTypes === ADVERT && (
                  <RcDatePicker
                    className="footWrapperInput"
                    isDisabled={props.isLocked}
                    isLoading={loading}
                    value={
                      props.publishTime
                        ? moment(props.publishTime).format(FORMAT)
                        : ""
                    }
                    onChange={(e) =>
                      handleAdvertPublishDate(new Date(e.target.value))
                    }
                    showDefaultButtons={true}
                  />
                )}

                {/* ----------------------------- */}
                {/*------ memoryRoom calender ------*/}
                {/* ----------------------------- */}

                {props.editModelTypes === MEMORY_ROOM && (
                  <RcDatePicker
                    className="footWrapperInput"
                    isDisabled={props.isLocked}
                    isLoading={loading}
                    value={
                      props.publishTime
                        ? moment(props.publishTime).format(FORMAT)
                        : ""
                    }
                    onChange={(e) =>
                      handleOrderPublish(new Date(e.target.value))
                    }
                    showDefaultButtons={true}
                  />
                )}

                {/* ----------------------------- */}
                {/*---- LiveStream calender    ---*/}
                {/* ----------------------------- */}

                {props.editModelTypes === LIVE_STREAM && (
                  <RcDatePicker
                    presentation={"date-time"}
                    className="footWrapperInput"
                    isDisabled={props.isLocked}
                    isLoading={loading}
                    value={
                      props.publishTime
                        ? moment(props.publishTime).format(FORMAT + "THH:mm")
                        : ""
                    }
                    onChange={(e) =>
                      handleOrderPublish(new Date(e.target.value))
                    }
                    showDefaultButtons={true}
                  />
                )}
              </div>

              {/* ----------------------------- */}
              {/*---- advert button ---*/}
              {/* ----------------------------- */}
              {props.userInterface?.publishButtonText &&
                props.editModelTypes === ADVERT && (
                  <Button
                    className="publishButton"
                    variants={"secondary_outline"}
                    onClick={handleMoveToPublishPage}
                  >
                    <span> {props.userInterface?.publishButtonText}</span>
                  </Button>
                )}
              {/* ----------------------------- */}
              {/*---- memmoryRoom buttom ---*/}
              {/* ----------------------------- */}
              {props.userInterface?.publishButtonText &&
                props.editModelTypes === MEMORY_ROOM && (
                  <Button
                    className="publishButton"
                    variants={"secondary_outline"}
                    onClick={publishMemoryRoom}
                    isLoading={loadingPublishOrder}
                  >
                    <span>{props.userInterface?.publishButtonText}</span>
                  </Button>
                )}
              {/* ----------------------------- */}
              {/*---- LiveStream buttom ---*/}
              {/* ----------------------------- */}
              {props.userInterface?.publishButtonText &&
                props.editModelTypes === LIVE_STREAM && (
                  <Button
                    className="publishButton"
                    variants={"secondary_outline"}
                    onClick={publishLiveStream}
                    isLoading={loadingPublishOrder}
                  >
                    <span>{props.userInterface?.publishButtonText}</span>
                  </Button>
                )}
            </form>
          </div>

          <PublishTimeModal
            isOpen={isPublishTimeModal}
            doClose={() => setPublishTimeModal(false)}
            availableDates={availableBookingTimes}
            handleAdvertPublishDate={handleAdvertPublishDate}
          />
          {error && (
            <ErrorAlert
              title={error?.name}
              subTitle={error.message}
              msg={error.extraInfo}
            />
          )}
        </IonFooter>
      )}
    </FooterToolbarContainerStyleWrapper>
  );
};

const FooterToolbarContainerStyleWrapper = styled.div`
  .fadButton {
    width: auto;
    height: auto;
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 10;
    .button {
      --background: white;
      --background-activated: white;
    }
  }

  .footer {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    box-shadow: 5px -15px 11px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    height: auto;
    z-index: 10;
    .publishWrapper {
      background-color: white;
      /* border-top: 1px solid var(--ion-color-light-shade); */
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;
      .showDetailsBtn {
        font-size: 15px;
      }
      .footerForm {
        display: flex;
        align-items: center;
        .FooterFormInputWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 5px;
          .footerWrapperLabel {
            font-weight: bold;
            font-size: 14px;
          }
          .footWrapperInput {
            /* width: 200px; */
            /* margin: 3px; */
          }
        }
        .publishButton {
          height: 37px;
          margin-left: 5px;
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
`;

const PublishTimeModal: React.FC<{
  isOpen: boolean;
  doClose: () => void;
  availableDates:
    | GetAvailablePublishTimes_mediaPublishCalendarDays[]
    | undefined;
  handleAdvertPublishDate: (date: Date) => void;
}> = (props) => {
  return (
    <WindowModal isOpen={props.isOpen} onDidDismiss={props.doClose}>
      <PublishTimeModalStyleWrapper>
        <IonToolbar>
          <IonHeader>
            <IonNote color="dark">
              Datum som du har valt är inte tillgängligt.
            </IonNote>
          </IonHeader>
        </IonToolbar>

        <IonList>
          <IonItem>
            <IonSelect
              placeholder="Tillgängliga datum"
              okText="Välj"
              cancelText="Stäng"
              interface={"alert"}
              onIonChange={(e) => {
                props.handleAdvertPublishDate(new Date(e.detail.value));
                props.doClose();
              }}
            >
              {props.availableDates &&
                props.availableDates.map((date, index) => {
                  return (
                    <IonSelectOption key={index} value={date.timeDay}>
                      {moment(date.timeDay).format("yyy-MM-DD")}
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </IonItem>
        </IonList>
      </PublishTimeModalStyleWrapper>
    </WindowModal>
  );
};

const PublishTimeModalStyleWrapper = styled.div`
  padding: 10px;
`;
export default FooterToolbarContainer;

// mutatePublishTime({
//   variables: {
//     input: {
//       orderId: props.orderId!,
//       publishTimes: [selectedAvailableBookingTime.timeDay],
//     },
//   },
//   update: (cache, result) => {
//     const query = GET_ORDER_BY_ID;
//     const variables = {
//       id: props.orderId!,
//     };
//     const cacheRead = cache.readQuery<
//       GetOrderById,
//       GetOrderByIdVariables
//     >({
//       query,
//       variables,
//     });

//     if (cacheRead && result) {
//       const _data = produce(cacheRead, (draft) => {
//         if (
//           draft.orders[0].mediaOrder &&
//           draft.orders[0].mediaOrder.preview
//         ) {
//           draft.orders[0].mediaOrder.preview =
//             (result.data?.updateOrderPublishTimes.order.mediaOrder
//               ?.preview as any) ?? {};
//         }
//         if (
//           draft.orders[0].mediaOrder &&
//           draft.orders[0].mediaOrder.publishTimes
//         ) {
//           draft.orders[0].mediaOrder.publishTimes =
//             (result.data?.updateOrderPublishTimes.order.mediaOrder
//               ?.publishTimes as any) ?? {};
//         }
//       });

//       cache.writeQuery({
//         query,
//         variables,
//         data: _data,
//       });
//     }
//   },
// });
