import gql from "graphql-tag";

export const PUBLISH_ORDER = gql`
  mutation PublishOrders($input: MutationPublishOrdersInput!) {
    publishOrders(input: $input) {
      isError
      isAddedMemoryRoom
      isPublishMemoryRoom
      isPublishMemoryRoomError
      publishMemoryRoomErrorText
      resultText
      resultIcon
      errorReason
      resultOrders {
        success
        resultIcon
        resultText
        order {
          id
          mediaOrder {
            edit {
              userInterface {
                editStatusIcon
                editStatusText
                publishButtonText
              }
            }
            isLocked
            status {
              color
              status
            }
            publishConversation {
              isBookingSent
            }
          }
        }
      }
    }
  }
`;
