import React from "react";
import RcInput from "../../../../../../../../../components/RcInput";
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines } from "../../../../../../../../../schema";
import { v4 as uuidv4 } from "uuid";
import { IonList, SelectChangeEventDetail } from "@ionic/react";
import RelativeDropdown from "./RelativeDropdown";
import styled from "styled-components";
import { useContextSelector } from "use-context-selector";
import { AreasContext } from "../../..";
import produce from "immer";
import {type Swiper as SwiperRef } from 'swiper'
type Line =
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_text_lines;

interface ILine extends Line {
  id?: string;
}
const LineEditorWrapper: React.FC<{
  lines: (Line | null)[];
  swpRef: React.MutableRefObject<SwiperRef | null>
}> = (props) => {
  const generatedID = uuidv4;
  const [lines, setLines] = React.useState<ILine[] | null>(null);
  const { paragraphClass, onLinesUpdate } = useContextSelector(
    AreasContext,
    (v) => ({
      paragraphClass: v?.paragraphClasses,
      onLinesUpdate: v?.onLinesUpdate,
    })
  );

  React.useEffect(() => {
    if (props.lines) {
      const initLine = initialLines();
      setLines(initLine);
    }
  }, [props.lines]);

  const initialLines = () =>
    props.lines.map((line) => ({
      id: generatedID(),
      class: line?.class!,
      text: line?.text!,
    }));

  const handleParagraphClassesSelect = (
    e: CustomEvent<SelectChangeEventDetail>,
    id: string
  ) => {
    const updatedLines = produce(lines, (draft) => {
      draft!.find((line) => {
        if (line.id === id) {
          line.class = e.detail.value!;
        }
      });
    });
    setLines(updatedLines);
    updateContent(updatedLines!);
  };

  const handleRcInputChange = (val: string, id: string) => {
    const updateLine = produce(lines, (draft) => {
      draft?.find((line) => {
        if (line.id === id) {
          line.text = val!;
        }
      });
    });
    setLines(updateLine);
    updateContent(updateLine!);
  };

  const updateContent = (updatedLines: ILine[]) => {
    const lines = produce(updatedLines, (draft) => {
      draft.map((line) => {
        delete line.id;
      });
    });
    if (onLinesUpdate) {
      onLinesUpdate(lines);
    }
  };

  return (
    <LineEditorWrapperStyleWrapper>
      <IonList className="list" onTouchStart={() => {
        props.swpRef.current!.allowTouchMove = false
      }}
      onTouchEnd={() => {
        props.swpRef.current!.allowTouchMove = true
      }}
      >
        {lines &&
          lines.map((line, index) => {
            return (
              <div className="innerItem" key={index}>
                <RcInput
                  outSideClass="input"
                  value={line.text}
                  name={"line"}
                  onChange={(e) =>
                    handleRcInputChange(e.detail.value!, line.id!)
                  }
                  debounce={1000}
                />
                <div>
                  <RelativeDropdown
                    lineId={line.id!}
                    lineClass={line.class!}
                    paragraphClasses={paragraphClass!}
                    handleSelectChange={handleParagraphClassesSelect}
                  />
                </div>
              </div>
            );
          })}
      </IonList>
    </LineEditorWrapperStyleWrapper>
  );
};

const LineEditorWrapperStyleWrapper = styled.div`
  
  .list {
    height: 250px;
    overflow: auto;
    margin-bottom: 50px;
    .innerItem {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 120px;
    grid-template-rows: 1fr;
    .input {
      width: 100%;
      min-width: 100px;
      margin-right: 10px;
      .rcInput {
        text-align: left;
      }
    }
  }
  } 


  .item {
    --padding-start: 0;
    --padding-end: 0;
  }
`;

export default LineEditorWrapper;
