import React from "react";
import { useParams } from "react-router";
import { IParams } from "../../custom.Interface";
import GET_PUBLISH_ORDER from "../../graphql/queries/getPublishOrders";
import {
  GetPublishOrders,
  GetPublishOrdersVariables,
  GetPublishOrders_caseFolder_documents_orders,
  GetPublishOrders_caseFolder_documents_summary_price_endCustomer,
  PublishOrders,
  PublishOrdersVariables,
} from "../../schema";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {  IonContent, IonLabel, IonProgressBar } from "@ionic/react";
import ErrorAlert from "../../components/ErrorAlert";
import OrderRow from "./OrderRow";
import styled from "styled-components";
import Button from "../../components/Button";
import { notEmpty, omitProps } from "../../utilities";
import { PUBLISH_ORDER } from "../../graphql/mutation/publishOrders";
import Toastify from "../../components/Toastify";
import TipsBox from "../../components/TipsBox";
import { faHandPointUp } from "@fortawesome/free-regular-svg-icons";

const PublishPageContainer: React.FC = () => {
  const params: IParams = useParams();

  const { data, loading, error } = useQuery<
    GetPublishOrders,
    GetPublishOrdersVariables
  >(GET_PUBLISH_ORDER, {
    variables: {
      caseFolderId: params.caseFolderId,
      documentId: params.documentId,
    },
    context: {
      debounceKey: "publishPage",
      debounceTimeout: 750,
    },
  });

  return (
    <>
      {loading && <IonProgressBar type="indeterminate"></IonProgressBar>}
      <TipsBox
        position="publishPage"
        icon={faHandPointUp}
        description="Håll fingret på tidningsnamnet för den tidning du önskar boka in. Tidningen blir markerad med blått när den är vald"
      />

      {data &&
        data.caseFolder &&
        data.caseFolder.documents.map((d) => {
          // d && d.orders && d.orders.map((o) => <></>);
          return (
            <PublishPage
              key={d!.id}
              orders={d!.orders.filter(notEmpty)}
              totalPrice={d!.summary.price.endCustomer}
            />
          );
        })}

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </>
  );
};

export interface PublishOrderKeyValueMap {
  [id: string]: PublishOrder;
}
export interface ReducerAction {
  type: "initPublishOrders" | "updateValue" | "toggleSelect" | "checkError";
  payload?: any;
}

export interface PublishOrder {
  orderId: string;
  order: GetPublishOrders_caseFolder_documents_orders;
  isSelected: boolean;
  errors: string;
  mediaCustomerSubriberNumber: string;
  mediaSystemOrderNumber: string;
  codeLate: string;
  messageToMedia: string;
}

//-----------------------------//
//        child component      //
//-----------------------------//
const PublishPage: React.FC<{
  orders: GetPublishOrders_caseFolder_documents_orders[];
  totalPrice: GetPublishOrders_caseFolder_documents_summary_price_endCustomer;
}> = (props) => {
  const client = useApolloClient();
  const [isToast, setToast] = React.useState<boolean>(false);
  const [orders, dispatch] = React.useReducer(reducer, {});

  const [mutatePublishOrders, { loading, data, error }] = useMutation<
    PublishOrders,
    PublishOrdersVariables
  >(PUBLISH_ORDER, {
    client,
  });

  React.useEffect(() => {
    if (props.orders) dispatch({ type: "initPublishOrders" });
    if (data?.publishOrders.isPublishMemoryRoomError) setToast(true);
  }, [props.orders, data?.publishOrders]);

  function initPublishOrders() {
    return props.orders.reduce(
      (acc, order) => ({
        ...acc,
        [order.id!]: {
          orderId: order.id!,
          order,
          isSelected: false,
          errors: [],
          messageToMedia: "",
          codeLate: "",
          mediaSystemOrderNumber: "",
          mediaCustomerSubriberNumber: "",
        },
      }),
      {}
    );
  }

  function updateValue(state: PublishOrderKeyValueMap, action: ReducerAction) {
    const p = action.payload!;
    return {
      ...state,
      [p.id]: {
        ...state[p.id],
        [p.name]: p.newValue,
      },
    };
  }
  function toggleSelect(state: PublishOrderKeyValueMap, action: ReducerAction) {
    const p = action.payload!;
    return {
      ...state,
      [p.id]: {
        ...state[p.id],
        isSelected: !state[p.id].isSelected,
      },
    };
  }

  function checkError(state: PublishOrderKeyValueMap, action: ReducerAction) {
    const p = action.payload!;
    const order: PublishOrder = state[p.id];
    if (p.errors === order.errors) return state;
    return {
      ...state,
      [p.id]: {
        ...state[p.id],
        errors: p.errors,
      },
    };
  }

  function reducer(state: any, action: ReducerAction) {
    switch (action.type) {
      case "initPublishOrders":
        return initPublishOrders();
      case "updateValue":
        return updateValue(state, action);
      case "toggleSelect":
        return toggleSelect(state, action);
      case "checkError":
        return checkError(state, action);
      default:
        return state;
    }
  }

  const isSendButtonVisible = Object.keys(orders)
    .map((id) => orders[id].isSelected)
    .some((item) => item === true);

  const onSubmitSendOrder = () => {
    const inputOrders = Object.keys(orders)
      .map((orderId) => {
        let order: any = { ...orders[orderId] };
        if (order.messageToMedia === "") order.messageToMedia = null;
        if (order.mediaSystemOrderNumber === "")
          order.mediaSystemOrderNumber = null;
        if (order.mediaCustomerSubriberNumber === "")
          order.mediaCustomerSubriberNumber = null;
        if (order.codeLate === "") order.codeLate = null;

        return order;
      })
      .filter((po) => po.isSelected && !po.errors);

    mutatePublishOrders({
      variables: {
        input: {
          orders: omitProps(inputOrders, ["order", "isSelected", "errors"]),
        },
      },
    });
  };

  return (
    <IonContent className="bg-transparent">
      <PublishPageStyleWrapper>
        <OrderRow
          orders={orders && orders}
          dispatch={dispatch}
          resultOrder={data && data?.publishOrders.resultOrders}
        />

        <div className="bottomToolbar">
          {/* <div className="info">
            <IonLabel className="label">Färger beskrivning</IonLabel>
            <div>
              <IonChip  className="send">skickad</IonChip>
              <IonChip  className="selected">vald</IonChip>
            </div>
          </div> */}
          <div className="toolbar">
            {isSendButtonVisible ? (
              <Button
                onClick={onSubmitSendOrder}
                variants={"primary"}
                isLoading={loading}
              >
                Skicka
              </Button>
            ) : (
              <div className="fill"></div>
            )}
            <div className="totalPrise">
              <IonLabel className="priseLabel">Totalt :</IonLabel>
              <span>
                {props.totalPrice
                  ? Math.ceil(props.totalPrice.pricePay) + " SEK"
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>

        {data && (
          <Toastify
            isOpen={isToast}
            doClose={() => setToast(false)}
            color={"danger"}
            msg={data!.publishOrders.publishMemoryRoomErrorText!}
            isExitBtn={true}
          />
        )}
      </PublishPageStyleWrapper>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </IonContent>
  );
};

const PublishPageStyleWrapper = styled.div`
  .bottomToolbar {
    width: 100%;
    height: 75px;
    background-color: white;
    position: fixed;
    bottom: 0;
    box-shadow: 5px -15px 11px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .info {
      padding: 5px;
      display: flex;
      align-items: center;
      .label {
        font-size: 14px;
      }
      .send {
        --background: var(--ion-color-success);
        --color: var(--ion-color-custom-dark);
        font-size: 13px;
      }
      .selected {
        --background: var(--ion-color-secondary);
        --color: var(--ion-color-custom-dark);
        font-size: 13px;
      }
      .currentOrder {
        --background: var(--ion-color-primary-light);
        --color: var(--ion-color-custom-dark);
      }
    }

    .toolbar {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .totalPrise {
        font-size: 15px;
        .priseLabel {
          margin-right: 3px;
          font-weight: bold;
        }
      }
    }
  }
`;

export default PublishPageContainer;
