import React from "react";
import { IonImg, IonText, useIonRouter } from "@ionic/react";
import { PATH_LINK } from "../../App";
import logo from "./timecutLogo.png";
import styled from "styled-components";

const Logo: React.FC = () => {
  const route = useIonRouter();

  const onLogoClick = () => {
    route.push(`${PATH_LINK.DASHBOARD}`, "root");
  };

  return (
    <LogoStyleWrapper>
      <div className="logoWrapper">
        <IonImg onClick={onLogoClick} src={logo} className={"logo"} />
        <IonText className="logoText">
          TAPS <span>5</span>
        </IonText>
      </div>
    </LogoStyleWrapper>
  );
};

const LogoStyleWrapper = styled.div`
        .logoWrapper {
          display: flex;
          align-items: center;
          .logo {
            width: 150px;
            height: calc(100% - 25px);
            margin-left: 10px;
          }
          .logoText {
            font-size: 20px;
            font-weight: bold;
            /* font-style:  */
            text-transform: uppercase;
            margin-left: 10px;
            span {
              color: var(--ion-color-medium);
            }
          }
        }

`;

export default Logo;
