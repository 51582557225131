import React from "react";
import { useMutation } from "@apollo/client";
import Button from "../../../../../components/Button";
import UPDATE_CUSTOMER_DOCUMENT_CONTENT_INITIATOR_FROM_ORDER from "../../../../../graphql/mutation/updateCustomerDocumentInitiatorFromOrder";
import {
    CustomerScope,
  UpdateCustomerDocumentContentInitiatorFromOrder,
  UpdateCustomerDocumentContentInitiatorFromOrderVariables,
} from "../../../../../schema";
import { IonAlert } from "@ionic/react";
import ErrorAlert from "../../../../../components/ErrorAlert";

const ContentInitiator: React.FC<{
  orderId: string | undefined | null;
  InitiatorText: string | null;
  dismissPopover: () => void
}> = (props) => {
    const { OFFICE } = CustomerScope
  const [alert, setAlert] = React.useState<boolean>(false);

  const [
    updateCustomerDocumentContentInitiatorFromOrderMutate,
    { loading, error },
  ] = useMutation<
    UpdateCustomerDocumentContentInitiatorFromOrder,
    UpdateCustomerDocumentContentInitiatorFromOrderVariables
  >(UPDATE_CUSTOMER_DOCUMENT_CONTENT_INITIATOR_FROM_ORDER);


  const handleUpdateContentInitiator = () => {
    props.dismissPopover()
    updateCustomerDocumentContentInitiatorFromOrderMutate({
        variables: {
            orderId: props.orderId!,
            customerScopeForDocumentContent: OFFICE,
            replaceLowerCustomerScopeInitiors: false
        }
    })
  }

  const confirmTitle = "spara som innehållsmall";
  const confirmText = "vill du spara annonsen som innehållsmall ?";
  return (
    <div>
      <Button
        // icon={bookmarkOutline}
        // iconDir={"start"}
        variants={"secondary_noLine"}
        onClick={() => setAlert(true)}
        isLoading={loading}
        className="button"
      >
        {props.InitiatorText}
      </Button>
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        header={confirmTitle}
        message={confirmText}
        buttons={[
          {
            text: "ja",
            role: "confirm",
            handler: () => {handleUpdateContentInitiator()},
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default ContentInitiator;
