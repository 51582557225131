import React from "react";
import { IonContent } from "@ionic/react";
import styled from "styled-components";
import { useContextSelector } from "use-context-selector";
import { OrderContext } from "..";
import AdvertEditModal from "./AdvertEditModal";
import Preview from "./Preview";
import {
  MutationUpdaterFn,
  useApolloClient,
  useMutation,
} from "@apollo/client";

import {
  UpdateOrderContent,
  UpdateOrderContentVariables,
} from "../../../../schema";
import ErrorAlert from "../../../../components/ErrorAlert";
import { omitTypename } from "../../../../utilities";
import { UPDATE_ORDER_CONTENT } from "../../../../graphql/mutation/updateOrderContent";
import FooterToolbarContainer from "../../FooterToolbarContainer";
import UserInterFace from "../../../../components/UserInterface";
import produce from "immer";
import { cloneDeep } from "@apollo/client/utilities";
import { AppContext } from "../../../../contexts/AppContextProvider";

// type IRow =
//   GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows;

const Advert: React.FC = () => {
  const orderContextValues = useContextSelector(OrderContext, (v) => v);
  const {dispatch} = useContextSelector(AppContext, (v) => ({
    dispatch: v?.dispatch,
  }))

  const client = useApolloClient();
  const [isOpenAreaModal, setIsOpenAreaModal] = React.useState<boolean>(false);
  const [selectedAreaId, setSelectedAreaId] = React.useState<string | null>(
    null
  );

  const [mutateUpdateOrderContent, { error, loading }] = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
  });

  function setLatestMutationVariables(
    value: UpdateOrderContentVariables | null | undefined
  ) {
    (window as any).latestMutationVariables = value;
  }
  function latestMutationVariables():
    | UpdateOrderContentVariables
    | null
    | undefined {
    return (window as any).latestMutationVariables;
  }

  const debug = true;

  const onUpdateCache: MutationUpdaterFn<UpdateOrderContent> = (
    cache,
    { data }
  ) => {
    /**
     * Changes Kenneth 20210510 this procedure force the output to be the input.
     *  This is crusial for get the editors work properly
     *  The problem that I try to solve here, is when  backend is requested,
     *  in the same time a debounce request are waiting to be requested.
     *  The result from backend in the first call, affect the changes made
     *  in the editors during the meantime it was request.
     *  So, for not making the result disturb editors, I force the outcome to be the lastest.
     */
    const latestVars = latestMutationVariables();
    if (latestVars && false) {
      const newData = produce(data, (draft) => {
        if (
          draft?.updateOrderContent?.order?.mediaOrder?.edit?.editModelAdvert
            ?.basePage?.areas
        ) {
          const areas: any =
            draft?.updateOrderContent.order.mediaOrder?.edit.editModelAdvert?.basePage.areas?.map(
              (draftArea) => {
                const areaId = draftArea?.content?.value?.id ?? null;

                if (areaId != null && draftArea?.content?.value) {
                  const latestArea =
                    latestVars?.input?.editModel?.editModelAdvertAreaContentValues?.filter(
                      (q) => q?.id === areaId
                    )?.[0] ?? null;
                  if (latestArea != null) {
                    const newAreaValue = cloneDeep(draftArea.content.value);
                    // newAreaValue.visible = latestArea.visible
                    newAreaValue.rows.forEach(
                      (rowItem: any, rowIndex: number) => {
                        rowItem.__typename = "EditModelAdvertAreaContentRow";
                        rowItem.columns.forEach(
                          (colItem: any, colIndex: number) => {
                            const lastestItem =
                              latestArea.rows[rowIndex].columns[colIndex]?.item;
                            if (lastestItem) {
                              if (colItem.item?.text)
                                colItem.item.text.plainText =
                                  lastestItem.text?.plainText; // "<p class=''>123</p>"
                            }
                          }
                        );
                      }
                    );

                    // if (debug) {
                    //   console.log(
                    //     "Cache update cache " +
                    //       newAreaValue?.rows[0].columns[0].item.text?.xmlText
                    //   )
                    // }

                    draftArea!.content.value = newAreaValue;
                  }
                }

                return cloneDeep(draftArea);
              }
            );
          draft.updateOrderContent.order.mediaOrder!.edit.editModelAdvert!.basePage.areas =
            areas ?? [];
        }
      });

      // if (debug) {
      //   console.log(newData)
      // }

      cache.writeQuery({ data: newData, query: UPDATE_ORDER_CONTENT });
    }
  };

  const handleUpdateOrder = (variables: UpdateOrderContentVariables) => {
    setLatestMutationVariables(variables);
    return new Promise(async (resolve, reject) => {
      try {
        const VariableWithoutTypeNames = omitTypename(variables);
        const response = await mutateUpdateOrderContent({
          variables: VariableWithoutTypeNames,
          update: onUpdateCache,
          context: {
            debounceKey: "updateAdvertContent",
            debounceTimeout: 850,
          },
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getAreaId = (id: string) => {
    setSelectedAreaId(id);
    onAreaModalOpen();
  };

  const onAreaModalOpen = () => {
    setIsOpenAreaModal(true)
    dispatch!({type: "SIDE_MENU_HANDLE", payload: {
      isMenuDisabled: true
    }})
  };
  const onAreaModalClose = () => {
    setIsOpenAreaModal(false);
    dispatch!({type: "SIDE_MENU_HANDLE", payload: {
      isMenuDisabled: false
    }})
  }


  return (
    <IonContent>
      <AdvertStyleWrapper>
        {orderContextValues?.userInterface!.editStatusText && (
          <div className="userInterfaceWrapper">
            <UserInterFace userInterface={orderContextValues?.userInterface!} />
          </div>
        )}
        <Preview
          img={orderContextValues?.img}
          areas={orderContextValues?.areas}
          getAreaId={getAreaId}
          isUpdateContentLoading={loading}
          selectedArea={selectedAreaId}
          handleCloseZoomRange={orderContextValues?.handleCloseZoomRange!}
          isZoomRangeVisible={orderContextValues?.isZoomRangeVisible!}
        />
        <AdvertEditModal
          isVisible={isOpenAreaModal}
          setIsVisible={setIsOpenAreaModal}
          doCloseModal={onAreaModalClose}
          selectedAreaId={selectedAreaId}
          areas={orderContextValues?.areas}
          orderId={orderContextValues?.orderId}
          handleUpdateOrder={handleUpdateOrder}
          isUpdateContentLoading={loading}
          getAreaId={getAreaId}
          mediaId={orderContextValues?.order.mediaOrder?.media.id!}
        />
      </AdvertStyleWrapper>

      {orderContextValues && orderContextValues.order && (
        <FooterToolbarContainer
          userInterface={
            orderContextValues?.order.mediaOrder?.edit.userInterface
          }
          publishTime={
            orderContextValues?.order.mediaOrder?.publishTimes[0]?.time!
          }
          alert={orderContextValues?.order.alerting}
          orderFriendlyId={orderContextValues?.order.customerFriendlyId}
          preview={orderContextValues?.order.mediaOrder?.preview}
          orderId={orderContextValues?.order.id}
          isLocked={orderContextValues?.order.mediaOrder?.isLocked!}
          documentId={orderContextValues.order.document.id}
          status={orderContextValues.order.mediaOrder?.status}
          editModelTypes={
            orderContextValues.order.mediaOrder?.edit.editModelType
          }
        />
      )}
      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </IonContent>
  );
};

const AdvertStyleWrapper = styled.div`
  margin-bottom: 100px;
  .userInterfaceWrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
`;

export default Advert;

// const latestMutationVariables = ():
//   | UpdateOrderContentVariables
//   | null
//   | undefined => {
//   return (window as any).latestMutationVariables;
// };

// const onUpdateCache: MutationUpdaterFn<UpdateOrderContent> = (
//   proxy,
//   { data }
// ) => {
//   try {
//     const latestVars = latestMutationVariables();
//     const readData = proxy.readQuery<GetOrderById>({
//       query: GET_ORDER_BY_ID,
//       variables: { id: data?.updateOrderContent.order.id },
//     });

//     const newData = produce(readData, (draft) => {
//       //orderObject
//       draft!.order.__typename = "Order";
//       const areas = cloneDeep(
//         draft?.order.mediaOrder?.edit.editModelAdvert?.basePage.areas!.map(
//           (area) => {
//             const areaId = area?.content.value.id;
//             const modifiedAreaValue =
//               latestVars?.input.editModel.editModelAdvertAreaContentValues!.filter(
//                 (value) => value?.id === areaId
//               );
//             if (modifiedAreaValue) {
//               // inject lastestVars in modified area
//               return produce(area, (draft) => {
//                 if (draft?.id === modifiedAreaValue![0]?.id) {
//                   if (
//                     area?.content.value.rows[0].columns[0].item.text !== null
//                   ) {
//                     (draft!.__typename = "EditModelAdvertArea"),
//                       (draft!.id =
//                         latestVars!.input.editModel
//                           .editModelAdvertAreaContentValues![0]!.id! ??
//                         areaId);
//                     // draft!.content.value.__typename =
//                     //   "EditModelAdvertAreaContentValue";
//                     draft!.content.value.id =
//                       latestVars!.input.editModel.editModelAdvertAreaContentValues![0]!.id!;
//                     // draft!.content.value.rows[0].columns[0].item.text!.__typename =
//                     //   "EditModelAdvertAreaContentItemText";
//                     draft!.content.value.rows[0].columns[0].item.text!.xmlText =
//                       latestVars!.input.editModel.editModelAdvertAreaContentValues![0]!.rows[0].columns[0].item.text?.xmlText!;
//                   }
//                 }
//               });
//             }

//             return modifiedAreaValue;
//           }
//         )
//       );

//       const modifiedOrderArea = produce(draft?.order, (draft: any) => {
//         draft!.mediaOrder!.edit!.editModelAdvert!.basePage.areas =
//           areas! ?? [];
//       });
//       return modifiedOrderArea as any;
//     });

//     proxy.writeQuery({
//       data: {
//         updateOrderContent: {
//           __typename: "MutationUpdateOrderContentResponse",
//           isError: false,
//           errorReason: null,
//           order: { ...newData },
//         },
//       },
//       query: UPDATE_ORDER_CONTENT,
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };
