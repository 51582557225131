import React from "react";
import Button from "../../../../components/Button";
import { trashOutline } from "ionicons/icons";
import { useMutation } from "@apollo/client";
import { DELETE_ORDER } from "../../../../graphql/mutation/deleteOrder";
import { DeleteOrder, DeleteOrderVariables } from "../../../../schema";
import { IonAlert } from "@ionic/react";
import ErrorAlert from "../../../../components/ErrorAlert";
import styled from "styled-components";

const OrderRemoveButton: React.FC<{
  orderId: string;
  isLocked: boolean;
  className?: string
}> = (props) => {
  const [alert, setAlert] = React.useState<boolean>(false);
  const [mutateDeleteOrder, { loading, error }] = useMutation<
    DeleteOrder,
    DeleteOrderVariables
  >(DELETE_ORDER);

  const handleOrderRemove = () => {
    if (!props.isLocked)
      mutateDeleteOrder({
        variables: {
          input: {
            orderIds: [props.orderId],
          },
        },
      });
  };

  const confirmTitle = "";
  const confirmText = props.isLocked
    ? "Denna order är låst."
    : "Vill du radera ordern ?";
  return (
    <OrderRemoveButtonStyleWrapper className={props.className}>
      <Button
        size={"small"}
        className="button"
        variants={"secondary_noLine"}
        icon={trashOutline}
        iconDir={"icon-only"}
        onClick={() => setAlert(true)}
      />

      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        header={confirmTitle}
        message={confirmText}
        buttons={
          !props.isLocked
            ? [
                {
                  text: "ja",
                  role: "confirm",
                  handler: () => handleOrderRemove(),
                },
                {
                  text: "Nej",
                  role: "cancel",
                  handler: () => {
                    setAlert(false);
                  },
                },
              ]
            : [
                {
                  text: "Stäng",
                  role: "cancel",
                  handler: () => {
                    setAlert(false);
                  },
                },
              ]
        }
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </OrderRemoveButtonStyleWrapper>
  );
};

const OrderRemoveButtonStyleWrapper = styled.div`
  .button {
    --padding-start: 0;
    --padding-end: 0;
  }

`

export default OrderRemoveButton;
