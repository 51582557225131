import gql from "graphql-tag"
import DASHBOARD_CASE_FOLDER_FRAGMENT from "../fragments/dashboardCaseFolderFragment"


const GET_DASHBOARD_CASE_FOLDERS = gql`
  query GetCaseFolders($touchCaseFolderIds:[ID!]) {
    findCaseFoldersByCurrentCustomer(filterOutNonActive: true, touchCaseFolderIds: $touchCaseFolderIds) {
      caseFolders {
        ...DashboardCaseFolderFragment
      }
    }
  }
  ${DASHBOARD_CASE_FOLDER_FRAGMENT}
`

export default GET_DASHBOARD_CASE_FOLDERS