import React from "react";
import Button from "../../../../../../../components/Button";
import { IonCardHeader, IonCardTitle } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";


const CardHeader: React.FC<{
  doCloseAreaModal: () => void;
  handleAreaInvisible: () => void;
  title: string;
  isVisible: boolean;
  isLocked: boolean;
  isLoading: boolean;
}> = ({
  doCloseAreaModal,
  title,
  // handleAreaInvisible,
  // isVisible,
  // isLocked,
  isLoading,
}) => {
  // const [isAlertVisible, setAlertVisible] = React.useState<boolean>(false);
  return (
    <IonCardHeader className="areaCardHeader" mode="ios">
      <div className="left">
        <Button
          className="backBtn"
          variants={"secondary_noLine"}
          onClick={doCloseAreaModal}
          icon={closeCircleOutline}
          iconDir={"icon-only"}
          size={"small"}
          isLoading={isLoading}
        />
        <IonCardTitle className="areaHeaderTitle">{title}</IonCardTitle>
      </div>
    </IonCardHeader>
  );
};

export default CardHeader;
