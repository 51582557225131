import React from "react";
import "./styles.css";
import {
  IonContent,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import PageHeader from "../PageHeader";

const PageContainer: React.FC = (props) => {
  const currentPathName = useIonRouter().routeInfo.pathname;

  return (
    <IonPage>
      {currentPathName !== "/login" && currentPathName !== "/archive" && (
        <PageHeader />
      )}
      <IonContent fullscreen className="background-image" scrollY={false}>
        {props.children}
      </IonContent>
    </IonPage>
  );
};

export default PageContainer;
