import React from "react";
import styled from "styled-components";
import { IonContent } from "@ionic/react";
import {
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  UpdateOrderContentVariables,
} from "../../../../../schema";
import AreaContentContainer from "./AreaContentContainer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { type Swiper as SwiperRef } from "swiper";
import cx from "classnames";
import TipsBox from "../../../../../components/TipsBox";

const AdvertEditModal: React.FC<{
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  doCloseModal: () => void;
  selectedAreaId: string | null;
  areas:
    | (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[]
    | null
    | undefined;
  orderId: string | null | undefined;
  handleUpdateOrder: (
    variables: UpdateOrderContentVariables
  ) => Promise<unknown>;
  isUpdateContentLoading: boolean;
  getAreaId: (arg: string) => void;
  mediaId: string
}> = (props) => {
  // const modal = React.useRef<HTMLIonModalElement>(null)
  const swiperRef = React.useRef<SwiperRef | null>(null);
  const visibleArea =
    props.areas && props.areas?.filter((area) => area?.content.value.visible);

  const selectedIndex =
    visibleArea &&
    visibleArea.findIndex((el, index) => {
      if (props.selectedAreaId === null && !el?.content.value.visible)
        return null;
      return props.selectedAreaId === el?.id && index + 1;
    });

  React.useEffect(() => {
    swiperRef.current?.slideTo(selectedIndex!, 500, true);
  }, [selectedIndex, props.isVisible]);

  return (
    <AdvertEditModalStyleWrapper>

      <div
        className={cx("advertEditorWrapper", {
          isInvisible: !props.isVisible,
        })}
      >
        <TipsBox
          className="tipsBoxAdvertEdit"
          position="advertEditModal"
          description={"-Svep åt sidan för att navigera mellan fälten. Ändringarna syns i annonsen medan du redigerar."}
          description2={"-Tryck på krysset när du vill stänga rutan."}
        />
        <IonContent
          className={"content"}
          scrollY={true}
        >
          <Swiper
            className="swiper"
            initialSlide={selectedIndex!}
            slidesPerView={1}
            direction={"horizontal"}
            speed={500}
            autoplay={false}
            onSwiper={(swp) => (swiperRef.current = swp)}
          >
            {visibleArea &&
              visibleArea.map((area, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="swiper-slide"
                    id={area?.id}
                  >
                    <AreaContentContainer
                      orderId={props.orderId}
                      mutateUpdateContent={props.handleUpdateOrder}
                      title={area!.header}
                      value={area!.content.value}
                      editor={area!.content.editor}
                      closeAreaEditModal={props.doCloseModal}
                      area={area}
                      doCloseAreaModal={props.doCloseModal}
                      isUpdateContentLoading={props.isUpdateContentLoading}
                      swpRef={swiperRef}
                      mediaId={props.mediaId}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </IonContent>

      </div>
    </AdvertEditModalStyleWrapper>
  );
};

const AdvertEditModalStyleWrapper = styled.div`
  .advertEditorWrapper {
    width: 100%;
    height: 100%;
    max-height: 400px;
    position: fixed;
    bottom: -5px;
    z-index: 20;
    animation: all 5s ease-out;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    .tipsBoxAdvertEdit {
      position: fixed;
      bottom: 360px;
    }
    &.isInvisible {
      display: none;
    }
    .content {
      --background: transparent;
    }
    .swiper {
      width: 100%;
      height: 100%;
    }
  }


  /* height: 100%;
  padding: 0;
  background-color: transparent;
  .swiper {
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    height: 390px;
  } */
`;

export default AdvertEditModal;
