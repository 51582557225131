import { IonIcon, IonImg, IonItem, IonItemGroup, IonLabel } from "@ionic/react";
import { checkmarkDoneOutline, copyOutline, openOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";
import { IQrTable } from "..";
import WindowModal from "../../../../../../components/WindowModal";
import { Clipboard } from "@capacitor/clipboard";





const QrModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    selectedQr: IQrTable;
  }> = (props) => {
    let [isCopy, setIsCopy] = React.useState<boolean>(false);
    const writeToClipboard = async (text: string) => {
      setIsCopy(true);
      await Clipboard.write({
        url: text,
        label: "data",
      });
      checkClipboard().then((result) => {
        if (result) {
          setTimeout(() => setIsCopy(false), 1000);
        }
      });
    };
  
    const checkClipboard = async () => {
      const { value } = await Clipboard.read();
      return value;
    };
  
    return (
      <WindowModal isOpen={props.isOpen} onDidDismiss={props.onClose}>
        <QrModalStyleWrapper>
          <div className="qrModal">
            <IonLabel className="label">
              QR-Kod: {props.selectedQr.qualityText}(
              <span>{props.selectedQr.qualityNr}</span>
              kbps)
            </IonLabel>
  
            <IonImg className="img" src={props.selectedQr.link} />
            <IonItemGroup>
              <IonItem
                lines="none"
                slot="start"
                onClick={() => writeToClipboard(props.selectedQr.link)}
              >
                {!isCopy ? (
                  <div className="copy">
                    <IonIcon icon={copyOutline} />
                    <span> Kopiera QR-Kod</span>
                  </div>
                ) : (
                  <div className="copy">
                    <IonIcon icon={checkmarkDoneOutline} />
                    <span> Kopierad</span>
                  </div>
                )}
              </IonItem>
              <IonItem
                lines="none"
                slot="end"
                href={`${props.selectedQr.larix}`}
                target={"_system"}
              >
                <div className="open">
                  <IonIcon icon={openOutline} />
                  <span>Öppna Larix</span>
                </div>
              </IonItem>
            </IonItemGroup>
          </div>
        </QrModalStyleWrapper>
      </WindowModal>
    );
  };
  
  const QrModalStyleWrapper = styled.div`
    .qrModal {
      padding: 10px;
      .label {
        font-weight: bold;
        padding: 10px;
        span {
          font-weight: normal;
        }
      }
      .img {
        width: 300px;
        height: 300px;
      }
      .copy,
      .open {
        color: var(--ion-color-secondary);
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  `;

  export default QrModal