import { useApolloClient, useQuery } from "@apollo/client";
import React from "react";
import { createContext } from "use-context-selector";
import ErrorAlert from "../../../components/ErrorAlert";
import GET_EDIT_ORDER_PAGE_CASE_FOLDER from "../../../graphql/queries/getEditOrderPageCaseFolder";
import {
  GetEditOrderPageCaseFolder,
  GetEditOrderPageCaseFolderVariables,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders,
  GetOrderById_orders,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom,
  GetOrderById_orders_mediaOrder_edit_userInterface,
} from "../../../schema";
import { notEmpty } from "../../../utilities";
import TabsSelect from "../TabsSelect";
import Advert from "./Advert";
import LiveStream from "./LiveStream";
import MemoryRoom from "./MemoryRoom";

export const OrderContext = createContext<IContextValue | undefined>(undefined);

const OrderContainer: React.FC<{
  orders: GetOrderById_orders[];
  isLoading: boolean;
  refetch: () => void;
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined;
  caseFolderId: string;
}> = (props) => {
  const client = useApolloClient();
  const [isZoomRangeVisible, setIsZoomRangeVisible] = React.useState<boolean>(false)

  const { data, error } = useQuery<
    GetEditOrderPageCaseFolder,
    GetEditOrderPageCaseFolderVariables
  >(GET_EDIT_ORDER_PAGE_CASE_FOLDER, {
    client,
    variables: {
      id: props.caseFolderId,
    },
  });


  const handleOpenZoomRange = () => setIsZoomRangeVisible(true)
  const handleCloseZoomRange = () => setIsZoomRangeVisible(false)


  const orders = data?.caseFolder.editTabs
  .map((doc: any) => doc.orders.map((x: any) => x))
  .reduceRight((acc, currVal) => acc.concat(currVal), [])
  .filter(notEmpty)


  return (
    <OrderContext.Provider
      value={{
        orderId: props.orders[0].id,
        refetch: props.refetch,
        areas: props.orders[0].mediaOrder?.edit.editModelAdvert?.basePage.areas,
        img: props.orders[0].mediaOrder?.preview.material!.pages[0]!.png.url,
        isLocked: props.orders[0].mediaOrder?.isLocked!,
        editModelMemoryRoom:
          props.orders[0].mediaOrder?.edit.editModelMemoryRoom,
        userInterface: props.userInterface,
        order: props.orders[0],
        caseFolderId: props.caseFolderId,
        orders: orders,
        handleCloseZoomRange: handleCloseZoomRange,
        isZoomRangeVisible: isZoomRangeVisible
      }}
    >
      {props.orders && (
        <TabsSelect
          editTabs={data?.caseFolder.editTabs!}
          caseFolderId={props.caseFolderId}
          orderId={props.orders[0].id!}
          isLocked={props.orders[0].mediaOrder?.isLocked!}
          userInterface={props.orders[0].mediaOrder?.edit.userInterface}
          publishTime={props.orders[0].mediaOrder?.publishTimes[0]?.time}
          editModelType={props.orders[0].mediaOrder?.edit.editModelType!}
          handleOpenZoomRange={handleOpenZoomRange}
        />
      )}
      {props.orders && props.orders[0].mediaOrder?.edit.editModelAdvert && (
        <Advert />
      )}
      {props.orders && props.orders[0].mediaOrder?.edit.editModelLiveStream && (
        <LiveStream />
      )}
      {props.orders && props.orders[0].mediaOrder?.edit.editModelMemoryRoom && (
        <MemoryRoom />
      )}

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </OrderContext.Provider>
  );
};

interface IContextValue {
  orderId: string | null;
  refetch: () => void;
  img: string | undefined;
  areas:
    | (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[]
    | null
    | undefined;
  isLocked: boolean;
  editModelMemoryRoom:
    | GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom
    | null
    | undefined;
  userInterface: GetOrderById_orders_mediaOrder_edit_userInterface | undefined;
  order: GetOrderById_orders;
  caseFolderId: string | null;
  orders: GetEditOrderPageCaseFolder_caseFolder_editTabs_orders[]
  handleCloseZoomRange: () => void
  isZoomRangeVisible: boolean
}

export default OrderContainer;
