import { useMutation } from "@apollo/client";
import { IonAlert } from "@ionic/react";
import React from "react";
import Button from "../../../../../components/Button";
import ErrorAlert from "../../../../../components/ErrorAlert";
import REACTIVE_ORDERS from "../../../../../graphql/mutation/reactiveOrder";
import GET_ORDER_BY_ID from "../../../../../graphql/queries/getOrderById";
import {
  ReActivateUnPublishOrders,
  ReActivateUnPublishOrdersVariables,
} from "../../../../../schema";

const ReactiveButton: React.FC<{
  orderId: string | null | undefined;
  reactiveButtonText: string | undefined | null;
  dismissPopover: () => void
}> = (props) => {
  const [alert, setAlert] = React.useState<boolean>(false);

  const [mutationReactiveOrder, { loading, error }] = useMutation<
    ReActivateUnPublishOrders,
    ReActivateUnPublishOrdersVariables
  >(REACTIVE_ORDERS, {
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID,
        variables: { id: props.orderId! },
      },
    ],
  });

  const handleReactiveOrder = () => {
    props.dismissPopover()
    mutationReactiveOrder({
      variables: {
        input: {
          orders: [
            {
              orderId: props.orderId!,
            },
          ],
        },
      },
    });
  };

  const confirmTitle = props.reactiveButtonText!;
  const confirmText = `Du är på väg att ${props.reactiveButtonText?.toLocaleLowerCase()}{" "}
  ordern vilken innebär att annonsen kommer att publisera igen. Vill
  du verkligen ${props.reactiveButtonText?.toLocaleLowerCase()} denna
  order ?`;

  return (
    <div>
      <Button
        variants={"secondary_noLine"}
        onClick={() => setAlert(true)}
        isLoading={loading}
        className="button"
      >
        {props.reactiveButtonText}
      </Button>
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        header={confirmTitle}
        message={confirmText}
        buttons={[
          {
            text: "ja",
            role: "confirm",
            handler: () => {
              handleReactiveOrder();
            },
          },
          {
            text: "Avbryta",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>

      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </div>
  );
};

export default ReactiveButton;
