import React from "react";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import styled from "styled-components";
import cx from "classnames";

interface I_InitButtonVariant {
  color: "primary" | "secondary" | "Tertiary" | "danger" | "gray";
  fill?: "clear" | "default" | "outline" | "solid";
}

interface IProps {
  onClick?: () => void;
  variants?:
    | "primary"
    | "secondary"
    | "secondary_outline"
    | "secondary_noLine"
    | "secondary_menu"
    | "danger-noLine"
    | "gray-noLine";
  icon?: string;
  iconDir?: "start" | "end" | "icon-only";
  type?: "button" | "reset" | "submit";
  size?: "default" | "large" | "small" | undefined;
  isLoading?: boolean;
  className?: string;
  isDisabled?: boolean;
  children?: React.ReactNode;
  id?: string;
}

const Button: React.FC<IProps> = (props) => {
  const initButtonVariant: I_InitButtonVariant = {
    color: "primary",
    fill: "solid",
  };

  if (props.variants === "secondary") {
    initButtonVariant.color = "secondary";
  }

  if (props.variants === "secondary_outline") {
    initButtonVariant.color = "secondary";
    initButtonVariant.fill = "outline";
  }

  if (props.variants === "secondary_noLine") {
    initButtonVariant.color = "secondary";
    initButtonVariant.fill = "clear";
  }

  if (props.variants === "secondary_menu") {
    initButtonVariant.color = "secondary";
    initButtonVariant.fill = "clear";
  }

  if (props.variants === "danger-noLine") {
    initButtonVariant.color = "danger";
    initButtonVariant.fill = "clear";
  }

  if (props.variants === "gray-noLine") {
    initButtonVariant.color = "gray";
    initButtonVariant.fill = "clear";
  }

  const isClearBg = initButtonVariant.fill !== "solid";

  return (
    <ButtonStyleWrapper>
      <IonButton
        id={props.id}
        className={cx(`btn ${props.className}`, {
          btn_primary: initButtonVariant.color === "primary",
          btn_secondary_outline: props.variants === "secondary_outline",
          btn_secondary_menu: props.variants === "secondary_menu",
          btn_gray_noLine: props.variants === "gray-noLine",
        })}
        type={props.type || "button"}
        color={
          initButtonVariant.color === "primary" ? "" : initButtonVariant.color
        }
        strong={true}
        size={props.size || "default"}
        fill={initButtonVariant.fill}
        onClick={props.onClick}
        disabled={props.isDisabled}
      >
        {props.isLoading && (
          <IonSpinner
            className="spinner"
            name="bubbles"
            color={isClearBg ? "secondary" : "light"}
          />
        )}

        {props.icon && !props.isLoading && (
          <IonIcon style={{fontSize: "25px"}} slot={props.iconDir || "start"} icon={props.icon} />
        )}

        {props.children}
      </IonButton>
    </ButtonStyleWrapper>
  );
};

const ButtonStyleWrapper = styled.div`
  .btn_primary {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
    --border-radius: 3px;
    --background: var(--ion-color-primary-shade);
  }
  .btn_secondary_outline {
    --border-width: 1px;
  }
  .btn_secondary_menu {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-light-shade);
  }
  .btn_gray_noLine {
    color: var(--ion-color-medium);
    &:active {
      color: var(--ion-color-secondary);
    }
  }

  .spinner {
    width: 15px;
    margin-right: 5px;
  }
`;

export default Button;
