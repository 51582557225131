import React from "react";
import { useFormik } from "formik";
import {
  InputChangeEventDetail,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer,
  GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard,
  UpdateCaseFolder,
  UpdateCaseFolderVariables,
} from "../../schema";
import RcInput from "../RcInput";
import styled from "styled-components";
import moment from "moment";
import Button from "../Button";
import * as Yup from "yup";
import { useApolloClient, useMutation } from "@apollo/client";
import UPDATE_CASE_FOLDER from "../../graphql/mutation/updateCaseFolder";
import ErrorAlert from "../ErrorAlert";
import RcDatePicker from "../RcDatePicker";
import { useContextSelector } from "use-context-selector";

import { standardMobileScreenSize } from "../../utilities";
import useWindowDimensions from "../../useHook/useWindowDimensions";
import cx from "classnames";
import { AppContext } from "../../contexts/AppContextProvider";

const CaseFolderForm: React.FC<{
  funeralProperties:
    | GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_propertiesFuneralStandard
    | null
    | undefined;
  doExit: () => void;
  documentId: string | null | undefined;
  customer:
    | GetCaseFolders_findCaseFoldersByCurrentCustomer_caseFolders_customer
    | null
    | undefined;
  modalRef: React.MutableRefObject<HTMLIonModalElement | null>;
  doOpen: () => void;
}> = (props) => {
  const { colleagues } = useContextSelector(AppContext, (v) => ({
    colleagues: v?.currentCustomer.customer!.colleagues,
  }));

  const { width } = useWindowDimensions();
  const isTablet = width >= standardMobileScreenSize;

  const { funeralProperties } = props;
  const FORMAT = "yyyy-MM-DD";
  const client = useApolloClient();

  const [mutateUpdateCaseFolder, { loading, error }] = useMutation<
    UpdateCaseFolder,
    UpdateCaseFolderVariables
  >(UPDATE_CASE_FOLDER, {
    client,
    context: {
      debounceKey: "caseFolderForm",
      debounceTimeout: 750,
    },
  });

  const initialValues: IInitialValues = {
    personFirstname: funeralProperties?.personFirstname!,
    personLastname: funeralProperties?.personLastname!,
    personBorn: funeralProperties?.personBorn!,
    personDied: funeralProperties?.personDied!,

    personBornLastname: funeralProperties?.personBornLastname!,
    personCityLive: funeralProperties?.personCityLive!,

    personFuneral: funeralProperties?.personFuneral!,
    memoryCeremonyLastBookingDay:
      funeralProperties?.memoryCeremonyLastBookingDay!,

    externalId: funeralProperties?.externalId!,

    customerId: props.customer?.id!,
  };

  const validationSchema = Yup.object().shape({
    personFirstname: Yup.string().required("obligatoriskt fält"),
    personLastname: Yup.string().required("obligatoriskt fält"),
    personBorn: Yup.date().required("obligatoriskt fält"),
    personDied: Yup.date()
      .when("personTimeBorn", (personTimeBorn, schema) => {
        let dayBefore = new Date(personTimeBorn);
        dayBefore.setDate(dayBefore.getDate() - 1);
        if (personTimeBorn) {
          return schema.min(
            new Date(dayBefore),
            "Dödsdag kan inte vara tidigare än födelsdag."
          );
        }
        return schema;
      })
      .required("obligatoriskt fält"),
  });

  const handleInputChange = (
    e: CustomEvent<HTMLIonInputElement | InputChangeEventDetail>,
    name: string
  ) => {
    const value = e.detail.value;
    formik.setFieldValue(name, value);
  };

  const handleDateInputChange = (
    e: CustomEvent<HTMLIonInputElement | InputChangeEventDetail>,
    name: string
  ) => {
    const value = e.detail.value;

    if ("personFuneral") formik.setFieldValue(name, moment(value!).toDate());
    else formik.setFieldValue(name, moment(value).format(FORMAT));
  };

  const updateContent = () => {
    const { values } = formik;
    if (formik.isValid) {
      mutateUpdateCaseFolder({
        variables: {
          input: {
            caseFolder: {
              propertiesFuneralStandard: {
                personFirstname: values.personFirstname!,
                personLastname: values.personLastname!,
                personBorn: moment(values.personBorn).format(FORMAT),
                personDied: moment(values.personDied).format(FORMAT),
                personBornLastname: values.personBornLastname,
                personCityLive: values.personCityLive,
                personFuneral: values.personFuneral
                  ? values.personFuneral
                  : null,
                memoryCeremonyLastBookingDay:
                  values.memoryCeremonyLastBookingDay
                    ? moment(values.memoryCeremonyLastBookingDay).format(FORMAT)
                    : null,
                externalId: values.externalId,
              },
              customerId: formik.values.customerId,
              id: props.documentId,
            },
          },
        },
      })
    }
  };

  
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: () => {},
  });


  React.useEffect(() => {
    if(!formik.isValid) props.modalRef.current!.swipeToClose = false
    else props.modalRef.current!.swipeToClose = true
  },[formik.isValid])




  props.modalRef.current?.onWillDismiss()
  .then(() => updateContent())

  

  return (
    <CaseFolderStyleWrapper>
      <form>
        <div
          className={cx("inputsWrapper", {
            isTabletInputsWrapper: isTablet,
          })}
        >
          <RcInput
            label={"Förnamn*"}
            placeholder={"Förnamn"}
            className={"input"}
            name={"personFirstname"}
            value={formik.values.personFirstname}
            onChange={(e) => handleInputChange(e, e.target.name)}
            errorMsg={formik.errors.personFirstname}
            isHorizontally={true}
          />
          <RcInput
            label={"Efternamn*"}
            placeholder={"Efternamn"}
            className={"input"}
            name={"personLastname"}
            value={formik.values.personLastname}
            onChange={(e) => handleInputChange(e, e.target.name)}
            errorMsg={formik.errors.personLastname}
            isHorizontally={true}
          />
          <RcInput
            label={"Födelsenamn"}
            placeholder={"Födelsenamn"}
            className={"input"}
            name={"personBornLastname"}
            value={formik.values.personBornLastname}
            onChange={(e) => handleInputChange(e, e.target.name)}
            errorMsg={formik.errors.personBornLastname}
            isHorizontally={true}
          />
          <RcInput
            label={"Hemstad"}
            placeholder={"Hemstad"}
            className={"input"}
            name={"personCityLive"}
            value={formik.values.personCityLive}
            onChange={(e) => handleInputChange(e, e.target.name)}
            errorMsg={formik.errors.personCityLive}
            isHorizontally={true}
          />
          <RcDatePicker
            label="Födelsedatum*"
            placeholder="Födelsedatum"
            className="dateInput"
            name={"personBorn"}
            value={
              formik.values.personBorn
                ? moment(formik.values.personBorn).format(FORMAT)
                : undefined
            }
            onChange={(e) => {
              handleDateInputChange(e, "personBorn");
            }}
            errorMsg={formik.errors.personBorn}
            isHorizontally={true}
          />
          <RcDatePicker
            label="Dödsdatum*"
            placeholder="Dödsdatum"
            className="dateInput"
            name={"personDied"}
            value={
              formik.values.personDied
                ? moment(formik.values.personDied).format(FORMAT)
                : undefined
            }
            onChange={(e) => {
              handleDateInputChange(e, "personDied");
            }}
            errorMsg={formik.errors.personDied}
            isHorizontally={true}
          />
          <RcDatePicker
            label="Begravningsdatum"
            placeholder="Begravningsdatum"
            className="dateInput"
            name={"personFuneral"}
            value={
              formik.values.personFuneral
                ? moment(formik.values.personFuneral).format(FORMAT + "THH:mm")
                : undefined
            }
            presentation={"date-time"}
            onChange={(e) => {
              handleDateInputChange(e, "personFuneral");
            }}
            errorMsg={formik.errors.personFuneral}
            isHorizontally={true}
            showDefaultButtons={true}
          />
          <RcDatePicker
            label="Anmälan till minnesstund"
            placeholder="Anmälan till minnesstund"
            className="dateInput"
            name={"memoryCeremonyLastBookingDay"}
            value={
              formik.values.memoryCeremonyLastBookingDay
                ? moment(formik.values.memoryCeremonyLastBookingDay).format(
                    FORMAT
                  )
                : undefined
            }
            onChange={(e) => {
              handleDateInputChange(e, "memoryCeremonyLastBookingDay");
            }}
            errorMsg={formik.errors.memoryCeremonyLastBookingDay}
            isHorizontally={true}
          />
          <RcInput
            label={"Ärendenummer"}
            placeholder={"Ärendenummer"}
            className={"input"}
            name={"externalId"}
            value={formik.values.externalId}
            onChange={(e) => handleInputChange(e, e.target.name)}
            errorMsg={formik.errors.externalId}
            isHorizontally={true}
          />
          <div className="selectWrapper">
            <IonLabel className="label">Handlägare</IonLabel>
            <div className="select">
              <IonSelect
                placeholder="Handlägare"
                value={formik.values.customerId}
                okText={"Välj"}
                cancelText={"Stäng"}
                name={"customerId"}
                className={"ionSelect"}
                onIonChange={(e) =>
                  formik.setFieldValue("customerId", e.detail.value)
                }
              >
                {colleagues?.map((colleague, index) => {
                  return (
                    <IonSelectOption key={index} value={colleague?.id}>
                      {colleague?.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </div>
          </div>
        </div>
      </form>

      <div className="buttonsWrapper">
        {/* <Button
          type="submit"
          isLoading={loading}
          onClick={updateContent}
          className={"button"}
        >
          Updatera
        </Button> */}
        {/* <Button
          variants={"secondary_outline"}
          className={"button"}
          onClick={() => props.doExit()}
        >
          Stäng
        </Button> */}
      </div>
      {error && (
        <ErrorAlert
          title={error?.name}
          subTitle={error.message}
          msg={error.extraInfo}
        />
      )}
    </CaseFolderStyleWrapper>
  );
};

interface IInitialValues {
  personFirstname: string | undefined;
  personLastname: string | undefined;
  personBorn: Date | undefined;
  personDied: Date | undefined;
  personBornLastname: string;
  personCityLive: string;
  personFuneral: Date | undefined;
  memoryCeremonyLastBookingDay: Date | undefined;
  externalId: string;
  customerId: string;
}

const CaseFolderStyleWrapper = styled.div`
  .inputsWrapper {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    .input {
      margin: 5px 0;
      .rcLabel {
        font-weight: bold;
        font-size: 13.5px;
      }
      .rcInput {
        /* width: 200px; */
        .input {
        }
      }
    }
    .dateInput {
      margin: 8px 0;
      .label {
        margin-bottom: 5px;
        font-size: 13.5px;
        font-weight: bold;
      }
      .input {
        margin: 0;
        /* width: 200px; */
      }
    }
    .selectWrapper {
      display: grid;
      grid-template-columns: 0.6fr 1.2fr;
      grid-template-rows: 1fr;
      column-gap: 10px;
      .label {
        font-size: 13.5px;
        font-weight: bold;
        align-self: center;
      }
      .select {
        --background: transparent linear-gradient(180deg, #fff 0%, #f8f8f8 100%)
          0% 0% no-repeat padding-box;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        margin-top: 10px;
        width: 100%;
        .ionSelect {
          --padding-start: 10px;
        }
      }
    }
  }

  .buttonsWrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .button {
      margin-right: 15px;
    }
  }
`;

export default CaseFolderForm;
