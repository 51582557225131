import React from "react";
import { TextAlignments } from "../../../../../../../../../../schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import cx from "classnames";
import { IonButton } from "@ionic/react";

const TextAlignment: React.FC<{
  allowedTextAdjustments: TextAlignments[];
  textAlignment: TextAlignments | null;
  handleTextAlignment: (
    value: TextAlignments,
    row: number,
    column: number
  ) => void;
  row: number;
  column: number;
  isLocked: boolean;
}> = ({
  allowedTextAdjustments,
  textAlignment,
  handleTextAlignment,
  row,
  column,
  isLocked,
}) => {
  return (
    <TextAlignmentStyleWrapper>
      <div className="textAlignmentWrapper">
        {allowedTextAdjustments &&
          allowedTextAdjustments.map((value, index) => {
            const getIcon = (selected: TextAlignments) => {
              switch (selected) {
                case TextAlignments.LEFT:
                  return faAlignLeft;
                case TextAlignments.CENTER:
                  return faAlignCenter;
                case TextAlignments.RIGHT:
                  return faAlignRight;
                default:
                  return faAlignLeft;
              }
            };
            return (
              <IonButton
                key={index}
                color={"none"}
                className={cx("icon", {
                  active: textAlignment !== null && textAlignment === value,
                })}
                onClick={() => handleTextAlignment(value, row, column)}
                disabled={isLocked}
                size={"small"}
              >
                <FontAwesomeIcon icon={getIcon(value)} />
              </IonButton>
            );
          })}
      </div>
    </TextAlignmentStyleWrapper>
  );
};

const TextAlignmentStyleWrapper = styled.div`
  .textAlignmentWrapper {
    display: flex;
    flex-direction: row-reverse;
    .icon {
      border: 1px solid var(--ion-color-medium-tint);
      color: var(--ion-color-medium-tint);
      border-radius: 3px;
      background-color: none;
      font-size: 14px;
      padding: 0;
      &.active {
        border: 1px solid var(--ion-color-secondary);
        color: var(--ion-color-secondary);
      }
    }
  }
`;

export default TextAlignment;
