import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { I_FormikValue } from "..";
import {
  IonAlert,
  IonImg,
  IonLabel,
  IonTextarea,
  TextareaChangeEventDetail,
} from "@ionic/react";
import { GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents } from "../../../../../schema";
import styled from "styled-components";
import Button from "../../../../../components/Button";
import { addCircleOutline, closeCircleOutline } from "ionicons/icons";
import useWindowSize from "../../../../../useHook/useWindowSize";
import {
  blobToBase64,
  standardMobileScreenSize,
} from "../../../../../utilities";
import { FormikProps } from "formik";
import ImageEditorModal from "../../../../../components/ImageEditorModal";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import produce from "immer";
import { LongPressDetectEvents, useLongPress } from "use-long-press";

export interface IImageFile {
  fileFormInput: any;
  name: any;
  type: any;
  lastModified: any;
  lastModifiedDate: any;
  size: any;
  path: any;
  preview: any;
}

interface Image {
  id: string;
  src: string;
  alt: string;
}

const ContentInputs: React.FC<{
  contentInfo: GetOrderById_orders_mediaOrder_edit_editModelMemoryRoom_contents;
  formik: FormikProps<I_FormikValue>;
  updateContent: (arg: any) => void;
  debounce: number;
  refetchData: () => void;
}> = (props) => {
  const { updateContent, formik, contentInfo, refetchData } = props;
  const defaultImages: Image[] =
    contentInfo.images?.map((image) => {
      return {
        id: image.id,
        src: image.imageUrl,
        alt: image.name,
      };
    }) ?? [];
  const { width } = useWindowSize();
  const isTabletView = width > standardMobileScreenSize;
  const [isImageEditorModal, setImageEditorModal] =
    React.useState<boolean>(false);
  const [imageFile, setImageFile] = React.useState<IImageFile | null>(null);
  const [imageType, setImageType] = React.useState<"AVATAR" | "IMAGE" | "">();
  const [images, setImages] = React.useState(defaultImages);
  const [alert, setAlert] = React.useState<boolean>(false);
  const [selectedImageID, setSelectedImageID] = React.useState<string | null>(
    null
  );

  const handleTextareaChange = (
    e: CustomEvent<TextareaChangeEventDetail>,
    name: string
  ) => {
    const value = e.detail.value;
    formik.setFieldValue(name, value);
    updateContent({
      contents: {
        [name]: value,
      },
    });
  };

  React.useEffect(() => {
    if (contentInfo) {
      const newImages: Image[] =
        contentInfo.images?.map((e) => {
          return {
            id: e.id,
            src: e.imageUrl,
            alt: e.name,
          };
        }) ?? [];

      setImages(newImages);
    }

    refetchData();
  }, [props.contentInfo]);

  const OpenAvatarImageModal = (file: IImageFile) => {
    setImageFile(file);
    setImageEditorModal(true);
    setImageType("AVATAR");
  };

  const OpenImageModal =  (file: IImageFile) => {
    setImageFile(file);
    setImageEditorModal(true);
    setImageType("IMAGE");
  };

  const successAvatarCrop = (blob: any) => {
    if (imageType === "AVATAR")
      blobToBase64(blob).then((base64data) => {
        updateContent({
          contents: {
            avatarImage: {
              value: {
                id: null,
                name: "avatarImage",
                inputContentBase64Encoded: base64data,
              },
            },
          },
        });
      });
    if (imageType === "IMAGE") {
      const prevImages =
        contentInfo.images.map((e) => {
          return {
            id: e.id,
            name: e.name,
            inputContentBase64Encoded: null,
          };
        }) ?? [];
      blobToBase64(blob).then((result) => {
        updateContent({
          contents: {
            images: [
              ...prevImages,
              {
                id: null,
                name: "Images",
                inputContentBase64Encoded: result,
              },
            ],
          },
        });
      });
    }
  };

  const handleAvatarClear = () => {
    setAlert(true);
    setImageType("AVATAR");
  };

  const handleImageClear = () => {
    setAlert(true);
    setImageType("IMAGE");
  };

  const callback = React.useCallback((evt, { context }) => {
    const selectedId: any = context;
    setSelectedImageID(selectedId);
    handleImageClear()
  }, []);

  const longPressEvent = useLongPress(callback, {
    threshold: 1000,
    detect: LongPressDetectEvents.TOUCH,
  });

  const clearAvatar = () => {
    updateContent({
      contents: {
        avatarImage: {
          value: null,
        },
      },
    });
  };

  const clearImage = () => {
    let index = -1;
    images.forEach((img, i1) => {
      if (img && img.id && img.id === selectedImageID) {
        index = i1;
      }
    });
    const newImages = produce(images, (draft) => {
      draft.splice(index, 1);
    });

    updateContent({
      contents: {
        images: newImages.map((e) => {
          return {
            id: e.id,
            name: e.alt,
            inputContentBase64Encoded: null,
          };
        }),
      },
    });
  };

  return (
    <ContentInputsStyleWrapper>
      <div className="memoryRoom-inputBlock">
        <div
          className={cx(" avatarImagesContainer", {
            tabletView: isTabletView,
          })}
        >
          <div className="avatarContainer">
            <label className="label">Visningsbild</label>
            <div className="avatarWrapper">
              <div className="avatar">
                {contentInfo.avatarImage?.value?.imageUrl ? (
                  <IonImg
                    className="avatarImg"
                    src={contentInfo.avatarImage?.value?.imageUrl}
                  />
                ) : (
                  <div className="image_filler"></div>
                )}
                <div className={cx("addAvatarButtonContainer")}>
                  <UploadAvatarButton
                    onDrop={OpenAvatarImageModal}
                    loading={true}
                    type={"avatar"}
                    isBgImage={
                      contentInfo.avatarImage?.value?.imageUrl ? true : false
                    }
                  />
                  {contentInfo.avatarImage?.value?.imageUrl && (
                    <Button
                      icon={closeCircleOutline}
                      iconDir={"icon-only"}
                      onClick={handleAvatarClear}
                      className={"avatarAbortButton"}
                      variants={"secondary_outline"}
                    ></Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="moreImageContainer">
            <label className="label">Flera bilder</label>
            <div className="moreImage">
              <div className="addImageWrapper">
                <UploadAvatarButton
                  onDrop={OpenImageModal}
                  loading={true}
                  type={"Image"}
                />
              </div>

              <Swiper
                className="swiper"
                initialSlide={0}
                slidesPerView={2}
                direction={"horizontal"}
                speed={500}
                autoplay={false}
              >
                {images.map((image, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="swiper-slide"
                      {...longPressEvent(image.id)}
                    >
                      <IonImg
                        src={image.src}
                        alt={image.alt}
                        style={{ width: "auto", height: "100px" }}
                      />
                    </SwiperSlide>
                  );
                })}
                <SwiperSlide></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="textareaInputWrapper">
          <IonLabel className="label">Inledning</IonLabel>
          <IonTextarea
            name={"welcomeText"}
            className="textareaInput"
            rows={5}
            value={formik.values.welcomeText}
            debounce={props.debounce}
            onIonChange={(e) => {
              handleTextareaChange(e, "welcomeText");
            }}
          />
        </div>
      </div>
      <ImageEditorModal
        isOpen={isImageEditorModal}
        onDidDismiss={() => setImageEditorModal(false)}
        imageFile={imageFile}
        onSuccess={successAvatarCrop}
      />
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        message={"Är du säkert radera bilden?"}
        buttons={[
          {
            text: "Ja",
            role: "confirm",
            handler: () => {
              imageType === "AVATAR" ? clearAvatar() : clearImage();
            },
          },
          {
            text: "Nej",
            role: "cancel",
            handler: () => {
              setAlert(false);
            },
          },
        ]}
      ></IonAlert>
    </ContentInputsStyleWrapper>
  );
};

const ContentInputsStyleWrapper = styled.div`
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid var(--ion-color-medium-tint);
  .memoryRoom-inputBlock {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .avatarImagesContainer {
      display: flex;
      flex-direction: column;
      &.tabletView {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
      }

      .avatarContainer {
        margin-bottom: 20px;
        .avatarWrapper {
          width: 100%;
          display: flex;

          margin-top: 10px;
          .avatar {
            position: relative;
            .avatarImg {
              width: 200px;
              height: auto;
            }
            .image_filler {
              width: 200px;
              height: 150px;
              border: 1px solid var(--ion-color-light-shade);
              border-radius: 3px;
            }
            .addAvatarButtonContainer {
              width: 95%;
              position: absolute;
              bottom: 5px;
              left: 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #ffffffca;
              padding: 5px;
              .avatarButton {
                margin: 0;
                font-size: 11px;
                font-weight: normal;
              }
              .avatarAbortButton {
                margin: 0;
                font-size: 11px;
                font-weight: normal;
              }
            }
          }
        }
      }
      .moreImageContainer {
        .moreImage {
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          .addImageWrapper {
            width: 120px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .addButton {
              width: 100px;
              height: 100px;
              border-radius: 8px;
            }
          }
          .swiper {
            width: 450px;
            height: 100%;
            background-color: var(--ion-color-primary-light);
          }
        }
      }
    }
  }
`;

const UploadAvatarButton: React.FC<{
  onDrop?: (file: IImageFile) => void;
  loading: boolean;
  type: "avatar" | "Image";
  isBgImage?: boolean;
}> = (props) => {
  const [error, setError] = React.useState<string | null>(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const mimeTypes = ["image/jpeg", "image/png"];
    const incorrectMimetype = mimeTypes.indexOf(file.type) === -1;

    if (incorrectMimetype) {
      setError("kunde inte ladda upp. filtyp är felaktig");
      return;
    }

    const houndedKb = 1 * 1024;
    const maxSize = 20 * 1024 * 1024;
    const incorrectFileSize = file.size < houndedKb || file.size > maxSize;

    if (incorrectFileSize) {
      setError("bildstorlek är ogiltigt");
      return;
    }

    if (props.onDrop) {
      setError("");
      const imageSelectDialogFile: IImageFile = {
        fileFormInput: file,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        size: file.size,
        path: file.path,
        preview: URL.createObjectURL(file),
      };
      props.onDrop(imageSelectDialogFile);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {props.type === "Image" && (
        <Button
          variants={"secondary_outline"}
          icon={addCircleOutline}
          iconDir={"icon-only"}
          className={"addButton"}
        ></Button>
      )}
      {props.type === "avatar" && (
        <Button variants={"secondary_noLine"} className={"avatarButton"}>
          {props.isBgImage ? "Byt Bild" : "Lägg Till"}
        </Button>
      )}
      <em style={{color: "red", fontSize: "14px"}}>{error}</em>
    </div>
  );
};

export default ContentInputs;
