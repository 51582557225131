import React from "react";
import {
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  SearchbarChangeEventDetail,
  SelectChangeEventDetail,
} from "@ionic/react";
import { IState } from "..";
import produce from "immer";
import styled from "styled-components";
import { CaseFolderCustomerScope, OrderStatus } from "../../../schema";
import { customerScopeText, statusText } from "../../../utilities";
import Logo from "../../../components/Logo";

interface IStatusOptions {
  value: OrderStatus;
  text: string;
}

const FilterTools: React.FC<{
  state: IState;
  setState: (arg: IState) => void;
}> = ({ state, setState }) => {
  const { NONE, EDIT, WAIT_APPROVAL, FAILED, PRODUCTION, PUBLISHED, CANCELED } =
    OrderStatus;

  const statusOptions: Array<IStatusOptions> = [
    {
      value: NONE,
      text: statusText[NONE],
    },
    {
      value: EDIT,
      text: statusText[EDIT],
    },
    {
      value: WAIT_APPROVAL,
      text: statusText[WAIT_APPROVAL],
    },
    {
      value: FAILED,
      text: statusText[FAILED],
    },
    {
      value: PRODUCTION,
      text: statusText[PRODUCTION],
    },
    {
      value: PUBLISHED,
      text: statusText[PUBLISHED],
    },
    {
      value: CANCELED,
      text: statusText[CANCELED],
    },
  ];

  const scopeOptions = [
    {
      value: CaseFolderCustomerScope.Default,
      text: customerScopeText[CaseFolderCustomerScope.Default],
    },
    {
      value: CaseFolderCustomerScope.Customer,
      text: customerScopeText[CaseFolderCustomerScope.Customer],
    },
    {
      value: CaseFolderCustomerScope.Office,
      text: customerScopeText[CaseFolderCustomerScope.Office],
    },
    {
      value: CaseFolderCustomerScope.Company,
      text: customerScopeText[CaseFolderCustomerScope.Company],
    },
  ];

  const handleSearchCaseFolder = (
    e: CustomEvent<SearchbarChangeEventDetail>
  ) => {
    const value = e.detail.value;
    const updateTextInput = produce(state, (draft) => {
      draft!.textFilter! = value !== undefined ? value : "";
    });
    setState(updateTextInput);
  };

  const handleFilterByStatus = (
    e: CustomEvent<SelectChangeEventDetail<any>>
  ) => {
    const filterStatus = produce(state, (draft) => {
      draft!.filterByOrderStatus! = e.detail.value;
    });
    setState(filterStatus);
  };

  const handleFilterByCustomerScope = (
    e: CustomEvent<SelectChangeEventDetail<any>>
  ) => {
    const scope = produce(state, (draft) => {
      draft!.scope! = e.detail.value;
    });
    setState(scope);
  };

  return (
    <FilterToolsStyleWrapper>
      <Logo />
      <div className="tools">
        <IonSearchbar
          value={state?.textFilter}
          onIonChange={handleSearchCaseFolder}
          debounce={1000}
          placeholder={"Sök"}
          className={"searchInput"}
        />

        <IonSelect
          okText="Välj"
          cancelText="Stäng"
          interface={"alert"}
          onIonChange={handleFilterByStatus}
          placeholder={statusText[NONE]}
          className={"status-select"}
        >
          {statusOptions.map((status, index) => {
            return (
              <IonSelectOption key={index} value={status.value}>
                {status.text}
              </IonSelectOption>
            );
          })}
        </IonSelect>

        <IonSelect
          okText="Välj"
          cancelText="Stäng"
          interface={"alert"}
          onIonChange={handleFilterByCustomerScope}
          placeholder={customerScopeText[CaseFolderCustomerScope.Default]}
          className={"scope-select"}
        >
          {scopeOptions.map((status, index) => {
            return (
              <IonSelectOption key={index} value={status.value}>
                {status.text}
              </IonSelectOption>
            );
          })}
        </IonSelect>
      </div>
    </FilterToolsStyleWrapper>
  );
};

const FilterToolsStyleWrapper = styled.div`
  .tools {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    row-gap: 10px;
    .searchInput {
      width: 220px;
      padding: 0 5px 0 0;
      .searchbar-search-icon {
        color: var(--ion-color-secondary);
      }
    }

    .status-select,
    .scope-select {
      border: 1px solid var(--ion-color-light-shade);
      width: 120px;
      margin-right: 5px;
      border-radius: 6px;
      font-size: 13px;
    }
  }
`;

export default FilterTools;
