import React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
  useIonRouter,
} from "@ionic/react";
import ConversationInputs from "./ConversationInputs";
import OrderInfo from "./OrderInfo";
import styled from "styled-components";
import { useParams } from "react-router";
import { IParams } from "../../../custom.Interface";
import cx from "classnames";
import { PublishOrder, PublishOrderKeyValueMap, ReducerAction } from "..";
import { LongPressDetectEvents, useLongPress } from "use-long-press";
import ValidateIcon from "./ValidateIcon";
import Button from "../../../components/Button";
import { warningOutline } from "ionicons/icons";
import { PublishOrders_publishOrders_resultOrders } from "../../../schema";

const OrderRow: React.FC<{
  orders: PublishOrderKeyValueMap;
  resultOrder: PublishOrders_publishOrders_resultOrders[] | null | undefined;
  dispatch: React.Dispatch<ReducerAction>;
}> = (props) => {
  const { orders, dispatch } = props;
  const router = useIonRouter();
  const [isSelectedRefused, setSelectRefused] = React.useState<boolean>(false);
  const params: IParams = useParams();
  const publishOrders =
    props.orders &&
    Object.keys(props.orders).map((orderId) => props.orders[orderId]);

  React.useEffect(() => {
    setTimeout(() => {
      expandRowByOrderId(params.orderId);
    }, 750);
  }, [params.orderId]);

  //Long Press
  const callback = React.useCallback((evt, { context }) => {
    
    const selectedOrder: PublishOrder = context;

    const isOrderPublish = publishOrders.find(
      (po) => po.orderId === selectedOrder.orderId
    )?.order.mediaOrder?.publishConversation.isBookingSent;

    if (!selectedOrder.errors && !isOrderPublish) {
      dispatch({
        type: "toggleSelect",
        payload: { id: selectedOrder.orderId },
      });
      setSelectRefused(false);
    } else {
      expandRowByOrderId(selectedOrder.orderId);
      setSelectRefused(true);
    }
  }, []);

  const longPressEvent = useLongPress(callback, {
    threshold: 1000,
    detect: LongPressDetectEvents.TOUCH,
  });

  const expandRowByOrderId = (orderId: string | null) => {
    const accordionGroup = document.querySelector("ion-accordion-group");
    accordionGroup!.value = [orderId!];
  };

  const handleMoveToEditPage = (orderId: string) => {
    router.push(
      `/editOrder/c/${params.caseFolderId}/o/${orderId}`,
      "back",
      "replace"
    );
  };

  return (
    <OrderRowStyleWrapper>
      <IonAccordionGroup multiple={true}>
        {orders &&
          publishOrders.map((publishOrder, idx) => {
            const isBookingSent =
              publishOrder.order.mediaOrder?.publishConversation.isBookingSent;
            const isActivated = publishOrder.order.mediaOrder?.publishConversation.isActivated
            return (
              <IonAccordion
                key={idx}
                className={cx("accordion", {})}
                {...longPressEvent(publishOrder)}
                value={publishOrder.orderId}
              >
                <IonItem
                  className={cx("headerContainer", {
                    currentOrder: params.orderId === publishOrder.orderId,
                    orderSelected: publishOrder.isSelected,
                    isPublished: isBookingSent || isActivated,
                  })}
                  slot="header"
                >
                  <ValidateIcon
                    publishOrder={publishOrder}
                    dispatch={props.dispatch}
                  />
                  <IonLabel className="label">
                    {publishOrder.order.mediaOrder?.media.friendlyName}
                  </IonLabel>
                </IonItem>
                <div className="accordionContent ion-padding" slot="content">
                  {publishOrder.errors.length > 0 && (
                    <div
                      className={cx("errorDescriptionBlock", {
                        refusedOrderSelect: isSelectedRefused,
                      })}
                    >
                      <IonIcon
                        className="icon"
                        icon={warningOutline}
                        color={"white"}
                      />
                      <IonText>{publishOrder.errors}</IonText>
                    </div>
                  )}

                  <OrderInfo
                    publishTime={
                      publishOrder.order.mediaOrder?.publishTimes[0]?.time
                    }
                    orderHeight={
                      publishOrder.order.mediaOrder?.preview.material.basePage
                        .size?.height
                    }
                    orderPrise={
                      publishOrder.order.mediaOrder?.preview.price?.endCustomer
                        .pricePayRounded
                    }
                    currency={
                      publishOrder.order.mediaOrder?.preview.price?.currency
                    }
                  />
                  <ConversationInputs
                    publishConversation={
                      publishOrder.order.mediaOrder?.publishConversation
                    }
                    dispatch={dispatch}
                    publishOrder={publishOrder}
                  />
                  <div className="footer">
                    <Button
                      onClick={() => handleMoveToEditPage(publishOrder.orderId)}
                      variants={"secondary_outline"}
                    >
                      Redigera
                    </Button>
                  </div>
                </div>
              </IonAccordion>
            );
          })}
      </IonAccordionGroup>
    </OrderRowStyleWrapper>
  );
};

const OrderRowStyleWrapper = styled.div`
  margin-bottom: 80px;
  .accordion {
    border: 1px solid transparent;
    border-radius: 8px;
    margin: 5px 0;
    .headerContainer {
      --background: var(--ion-color-light);
      .label {
        font-size: 15px;
        font-weight: 600;
      }
      &.currentOrder {
        --background: var(--ion-color-primary-light);
      }
      &.orderSelected {
        --background: var(--ion-color-secondary);
        --color: var(--ion-color-primary-light);
      }
      &.isPublished {
        --background: var(--ion-color-success);
      }
    }
    .accordionContent {
      width: 100;
      height: auto;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
      .errorDescriptionBlock {
        background-color: var(--ion-color-warning);
        border-radius: 3px;
        padding: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: white;
        display: flex;
        align-items: center;
        border: 2px solid transparent;
        .icon {
          font-size: 20px;
          margin-right: 5px;
        }
        &.refusedOrderSelect {
          border: 2px dotted var(--ion-color-danger);
        }
      }

      .footer {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export default OrderRow;
