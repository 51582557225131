import React from "react";
import styled from "styled-components";
import Button from "../../../../../../../../components/Button";
import Modal from "../../../../../../../../components/Modal";
import {
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useApolloClient, useQuery } from "@apollo/client";
import { GET_GALLERY_IMAGES } from "../../../../../../../../graphql/queries/getGalleyImages";
import {
  GetGalleryImages,
  GetGalleryImagesVariables,
  GetGalleryImages_galleryImagesByTags_images,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor,
} from "../../../../../../../../schema";
import { arrowBack } from "ionicons/icons";
import ErrorAlert from "../../../../../../../../components/ErrorAlert";
import TipsBox from "../../../../../../../../components/TipsBox";
import Symbols from "./Symbols";
import SearchContainer from "./SearchContainer";
import produce from "immer";
import { faHandPointUp } from "@fortawesome/free-regular-svg-icons";

export interface ITags {
  label: string;
  value: string;
}

type ISymbol = GetGalleryImages_galleryImagesByTags_images;

interface IState {
  startIndex: number;
  count: number;
}

const SymbolGalleryModal: React.FC<{
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
  doClose: () => void;
  selectSymbol: (
    imageId: string,
    publicId: number,
    thumbnailImage: string
  ) => void;
  editor: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor | null | undefined
  templateAreaId: string | null | undefined
  mediaId: string | null | undefined
}> = (props) => {
  const client = useApolloClient();
  const [state, setState] = React.useState<IState>({
    startIndex: 0,
    count: 50,
  });
  const [selectedOptions, setSelectedOptions] = React.useState<ITags[]>();
  const [isColorSymbol, setIsColorSymbol] = React.useState<boolean>(false);
  const [symbolsList, setSymbolsList] = React.useState<ISymbol[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] =
    React.useState<boolean>(false);
  const contentRef = React.useRef<HTMLIonContentElement | null>(null);

  const { data, error, loading } = useQuery<
    GetGalleryImages,
    GetGalleryImagesVariables
  >(GET_GALLERY_IMAGES, {
    client,
    fetchPolicy: "network-only",
    variables: {
      input: {
        tags:
          selectedOptions && selectedOptions.length > 0
            ? selectedOptions
                .map((tag: ITags) => tag.value)
                .concat(isColorSymbol ? ["ATTR-COLOR"] : [])
            : ["kors"].concat(isColorSymbol ? ["ATTR-COLOR"] : []),
        tagGroupIds: [
          "f0c63e58-3d55-11e1-a38f-5254006c9da8",
          "00000000-0000-0000-0000-000000000010",
          "00000000-0000-0000-0000-000000000100",
        ],
        documentFormatId: props.editor?.image?.documentFormatId,
        startIndex: state.startIndex,
        pageLength: state.count,
        filterGalleryImageAttributeInternalIds: props.editor?.image?.filterGalleryImageAttributeInternalIds,
        filterGalleryImageAttributeNotInternalIds: [],
        galleryImageOption: "",
        templateAreaId:props.templateAreaId,
        mediaId: props.mediaId!
      },
      filterTagGroupId: "f0c63e58-3d55-11e1-a38f-5254006c9da8",
    },
  });

  React.useEffect(() => {
    if (data && data.galleryImagesByTags) {
      setSymbolsList([...data.galleryImagesByTags.images]);
    }
  }, [data]);

  const handleSelectTagUpdate = (newTag: ITags[]) => {
    setSelectedOptions(newTag);
    scrollToTop();
    stateReset();
  };

  const handleColorCheckBoxChange = () => {
    setIsColorSymbol(!isColorSymbol);
  };

  const stateReset = () => {
    setState(
      produce(state, (draft) => {
        draft.startIndex = 0;
        draft.count = 25;
      })
    );
  };

  //infinity scroll configuration
  const pushData = () => {
    if (data && data.galleryImagesByTags.images) {
      setState(
        produce(state, (draft) => {
          draft.count = state.count + 25;
        })
      );
    }
  };

  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      ev.target.complete();

      if (
        symbolsList.length ===
        data?.galleryImagesByTags.totalImageCount! - 10
      ) {
        //somehow the length of verseslist is 10 items less than actual list
        // therefore I had to took 10 item from the count
        setInfiniteDisabled(true);
      }
    }, 500);
  };

  useIonViewWillEnter(() => {
    pushData();
  });

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(1000);
  };

  if (error) {
    return (
      <ErrorAlert
        title={error.name}
        subTitle={"field to calling symbol gallery"}
        msg={error.message}
      />
    );
  }

  return (
    <Modal
      doClose={props.doClose}
      isVisible={props.isVisible}
      setIsVisible={props.setIsVisible}
    >
      <SymbolGalleryModalStyleWrapper>
        <IonHeader>
          <IonToolbar className="symbolToolbar">
            <div className="innerSymbolToolbar">
              <Button
                className="backBtn"
                variants={"secondary_noLine"}
                onClick={props.doClose}
                icon={arrowBack}
                iconDir={"icon-only"}
              />
              <IonTitle className="title">Symboler</IonTitle>
            </div>
          </IonToolbar>
          <IonToolbar className="subToolbar">
            <SearchContainer
              handleTagUpdate={handleSelectTagUpdate}
              selectedTags={selectedOptions}
            />

            <div className="checkboxWrapper">
              <IonCheckbox
                className="checkbox"
                slot="start"
                color="success"
                value={isColorSymbol}
                onIonChange={handleColorCheckBoxChange}
                mode={"ios"}
              />
              <IonLabel className="checkboxLabel" color={"dark"}>
                Endast färgsymboler
              </IonLabel>
            </div>
          </IonToolbar>
          {loading ? (
            <IonProgressBar type="indeterminate"></IonProgressBar>
          ) : (
            <div className="fill" style={{ height: "3px" }}></div>
          )}
        </IonHeader>
        <br />

        <IonContent
          ref={contentRef}
          fullscreen
          className="symbolContent"
          scrollEvents={true}
        >
          <TipsBox
            position="galleyModal"
            icon={faHandPointUp}
            description="Välj genom att trycka på Symbol"
            className="symbolTipsBox"
          />
          <Symbols
            symbols={symbolsList}
            selectSymbol={props.selectSymbol}
            doCloseSymbolModal={props.doClose}
          />

          <IonInfiniteScroll
            color="primary"
            onIonInfinite={loadData}
            threshold="100px"
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Anropar mer symboler ..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </SymbolGalleryModalStyleWrapper>
    </Modal>
  );
};

const SymbolGalleryModalStyleWrapper = styled.div`
  .symbolToolbar {
    padding: 0;
    .innerSymbolToolbar {
      display: flex;
      .title {
        text-align: center;
      }
    }
  }

  .checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    padding: 8px 0;
    .checkbox {
      --size: 25px;
      z-index: 0;
    }
    .checkboxLabel {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .symbolContent {
    .symbolsContainer {
      height: fit-content;
      .symbolWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .symbol {
          width: 80px;
        }
        span {
          margin-top: 10px;
        }
      }
    }
  }
`;

export default SymbolGalleryModal;
