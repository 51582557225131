import React from "react";
import { IonImg } from "@ionic/react";
import {
  EditModelAdvertAreaContentEditorBehaviours,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image,
} from "../../../../../../../schema";
import styled from "styled-components";
import Button from "../../../../../../../components/Button";
import { search } from "ionicons/icons";
import SymbolGalleryModal from "./SymbolGalleryModal";
import Slider from "./Slider";
import { type Swiper as SwiperRef } from "swiper";

export interface ISlider {
  value: number | null;
  scale: number | null;
}

const GalleyContent: React.FC<{
  closeAreaEditModal: () => void;
  isLocked: boolean;
  imageProps:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_value_rows_columns_item_image
    | null
    | undefined;
  selectSymbol: (
    imageId: string,
    publicId: number,
    thumbnailImage: string
  ) => void;
  updateImageHeight: (height: number) => void;
  editorImage: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_image | null;
  heightMM: number;
  editor:
    | GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
    | null
    | undefined;
  templateAreaId: string | null | undefined;
  swpRef: React.MutableRefObject<SwiperRef | null>;
  mediaId: string | null | undefined;
}> = (props) => {
  const MIN_SCALE = 0.4;
  const MAX_SCALE = 1;
  const { ORDER_IMAGE } = EditModelAdvertAreaContentEditorBehaviours;
  const [isSymbolGalleyModal, setIsSymbolGalleryModal] =
    React.useState<boolean>(false);
  const [slider, setSlider] = React.useState<ISlider>({
    value: null,
    scale: null,
  });

  const handleOpenSymbolModal = () => setIsSymbolGalleryModal(true);

  const handleCloseSymbolModal = () => setIsSymbolGalleryModal(false);

  const getScalaValue = (value: number) => {
    const maxImageHeight = Number(props.editorImage?.maxImageHeight);
    const minImageHeight = Number(props.editorImage?.minImageHeight);
    if (!maxImageHeight && !minImageHeight) return null;
    const diff = MAX_SCALE - MIN_SCALE;
    return (
      ((value - minImageHeight) / (maxImageHeight - minImageHeight)) * diff +
      MIN_SCALE
    );
  };

  return (
    <>
      {props.editor?.behaviour === ORDER_IMAGE ? (
        <></>
      ) : (
        <GalleyContentStyleWrapper>
          <div className="symbolImageWrapper">
            <IonImg
              className="imageSymbol"
              src={props.imageProps?.imageThumbnaill100x80Url!}
              style={{ transform: `scale(${slider.scale!})` }}
            />
          </div>
          <Slider
            heightMM={props.heightMM}
            updateImageHeight={props.updateImageHeight}
            maxImageHeight={props.editorImage?.maxImageHeight!}
            minImageHeight={props.editorImage?.minImageHeight!}
            slider={slider}
            setSlider={setSlider}
            getScalaValue={getScalaValue}
            isLocked={props.isLocked}
            swpRef={props.swpRef}
          />
          <div className="btnWrapper">
            <Button
              onClick={handleOpenSymbolModal}
              icon={search}
              variants={"secondary_outline"}
              size={"default"}
              isDisabled={props.isLocked}
            >
              Välj Symbol
            </Button>
          </div>
        </GalleyContentStyleWrapper>
      )}

      <SymbolGalleryModal
        isVisible={isSymbolGalleyModal}
        setIsVisible={setIsSymbolGalleryModal}
        doClose={handleCloseSymbolModal}
        selectSymbol={props.selectSymbol}
        editor={props.editor}
        templateAreaId={props.templateAreaId}
        mediaId={props.mediaId}
      />
    </>
  );
};

const GalleyContentStyleWrapper = styled.div`
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  .symbolImageWrapper {
    width: 150px;
    height: 150px;
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 3px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    .imageSymbol {
      width: 100%;
      height: 100%;
    }
  }
`;
export default GalleyContent;
